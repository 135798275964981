import camelCase from 'lodash/camelCase';

import { error } from '@/src/helpers/general';

import type { FeatureFlagUserAttributes } from './config';
import {
  exactEvaluator,
  inArrayEvaluator,
  notInArrayEvaluator,
  semverGteEvaluator,
  semverLteEvaluator,
} from './evaluators';

const OPERATORS = {
  In: 'in',
  NotIn: 'not_in',
  Equal: 'equal',
  SemverGreaterOrEqual: 'semver_greater_or_equal',
  SemverLessOrEqual: 'semver_less_or_equal',
} as const;

type ConditionOperator = (typeof OPERATORS)[keyof typeof OPERATORS];

type ConditionValue = string | boolean | number | undefined | null;

interface BaseAudienceCondition {
  operator: ConditionOperator;
  attribute: string;
}

export interface AudienceConditionEqual extends BaseAudienceCondition {
  operator: 'equal';
  value: ConditionValue;
}

export interface AudienceConditionIn extends BaseAudienceCondition {
  operator: 'in';
  value: ConditionValue[];
}

export interface AudienceConditionNotIn extends BaseAudienceCondition {
  operator: 'not_in';
  value: ConditionValue[];
}

/**
 * This operator is used to check whether a sem-version is greater or equal to a given value.
 * It is used mostly backend-side to enable a FF for a given mobile app version, for example:
 * - we want to enable a FF for mobile app versions greater or equal to 9.3.0
 */
export interface AudienceConditionSemverGreaterOrEqual extends BaseAudienceCondition {
  operator: 'semver_greater_or_equal';
  value: string;
}

/**
 * This operator is used to check whether a sem-version is less or equal to a given value.
 * It is used mostly backend-side to enable a FF for a given mobile app version, for example:
 * - we want to enable a FF for mobile app versions less or equal to 9.3.0
 */
export interface AudienceConditionSemverLessOrEqual extends BaseAudienceCondition {
  operator: 'semver_less_or_equal';
  value: string;
}

export type AudienceCondition =
  | AudienceConditionEqual
  | AudienceConditionIn
  | AudienceConditionNotIn
  | AudienceConditionSemverGreaterOrEqual
  | AudienceConditionSemverLessOrEqual;

interface Audience {
  /**
   * Unique identifier for the audience
   */
  key: string;
  /**
   * Audience conditions
   * Note: all conditions must be met for the audience to be considered a match
   */
  conditions: AudienceCondition[];
}

/**
 * Evaluates a single condition for a given user account.
 * If the operator provided is not supported, the function will return false.
 */
function evaluateCondition({
  featureFlagName,
  attributes,
  condition,
  audienceKey,
}: {
  featureFlagName: string;
  attributes?: FeatureFlagUserAttributes;
  condition: AudienceCondition;
  audienceKey: string;
}): boolean {
  const userAttributeValue = attributes?.[camelCase(condition.attribute)];

  if (userAttributeValue === undefined) return false;

  switch (condition.operator) {
    case 'equal':
      return exactEvaluator(condition, userAttributeValue, featureFlagName, audienceKey);

    case 'in':
      return inArrayEvaluator(condition, userAttributeValue);

    case 'not_in':
      return notInArrayEvaluator(condition, userAttributeValue);

    /**
     * Backend added support for semver comparison and we need to support this to avoid errors
     * https://linear.app/remote/issue/MBL-1181/improve-ff-system-to-handle-mobile-conditions-platformversion
     */
    case 'semver_greater_or_equal':
      return semverGteEvaluator(condition, userAttributeValue);

    case 'semver_less_or_equal':
      return semverLteEvaluator(condition, userAttributeValue);

    default:
      // @ts-expect-error (this should not happen BUT we're dealing with an API so it's best to consider a possible error)
      error(`Unsupported operator: "${condition.operator}" on feature flag: "${featureFlagName}"`);
      return false;
  }
}

/**
 * Evaluates a single audience for a given set of user attributes.
 */
function evaluateAudience(
  featureFlagName: string,
  audience: Audience,
  attributes?: FeatureFlagUserAttributes
): boolean {
  // For an audience to considered a "match", all conditions must be met
  return audience.conditions.every((condition) =>
    evaluateCondition({ featureFlagName, attributes, condition, audienceKey: audience.key })
  );
}

interface EvaluateFeatureFlagAudiencesParams {
  featureFlagName: string;
  audiences?: Audience[] | null;
  attributes?: FeatureFlagUserAttributes;
}

/**
 * Evaluates the Feature Flag audiences for a given user account.
 * If no audiences are provided, the function will return true.
 * If the user is not provided, the function will return false.
 * Otherwise, audiences will be evaluated and if any of them match, the function will return true.
 */
export function evaluateFeatureFlagAudiences({
  featureFlagName,
  audiences,
  attributes,
}: EvaluateFeatureFlagAudiencesParams): boolean {
  // If we have no audiences, we should enable the feature flag as it does not depend on the user
  if (!audiences || !audiences.length) {
    return true;
  }

  // Otherwise, we should evaluate the audiences and if some of them match, we should enable the feature flag
  return audiences.some((audience) => {
    const audienceEvaluatesAsMatch = evaluateAudience(featureFlagName, audience, attributes);

    return audienceEvaluatesAsMatch;
  });
}

export type { Audience as FeatureFlagAudience };
