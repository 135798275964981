import PropTypes from 'prop-types';

import { ProgressBarTrack, ProgressBarValue, ProgressLabel } from './ProgressBar.styled';

const ProgressBar = ({ max, value, height, variant, className }) => {
  const progressPercent = (value * 100) / max;
  const isIndeterminate = value === undefined;
  return (
    <ProgressBarTrack
      $height={height}
      $variant={variant}
      className={className}
      role="progressbar"
      aria-valuenow={value}
      aria-valuemin="0"
      aria-valuemax={max}
    >
      <ProgressBarValue
        $minWidth={height}
        $percentValue={progressPercent}
        $isIndeterminate={isIndeterminate}
        $variant={variant}
      />
    </ProgressBarTrack>
  );
};

ProgressBar.propTypes = {
  max: PropTypes.number,
  value: PropTypes.number,
  height: PropTypes.string,
  variant: PropTypes.oneOf(['auto', 'primary', 'secondary', 'empty', 'success', 'warning']),
  className: PropTypes.string,
};

ProgressBar.defaultProps = {
  max: 1,
  height: '8px',
  variant: 'auto',
};

export default ProgressBar;

export const ProgressBarLabel = ProgressLabel;
