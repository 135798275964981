import { InputSelect } from '@remote-com/norma';
import type { InputSelectProps, SelectOption } from '@remote-com/norma';
import { useState } from 'react';
import type { ActionMeta } from 'react-select';

type APIValue = string | string[];
type onChangeArgs = [SelectOption, ActionMeta<SelectOption>];

function getOptionFromValue(value: APIValue, options: InputSelectProps['options']): SelectOption {
  // @ts-expect-error - Help!
  return options.find((opt) => opt.value === value);
}

function getDefaultValue(
  value: APIValue,
  options: InputSelectProps['options']
): SelectOption[] | SelectOption | undefined {
  // it's a multi-select
  if (Array.isArray(value)) {
    return value.map((itemValue) => getOptionFromValue(itemValue, options));
  }

  return getOptionFromValue(value, options) || undefined;
}

/*
! This wrapper is necessary for performance reasons.
Problem: Norma InputSelect, when is multi-select, calls onChange() right after the user selects an option.
This causes a re-render, which we do NOT want to happen in the Table's inline editing, because it gets super slow to select
more options and causes extra Network requests (1 per option).

Solution: Delay onChange() call. Onl call it when the menu closes (when the user finishes selecting all options).

Check MR !37452 for Demo.
*/

export function EditCellInputSelect(
  props: InputSelectProps & {
    currentValue: APIValue;
  }
) {
  const { onChange, onMenuClose, currentValue, ...rest } = props;
  const [valueOnChanged, setValueOnChanged] = useState<onChangeArgs>();
  const hasDelayOnChange = props.multiple;

  function handleChange(...args: onChangeArgs) {
    setValueOnChanged(args);
    // DO NOT call onChange() as explained above
    // onChange?.(...args);
  }

  function handleMenuClose() {
    if (typeof valueOnChanged !== 'undefined') {
      onChange?.(...valueOnChanged);
    }
    onMenuClose?.();
  }

  return (
    <InputSelect
      {...rest}
      defaultValue={getDefaultValue(currentValue, props.options)}
      onMenuClose={hasDelayOnChange ? handleMenuClose : onMenuClose}
      onChange={hasDelayOnChange ? handleChange : onChange}
    />
  );
}
