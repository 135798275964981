import type { Axios, InternalAxiosRequestConfig } from 'axios';

import type { OnAuthFailParams, OnAuthFailRequestInterceptorConfig } from './onAuthFail';
import { onAuthFail, NAME as onAuthFailName } from './onAuthFail';
import type { OnAuthRequestInterceptorConfig } from './onAuthRequest';
import { onAuthRequest, NAME as onAuthRequestName } from './onAuthRequest';
import type { OnForbiddenFailParams } from './onForbiddenFail';
import { onForbiddenFail, NAME as onForbiddenFailName } from './onForbiddenFail';
import { onImpersonationFail, NAME as onImpersonationFailName } from './onImpersonationFail';
import { onRefreshHint, NAME as onRefreshHintName } from './onRefreshHint';

export type AxiosCustomConfigProperties = OnAuthFailRequestInterceptorConfig &
  OnAuthRequestInterceptorConfig;

export function createInterceptorManager(axiosInstance: Axios) {
  const interceptorReferences: Record<string, number> = {};

  return {
    installOnAuthFailResponseInterceptor(options: OnAuthFailParams) {
      if (!interceptorReferences[onAuthFailName]) {
        interceptorReferences[onAuthFailName] = axiosInstance.interceptors.response.use(
          (resp) => resp,
          onAuthFail(options)
        );
      }
    },
    installOnImpersonationInterceptor() {
      if (!interceptorReferences[onImpersonationFailName]) {
        interceptorReferences[onForbiddenFailName] = axiosInstance.interceptors.response.use(
          (resp) => resp,
          onImpersonationFail()
        );
      }
    },
    removeOnImpersonationInterceptor() {
      const reference = interceptorReferences[onImpersonationFailName];
      if (typeof reference === 'number') {
        axiosInstance.interceptors.response.eject(reference);
        delete interceptorReferences[onImpersonationFailName];
      }
    },
    removeOnAuthFailResponseInterceptor() {
      const reference = interceptorReferences[onAuthFailName];
      if (typeof reference === 'number') {
        axiosInstance.interceptors.response.eject(reference);
        delete interceptorReferences[onAuthFailName];
      }
    },
    installOnAuthRequestInterceptor() {
      if (!interceptorReferences[onAuthRequestName]) {
        interceptorReferences[onAuthRequestName] = axiosInstance.interceptors.request.use(
          onAuthRequest,
          null,
          {
            runWhen(config: InternalAxiosRequestConfig | AxiosCustomConfigProperties) {
              if ('useAuthentication' in config) {
                return Boolean(config.useAuthentication);
              }
              return false;
            },
          }
        );
      }
    },
    removeOnAuthRequestInterceptor() {
      const reference = interceptorReferences[onAuthRequestName];
      if (typeof reference === 'number') {
        axiosInstance.interceptors.request.eject(reference);
        delete interceptorReferences[onAuthRequestName];
      }
    },
    installOnForbiddenFailResponseInterceptor(options: OnForbiddenFailParams) {
      if (!interceptorReferences[onForbiddenFailName]) {
        interceptorReferences[onForbiddenFailName] = axiosInstance.interceptors.response.use(
          (resp) => resp,
          onForbiddenFail(options)
        );
      }
    },
    removeOnForbiddenFailResponseInterceptor() {
      const reference = interceptorReferences[onForbiddenFailName];
      if (typeof reference === 'number') {
        axiosInstance.interceptors.response.eject(reference);
        delete interceptorReferences[onForbiddenFailName];
      }
    },
    installOnRefreshHintResponseInterceptor() {
      if (!interceptorReferences[onRefreshHintName]) {
        interceptorReferences[onRefreshHintName] =
          axiosInstance.interceptors.response.use(onRefreshHint);
      }
    },
    removeOnRefreshHintResponseInterceptor() {
      const reference = interceptorReferences[onRefreshHintName];
      if (typeof reference === 'number') {
        axiosInstance.interceptors.response.eject(reference);
        delete interceptorReferences[onRefreshHintName];
      }
    },
  };
}
