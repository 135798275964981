import { pathnameToLocationOfAction } from '@remote-com/analytics';

export const OPEN_SMART_SEARCH = ({ pathname }: { pathname: string }) => ({
  name: 'Smart Search opened',
  eventProps: {
    elementType: 'button',
    elementName: 'smart-search-button',
    locationOfAction: pathnameToLocationOfAction(pathname),
  },
});

export const CANCEL_SMART_SEARCH = ({ pathname }: { pathname: string }) => ({
  name: 'Smart Search canceled',
  eventProps: {
    elementType: 'modal',
    elementName: 'smart-search-cancel',
    locationOfAction: pathnameToLocationOfAction(pathname),
  },
});

export const PROMPT_SMART_SEARCH = ({ pathname }: { pathname: string }) => ({
  name: 'Smart Search prompted',
  eventProps: {
    elementType: 'modal',
    elementName: 'smart-search-prompt',
    locationOfAction: pathnameToLocationOfAction(pathname),
  },
});

export const SMART_SEARCH_ISSUE = ({ pathname }: { pathname: string }) => ({
  name: 'Smart Search unable to apply filters',
  eventProps: {
    elementType: 'modal',
    elementName: 'smart-search-issue',
    locationOfAction: pathnameToLocationOfAction(pathname),
  },
});
