import { trackEvent } from '@remote-com/analytics';
import { Box, toast } from '@remote-com/norma';
import { IconSearch } from '@remote-com/norma/icons/IconSearch';
import snakeCase from 'lodash/snakeCase';
import { useRouter } from 'next/router';
import { useLayoutEffect, useState } from 'react';

import type { API } from '@/src/api/config/api.types';
import ProgressBar from '@/src/components/ProgressBar';
import { usePostFiltersFromAiService } from '@/src/components/Table/Components/Toolbar/SmartSearch/hooks';
import type { ColumnType } from '@/src/components/Table/types.ts';
import { useSavedSearchValue } from '@/src/hooks/useSavedSearchValue';

import { PROMPT_SMART_SEARCH, SMART_SEARCH_ISSUE } from './events';
import { getApplicableFilters, mergeFilters } from './helpers';
import { ProgressBarContainer, Icon, Input, Wrapper } from './SmartSearch.styled';

type Filters = API.RemoteAi.GenerateSmartSearchFilter.Response['data']['filters'];
type SmartSearchInputProps = {
  onInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder: string;
  isLoading: boolean;
};

function SmartSearchInput({ onInput, onKeyDown, placeholder, isLoading }: SmartSearchInputProps) {
  return (
    <Wrapper>
      <Icon as={IconSearch} />
      <Input
        type="search"
        placeholder={placeholder}
        autoFocus
        onInput={onInput}
        onKeyDown={onKeyDown}
      />
      <ProgressBarContainer>
        {isLoading ? <ProgressBar height="4px" /> : <Box height="4px" />}
      </ProgressBarContainer>
    </Wrapper>
  );
}

type SmartSearchProps = {
  onClose: () => void;
  apiPath: string;
  setAllFilters?: (filters: Filters) => void;
  transformFilters: (filters: Filters) => Filters;
  columns: Array<ColumnType>;
  setGlobalFilter?: (filter: string) => void;
  /**
   * What field or fields are searchable by the table's global search (eg: "name", "name and email", "slug")
   */
  globalSearchFields?: string;
  currentFilters: Filters;
  placeholder?: string;
};

export default function SmartSearch({
  onClose,
  apiPath,
  setAllFilters,
  transformFilters,
  columns,
  setGlobalFilter,
  currentFilters,
  globalSearchFields,
  placeholder = 'What are you looking for?',
}: SmartSearchProps) {
  const { pathname } = useRouter();
  const { setSearchTerm } = useSavedSearchValue();
  const [userQuery, setUserQuery] = useState('');
  const { isLoading, isError, mutateAsync } = usePostFiltersFromAiService();
  const columnNames = columns
    .filter((col) => !col.disableFilters)
    .map((c) => c.id)
    .map((id) => snakeCase(id))
    .filter((id) => id !== 'actions')
    .filter(Boolean) as string[];

  useLayoutEffect(() => {
    if (isError) {
      toast.error('An error occurred, some results might be missing.');
    }
  }, [isError]);

  const handleSort = (
    sort: NonNullable<API.RemoteAi.GenerateSmartSearchFilter.Response['data']['sort']>
  ) => {
    const column = columns.find((c) => c.id === sort.sortBy);

    if (!sort.sortBy || !column?.toggleSortBy) {
      // If no valid sort or column found, maintain current sort state
      return;
    }

    // Clear any existing sorts before applying new one
    columns.forEach((c) => {
      if (c.id !== sort.sortBy && c.clearSortBy) {
        c.clearSortBy();
      }
    });

    column.toggleSortBy(sort.order === 'desc');
    return true;
  };

  const handleSmartSearchResults = (status: 'error' | 'partial-success' | 'success') => {
    if (status === 'error') {
      toast.error(`There's been an issue applying desired view.`);
      trackEvent(SMART_SEARCH_ISSUE({ pathname }));
      onClose();
      return;
    }
    if (status === 'success') {
      toast.success('Filters applied to the current view.');
    } else {
      toast.success(
        'Some queries might not be applicable to the current view. Please apply them manually.'
      );
    }
    onClose();
  };

  const handlePressEnter = async () => {
    trackEvent(PROMPT_SMART_SEARCH({ pathname }));

    if (!userQuery.trim()) {
      toast.error('Please enter a valid input query.');
      return;
    }

    const response = await mutateAsync({
      bodyParams: {
        userQuery,
        endpoint: apiPath,
        columnNames,
        globalSearch: globalSearchFields,
      },
    });

    const {
      filters: responseFilters,
      sort: responseSort,
      globalFilter: responseGlobalFilter,
    } = response.data;

    const additionalColumnsContext = columns.reduce<
      Record<string, { label: string; value: string }[]>
    >((acc, column) => {
      if (column.context) {
        acc[column.id as keyof typeof acc] = column.context;
      }
      return acc;
    }, {});

    const applicableFilters = getApplicableFilters(
      responseFilters,
      columns,
      additionalColumnsContext
    );
    const combinedFilters = mergeFilters(currentFilters, applicableFilters);

    setAllFilters?.(transformFilters(combinedFilters));

    if (responseGlobalFilter) {
      setGlobalFilter?.(responseGlobalFilter);
      setSearchTerm(responseGlobalFilter);
    }

    if (responseSort) {
      handleSort(responseSort);
    }

    // Filter out sort operations from response filters since they are treated separately
    const filters = responseFilters.filter((filter) => filter.id !== 'sort_by');

    // Partial success: it only applies when filters were requested but some couldn't be applied
    const partiallySuccessfulFilters =
      filters.length > 0 && applicableFilters.length < filters.length;

    // Error: we have no valid operations to apply
    const noQueryToApply = filters.length === 0 && !responseGlobalFilter && !responseSort;

    // Determine the operation status based on conditions
    let status: 'error' | 'partial-success' | 'success' = 'error';
    if (noQueryToApply) {
      status = 'error';
    } else if (partiallySuccessfulFilters) {
      status = 'partial-success';
    } else {
      status = 'success';
    }

    handleSmartSearchResults(status);
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      e.stopPropagation();
      onClose();
    }
    if (e.key === 'Enter') {
      handlePressEnter();
    }
  };

  return (
    <Box pt={3} width={['100%', '560px', '400px', '560px']} maxWidth="90vw" marginRight={5}>
      <SmartSearchInput
        placeholder={placeholder}
        isLoading={isLoading}
        onInput={(e) => {
          setUserQuery(e.target.value);
        }}
        onKeyDown={handleKeyDown}
      />
    </Box>
  );
}
