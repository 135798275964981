import { Box } from '@remote-com/norma';
import isNil from 'lodash/isNil';
import styled from 'styled-components';

import { CHECKBOX_WIDTH } from '@/src/components/Table/helpers/constants';

import { ContentLayout } from '../Ui/Layout';

export const TableWrapper = styled(Box)`
  position: relative;
  width: 100%;
  overflow-x: auto;
  z-index: 1;

  &[data-loading='true'] {
    overflow-x: hidden;
  }
`;

export const MIN_COLUMN_SIZE = 70;

export const TableStyled = styled.table<{
  $columnsCount: number;
  $isCompactTable: boolean;
  $widths: Array<string | number | undefined | null>;
}>`
  border-collapse: collapse;
  min-width: 100%;
  width: fit-content;
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-template-columns: ${({ $columnsCount, $widths }) => {
    // should be individual for allowing for columns resizing
    const columns = Array.from(Array($columnsCount).keys());

    const widths = columns.map((_, index) => {
      const width = $widths[index];

      // If no width has been defined, scale the column automatically
      if (isNil(width)) {
        return 'minmax(min-content, 1fr)';
      }

      // If the defined value is number-like, use it as a pixel value
      if (!Number.isNaN(Number(width))) {
        return `${width || MIN_COLUMN_SIZE}px`;
      }

      // Otherwise, just return the value (e.g. "5rem", "16px", ...)
      return width;
    });

    return widths.join(' ');
  }};

  thead,
  tbody,
  tfoot,
  tr,
  tbody > a {
    display: contents;
  }

  th,
  td {
    min-height: ${({ $isCompactTable }) => ($isCompactTable ? '40px' : '54px')};
  }

  td {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: flex-start;
  }

  [data-sticky-td] {
    z-index: 3;
    position: sticky;
    /* On action cells, we want overflow to be visible because
     a) we control the content
     b) it allow for menu actions' focus style to remain uncropped */
    & > div {
      overflow: visible;
    }
  }
  [data-sticky-left-td],
  [data-sticky-right-td] {
    box-shadow: 0px 0px 10px rgba(13, 38, 58, 0.12);
  }

  [data-sticky-left-td] {
    clip-path: inset(0.5px -10px 0 0);
  }

  [data-sticky-left-td]:first-of-type {
    left: 0;
  }

  [data-sticky-left-td]:nth-of-type(2) {
    left: ${CHECKBOX_WIDTH}px;
  }

  /* Sticky right column logic */
  [data-sticky-right-td] {
    right: 0;
    clip-path: inset(0.5px 0 0 -10px);
  }
`;

export const StyledContentLayout = styled(ContentLayout)<{
  $isolate: boolean;
  $footerExists: boolean;
}>`
  ${({ $footerExists }) =>
    $footerExists &&
    `
    padding-bottom: 0;
    box-shadow: 0;
    `};

  ${({ $isolate }) =>
    $isolate &&
    `  /* Start a new stacking context */
       isolation: isolate;
        `}

  padding-top: 0;
  position: relative;
`;
