import { Text } from '@remote-com/norma';

import { ModalActionable } from '@/src/components/Modal/ModalActionable';

export function ActionRestrictedModal({ handleModalAction }: { handleModalAction: () => void }) {
  return (
    <ModalActionable
      open
      title="Action restricted"
      tone="destructive"
      description={<Text>You’re unable to take this action while mimicking user accounts.</Text>}
      confirmLabel="Close"
      onConfirm={handleModalAction}
      onCancel={handleModalAction}
      showCancel={false}
    />
  );
}
