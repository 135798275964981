import { useRouter } from 'next/router';
import { useEffect } from 'react';
// eslint-disable-next-line remote/prefer-using-the-data-layer
import { useQuery } from 'react-query';

import { createFeatureFlagConfig } from '../config';

export const useFetchFeatureFlagConfig = () => {
  const router = useRouter();
  const { data, isLoading, isStale, refetch } = useQuery({
    queryKey: ['featureFlagsConfig'],
    queryFn: createFeatureFlagConfig,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  useEffect(() => {
    // Trigger refetch on route change
    const handleRouteChange = () => {
      if (isStale) {
        refetch();
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [isStale, refetch, router.events]);

  return { data, isLoading };
};
