import type { ComponentProps } from 'react';

import type { LegalEntityPayElementApi } from '@/src/api/config/employ/legalEntityPayElement.types';
import { getDataWithRenamedProperty } from '@/src/helpers/api';

import { PaginatedSearchSelectField } from '../PaginatedSearchSelectField';

type Props = ComponentProps<typeof PaginatedSearchSelectField> & {
  filters: LegalEntityPayElementApi.Admin.LegalEntityPayElementsRequest['queryParams'];
};

export function LegalEntityPayElementCodeSelect({ filters = {}, ...selectFieldProps }: Props) {
  return (
    <PaginatedSearchSelectField
      query={{
        path: '/api/v1/rivendell/legal-entity-pay-elements',
        params: { queryParams: { ...filters } },
        options: {
          select: (res: LegalEntityPayElementApi.Admin.LegalEntityPayElementsResponse) =>
            getDataWithRenamedProperty(res, 'legalEntityPayElements'),
        },
      }}
      label="Pay item code"
      placeholder="Select a pay item code"
      loadingMessage={() => 'Loading pay item codes…'}
      getOptionLabel={({ code }: LegalEntityPayElementApi.LegalEntityPayElement) => code}
      getOptionValue={({ code }: LegalEntityPayElementApi.LegalEntityPayElement) => code}
      description="Search by pay item code"
      isControlled
      {...selectFieldProps}
    />
  );
}
