import { Box, Text } from '@remote-com/norma';
import styled from 'styled-components';

export const ProgressBarContainer = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
export const ErrorMessage = styled(Text).attrs({
  color: 'bayoux',
  variant: 'base',
  py: 5,
})`
  display: grid;
  place-items: center;
`;

export const Wrapper = styled(Box).attrs({
  boxShadow: 'large',
  px: 6,
  py: 5,
})`
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.blank};
  border: 0;
  border-radius: 12px;
  margin-bottom: -${({ theme }) => theme.space[2]}px;
`;

export const Icon = styled.svg`
  left: var(--modal-outer-spacing);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  fill: ${({ theme }) => theme.colors.bayoux};
`;

export const Input = styled.input`
  appearance: none;
  background-color: transparent;
  border: 0;
  color: ${({ theme }) => theme.colors.grey[900]};
  padding-left: ${({ theme }) => theme.space[6]}px;
  width: 100%;
  ${({ theme }) => theme.typography.base};

  &::placeholder {
    color: ${({ theme }) => theme.colors.lynch};
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    width: 10px;
    /* Note: In order to style the webkit search cancel button, we need to use a base64 encoded bg image **/
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAUGVYSWZNTQAqAAAACAACARIAAwAAAAEAAQAAh2kABAAAAAEAAAAmAAAAAAADoAEAAwAAAAEAAQAAoAIABAAAAAEAAAAToAMABAAAAAEAAAATAAAAADKyfrgAAAIwaVRYdFhNTDpjb20uYWRvYmUueG1wAAAAAAA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJYTVAgQ29yZSA2LjAuMCI+CiAgIDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+CiAgICAgIDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiCiAgICAgICAgICAgIHhtbG5zOmV4aWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20vZXhpZi8xLjAvIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDxleGlmOlBpeGVsWURpbWVuc2lvbj4xOTwvZXhpZjpQaXhlbFlEaW1lbnNpb24+CiAgICAgICAgIDxleGlmOlBpeGVsWERpbWVuc2lvbj4xOTwvZXhpZjpQaXhlbFhEaW1lbnNpb24+CiAgICAgICAgIDxleGlmOkNvbG9yU3BhY2U+MTwvZXhpZjpDb2xvclNwYWNlPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KOmI4FgAAAQNJREFUOBGtlD0OwjAMhd1WSBwjV+mGxMLYCyBxJI7AyMjGUcg9UCl5cVOlbuIECQ/5eY4/OY4Voj9aA9ZxOL8w76jr77erxbrWTsPFvGl84nzLQZ7pRThZK48RyMU0DBup7d3GuvBqoABZMHxKyOHgMuro49KdjNta7cop0MOVZ4HVAnMgxK9gJaAGSsJyQOjzqxnUFzXC1aAH22QWHLKGs25yIPizMDgFEMeTGeEsbO4z3shx74UpkidiLZKiZTYzWWyO0dsmCZMgbmqiUh9uYClQeDVRw01jr2AaKJRGAy6wGlAJ6GG/gDSgb41SZwdAPKOO8reJ/jO9IWNQWK+BDX0BCOPkMdB+EzEAAAAASUVORK5CYII=);
    background-size: 10px 10px;
  }

  &:focus {
    outline: 0;
  }
`;
