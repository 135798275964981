import DOMPurify from 'dompurify';
import type { ElementType } from 'react';

import type { ComponentProps } from '../../types';

export type HTMLRenderedProps<T extends ElementType> = {
  Tag: T;
  forwardedAs?: ElementType;
} & ComponentProps<T>;

// Deduplicates rel values if necessary and appends noopener and noreferrer
const appendSecureRelValue = (rel: string | null) => {
  const attributes = new Set(rel ? rel.toLowerCase().split(' ') : []);

  attributes.add('noopener');
  attributes.add('noreferrer');

  return Array.from(attributes).join(' ');
};

// checks for support first - DOMPurify requires presence of window
// without this, SSR + Webpack issues issues will occur
// https://github.com/cure53/DOMPurify/issues/526#issuecomment-2092086879
if (DOMPurify.isSupported) {
  DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    const target = node.getAttribute('target');

    // set value of target to be _blank with rel, or keep as _self if already set
    if (node.tagName === 'A' && (!target || target !== '_self')) {
      node.setAttribute('target', '_blank');
      const rel = node.getAttribute('rel');
      node.setAttribute('rel', appendSecureRelValue(rel));
    }
  });
}

export function HTMLRendered<T extends ElementType>({
  children,
  Tag,
  ...props
}: HTMLRenderedProps<T>) {
  return typeof children === 'string' ? (
    <Tag
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children, { ADD_ATTR: ['target'] }) }}
      {...props}
    />
  ) : (
    <Tag {...props}>{children}</Tag>
  );
}
