export type RemoteEngineeringTeam = {
  name: string;
  slack: { channel: string; userGroupId?: string };
};

export const remoteEngineeringTeams = [
  {
    name: 'Billing Core',
    slack: {
      // note: Slack channels don't include # so that we're able to create dynamic Slack handle in Datadog (https://docs.datadoghq.com/monitors/notify/variables/?tab=is_alert#advanced) if necessary.
      channel: 'dev-team-billing-core',
      userGroupId: 'S0572163A3U', // @billing-core-eng
    },
  },
  {
    name: 'Billing Experience',
    slack: {
      channel: 'dev-team-billing-experience',
      userGroupId: 'S0708E46KU7', // @billing-experience-eng
    },
  },
  {
    name: 'Billing Platform',
    slack: {
      channel: 'dev-team-billing-platform',
      userGroupId: 'S02391FDZU4', // @billing-platform-eng
    },
  },
  {
    name: 'Company Foundations',
    slack: {
      channel: 'dev-team-company-foundations',
      userGroupId: 'S08CGFD1NRW', // @company-foundations-eng
    },
  },
  {
    name: 'Contractor Experience',
    slack: {
      channel: 'dev-team-contractor-exp',
      userGroupId: 'S06721MDSKH', // @contractor-exp-eng
    },
  },
  {
    name: 'Contractor Management',
    slack: {
      channel: 'dev-team-contractor-mgmt',
      userGroupId: 'S06721GDXE3', // @contractor-mgmt-eng
    },
  },
  {
    name: 'Employee Payments',
    slack: {
      channel: 'dev-team-employee-payments',
      userGroupId: 'S02KXAB4SRL', // @employee-payments-eng
    },
  },
  {
    name: 'Equity Management',
    slack: {
      channel: 'dev-team-remote-equity',
      userGroupId: 'S086M2NNFQA', // @remote-equity-eng
    },
  },
  {
    name: 'Fraud & Strategic Payment Extensions',
    slack: {
      channel: 'dev-team-fraud-and-strategic-payments',
      userGroupId: 'S08AZD26A3G', // @dev-fraud-and-strategic-payments
    },
  },
  {
    name: 'Payments Core',
    slack: {
      channel: 'dev-team-payments-core',
      userGroupId: 'S06FJH75V4Z', // @payments-core-eng
    },
  },
  {
    name: 'Pricing',
    slack: {
      channel: 'dev-team-pricing',
      userGroupId: 'S05KGEKGN90', // @pricing-team
    },
  },
  {
    name: 'Tax & Accounting',
    slack: {
      channel: 'dev-team-tax-and-accounting',
      userGroupId: 'S04CWMH3YMR', // @tax-and-accounting-eng
    },
  },
  {
    name: 'Knowledge Management',
    slack: {
      channel: 'dev-team-knowledge-management',
      userGroupId: 'S06V2HXNL13', // @knowledge-management-eng
    },
  },
  {
    name: 'Company and People Foundations',
    slack: {
      channel: 'dev-team-company-and-people-foundations',
      userGroupId: 'S028MRF9R7U', // @company-and-people-foundations-eng
    },
  },
  {
    name: 'Contracts & Documents',
    slack: {
      channel: 'dev-team-contracts-and-documents',
      userGroupId: 'S0512M61E9Y', // @contracts-and-documents-eng
    },
  },
  {
    name: 'Employee Lifecycle',
    slack: {
      channel: 'dev-team-employee-lifecycle',
      userGroupId: 'S037L7NENS0', // @employee-lifecycle-eng
    },
  },
  {
    name: 'EOR XP',
    slack: {
      channel: 'dev-team-eor-xp',
      userGroupId: 'S05J7DX5XM4', // @eor-xp-eng
    },
  },
  {
    name: 'Jobs',
    slack: {
      channel: 'dev-team-jobs',
      userGroupId: 'S080WLZD75L', // @dev-team-remote-jobs
    },
  },
  {
    name: 'Mobile',
    slack: {
      channel: 'dev-team-mobile',
      userGroupId: '', // @mobile-team
    },
  },
  {
    name: 'Performance Management',
    slack: {
      channel: 'dev-team-performance-management',
      userGroupId: 'S06CBBMKCKY', // @performance-management-eng
    },
  },
  {
    name: 'Remote Talent',
    slack: {
      channel: 'dev-team-talent',
      userGroupId: 'S05HB13CTUK', // @remote-talent-eng
    },
  },
  {
    name: 'Time & Attendance',
    slack: {
      channel: 'dev-team-time-and-attendance',
      userGroupId: 'S06D02L9N80', // @time-and-attendance-platform-eng
    },
  },
  {
    name: 'Workforce Planning',
    slack: {
      channel: 'dev-team-workforce-planning',
      userGroupId: 'S05JE44CX1Q', // @workforce-planning-eng
    },
  },
  {
    name: 'Benefits Core',
    slack: {
      channel: 'dev-team-benefits',
      userGroupId: 'S02L42B916X', // @benefits-eng
    },
  },
  {
    name: 'Benefits Experience',
    slack: {
      channel: 'dev-team-benefits',
      userGroupId: 'S02L42B916X', // @benefits-eng
    },
  },
  {
    name: 'Benefits Integrations',
    slack: {
      channel: 'dev-team-benefits-3',
      userGroupId: 'S02L42B916X', // @benefits-eng
    },
  },
  {
    name: 'Analytics & Reporting',
    slack: {
      channel: 'dev-team-analytics-reporting',
      userGroupId: 'S070ZTB5KND', // @reporting-analytics
    },
  },
  {
    name: 'Customer Accounting Integrations',
    slack: {
      channel: 'dev-team-customer-accounting-integrations',
      userGroupId: 'S06EL8ZM2G3', // @customer-accounting-integrations-team
    },
  },
  {
    name: 'Customer HRIS Integrations',
    slack: {
      channel: 'dev-team-customer-hris-integrations',
      userGroupId: 'S05UW5S0RB4', // @customer-hris-integrations-team
    },
  },
  {
    name: 'Development Experience',
    slack: {
      channel: 'dev-team-development-experience',
      userGroupId: 'S03PUCN57K3', // @dev-experience-engineers
    },
  },
  {
    name: 'Identity & Access Management',
    slack: {
      channel: 'dev-team-identity-and-access-management',
      userGroupId: 'S04T73RKW4Q', // @iam-engineers
    },
  },
  {
    name: 'Web Experience',
    slack: {
      channel: 'dev-team-web-experience',
      userGroupId: 'S06RUA1P788', // @web-experience-engineers
    },
  },
  {
    name: 'Workflow Engine',
    slack: {
      channel: 'dev-team-workflow-engine',
      userGroupId: 'S073HMAV4KT', // @workflow-engine-team
    },
  },
  {
    name: 'Payroll Data and Platform',
    slack: {
      channel: 'dev-team-payroll-data-and-platform',
      userGroupId: 'S04FMKK4WRL', // @payroll-data-and-platform-eng
    },
  },
  {
    name: 'Payroll Onboarding',
    slack: {
      channel: 'dev-team-payroll-onboarding',
      userGroupId: 'S03GLGCSCGJ', // @payroll-onboarding-eng
    },
  },
  {
    name: 'Payroll Integrations',
    slack: {
      channel: 'dev-team-payroll-integrations',
      userGroupId: 'S03GDT6CXM4', // @payroll-integrations-eng
    },
  },
  {
    name: 'Expense Management',
    slack: {
      channel: 'dev-team-expense-management',
      userGroupId: 'S069RQ866R4', // @dev-expense-management
    },
  },
  {
    name: 'Powered by Remote',
    slack: {
      channel: 'dev-team-powered-by-remote',
      userGroupId: 'S03QH89SS4T', // @powered-by-remote-eng-team
    },
  },
  {
    name: 'PLG',
    slack: {
      channel: 'dev-team-plg',
      userGroupId: 'S04FQLNM5FZ',
    },
  },
  {
    name: 'Payroll Self-Serve Processing',
    slack: {
      channel: 'dev-team-payroll-self-serve-processing',
      userGroupId: 'S07TTJCNL5N', // @payroll-self-serve-processing-eng
    },
  },
] as const satisfies readonly RemoteEngineeringTeam[];

export type EngineeringTeamName = (typeof remoteEngineeringTeams)[number]['name'];

export const UNKNOWN_OWNERSHIP = 'unknown';
