import { Stack, Text } from '@remote-com/norma';
import type { LinkProps } from 'next/link';
import styled from 'styled-components';

import { ButtonInline } from '@/src/components/Button';

export const Container = styled(Stack).attrs({ gap: 7 })`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Illustration = styled.svg`
  height: 160px;
`;

export const Body = styled(Stack).attrs({ gap: 2 })`
  align-items: center;
`;

export const Title = styled(Text).attrs({
  color: 'grey.900',
  forwardedAs: 'h3',
  variant: 'xlMedium',
})`
  font-family: var(--typography-fontBrand);
  max-width: 40ch;
  text-wrap: balance;
`;

export const Description = styled(Text).attrs({
  color: 'grey.600',
  variant: 'sm',
})`
  max-width: 45ch;
  text-wrap: balance;
`;

export const SecondaryLink = ({ href, label }: { href: LinkProps['href']; label: string }) => (
  <ButtonInline href={href} target="_blank" rel="noopener noreferrer">
    {label}
  </ButtonInline>
);

export const Actions = styled(Stack).attrs({ direction: 'row', gap: 4 })`
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;
