import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';

import type { JSONSchema, JsonSchemaDependentRequired } from '@/src/api/config/employ/jsonSchemas';
import { camelCaseKeepDots } from '@/src/helpers/general';

type ExcludedFields = (string | RegExp)[];

const HANDLEBARS_REGEX = /\{\{([^{}]+)\}\}/g;

/**
 * @example
 * Input: "{{average_temp}}"
 * Output: "{{averageTemp}}"
 */
function camelCaseHandlebars(text: string) {
  return text.replace(HANDLEBARS_REGEX, (_, key) => {
    return `{{${camelCaseKeepDots(key)}}}`;
  });
}

/**
 * Transform JSON logic computedAttrs to camelCase, recursively.
 * @example
 * Input: { "description": "The avg temperature is {{average_temp}}." }
 * Output: { "description": "The avg temperature is {{averageTemp}}." }
 */
function transformComputedAttrs(computedAttrs: Record<string, unknown>) {
  return Object.entries(computedAttrs).reduce((acc, [key, value]) => {
    if (typeof value === 'object' && value !== null) {
      acc[key] = transformComputedAttrs(value as Record<string, unknown>);
    } else if (typeof value === 'string') {
      acc[key] = value.match(HANDLEBARS_REGEX)
        ? camelCaseHandlebars(value)
        : camelCaseKeepDots(value);
    }
    return acc;
  }, {} as Record<string, unknown>);
}

const isFieldExcluded = (key: string, excludedFields: ExcludedFields = []) =>
  excludedFields.some((field) => field === key || (field instanceof RegExp && field.test(key)));

function maybeCamelCase(fieldName: string, excludedFields: ExcludedFields = []) {
  if (isFieldExcluded(fieldName, excludedFields)) return fieldName;

  return camelCaseKeepDots(fieldName);
}

/**
 * @example
 * Input: { "has_pet": ["pet_name"] }
 * Output: { "hasPet": ["petName"] }
 */
function transformDependentRequired(
  dependentRequired: JsonSchemaDependentRequired,
  excludedFields?: ExcludedFields
): JsonSchemaDependentRequired {
  return Object.entries(dependentRequired).reduce((acc, [dependent, required]) => {
    const dependentName = maybeCamelCase(dependent, excludedFields);
    acc[dependentName] = required.map((fieldName) => maybeCamelCase(fieldName, excludedFields));
    return acc;
  }, {} as JsonSchemaDependentRequired);
}

/**
camelCase rules:
- Every key is converted to camelCase, except for:
  - 'x-jsf-*' keys because they are needed by json-schema-form.
    - If we convert it, then we'd need to un-convert before passing it to json-schema-form.
- Identify where the field keys are used as string and convert them too.
  The keys are "required", "x-jsf-order"
  For example:
  - Input: { required: ["has_pet", "pet_name"] }
  - Output: { required: ["hasPet", "petName"] }
- Support fieldsets (nested fields) and group-arrays.

- What else? Check for edge-cases.
 */
export function transformJsonSchema(
  jsonSchema: JSONSchema,
  config: { excludedFields?: ExcludedFields } = {}
): JSONSchema {
  const finalSchema = cloneDeep(jsonSchema);

  // Recursively process the schema object
  function processSchemaNode(node: any, fieldPath: string): JSONSchema {
    if (!isObject(node)) {
      return node;
    }

    const result: any = Array.isArray(node) ? [] : {};

    // Process each property in the node
    Object.entries(node).forEach(([key, value]) => {
      // Skip converting x-jsf-* keys
      const newKey = key.startsWith('x-jsf-') ? key : camelCase(key);

      if (
        ['required', 'x-jsf-order', 'x-jsf-logic-validations', 'propertiesByName'].includes(
          newKey
        ) &&
        Array.isArray(value)
      ) {
        result[newKey] = value.map((fieldName) => {
          return maybeCamelCase(fieldName, config.excludedFields);
        });
      } else if (['properties', 'items'].includes(key)) {
        // Recursively process nested schemas
        result[newKey] = processSchemaNode(value, fieldPath);
      } else if (Array.isArray(value)) {
        const hasSpecialChars = /[^\w\s]/.test(key); // json-logic, "+", "/", etc.

        // eg allOf, oneOf, etc. Go through each.
        result[hasSpecialChars ? key : newKey] = value.map((arrayItemVal: unknown) =>
          processSchemaNode(arrayItemVal, fieldPath)
        );
      } else if (key === 'dependentRequired') {
        result[newKey] = transformDependentRequired(value, config.excludedFields);
      } else if (key === 'var') {
        result[newKey] = maybeCamelCase(value, config.excludedFields);
      } else if (key === 'x-jsf-logic-computedAttrs') {
        result[newKey] = transformComputedAttrs(value);
      } else {
        const newFieldPath = fieldPath ? `${fieldPath}.${key}` : key;
        const isKeyExcluded = isFieldExcluded(newFieldPath, config.excludedFields);
        // Recursively process all keys
        result[isKeyExcluded ? key : newKey] = processSchemaNode(value, newFieldPath);
      }
    });

    return result;
  }

  return processSchemaNode(finalSchema, '');
}
