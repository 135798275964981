/* eslint-disable import/no-extraneous-dependencies */

import { getCompanyIdEventValue, hasWindow } from '../helpers';

import { captureHTTPException } from './captureException';
import { debug } from './utils';

type UserRole = 'admin' | 'deleted' | 'employer' | 'employee' | 'service_provider' | 'candidate';

type ProductEmploymentType = 'contractor' | 'eor' | 'global_payroll' | 'eor_team_plan';
type EmploymentType = 'contractor' | 'direct' | 'full_time';

type User = {
  activeEmployment?: {
    type: EmploymentType;
    product?: { employmentType?: ProductEmploymentType };
  };
  companySlug: string;
  slug: string;
  realUser?: {
    email: string;
    name: string;
    profilePicture: string | null;
    slug: string;
  };
};
type EmployeePersona = 'employee' | 'contractor' | 'freelancer';

type Traits = {
  employmentType?: EmploymentType;
  productEmploymentType?: ProductEmploymentType;
  userType: UserRole | EmployeePersona;
  debugCompanyIDhasSetProperties: boolean;
  debugCompanyID?: string;
  [key: string]: any;
};

type PageTrackingParamsTypes = {
  category?: string;
  name?: string;
  properties?: {
    [key: string]: any;
  };
};

export type Event = {
  name: string;
  eventProps: {
    [key: string]: any;
  };
};

export type RudderStackAnalyticsConfig = {
  RUDDERSTACK_WRITE_KEY: string;
  RUDDERSTACK_DATAPLANE_URL: string;
  RUDDERSTACK_ENABLED: boolean;
  RUDDERSTACK_USE_SERVER_SIDE_COOKIES: boolean;
};

let rudderanalytics: Promise<any>;
let extraUserProperties: {
  companyID?: string;
  [key: string]: any;
} = {};

let impersonationProperties = {};

const canBeEnabled = () => hasWindow();
const isActive = () => canBeEnabled() && typeof rudderanalytics !== 'undefined';

export async function initRudderStack({
  RUDDERSTACK_WRITE_KEY,
  RUDDERSTACK_DATAPLANE_URL,
  RUDDERSTACK_ENABLED,
  RUDDERSTACK_USE_SERVER_SIDE_COOKIES,
}: RudderStackAnalyticsConfig) {
  if (!canBeEnabled() || !RUDDERSTACK_ENABLED) return;

  try {
    rudderanalytics = import('rudder-sdk-js');
    window.rudderanalytics = await rudderanalytics;

    (await rudderanalytics).load(RUDDERSTACK_WRITE_KEY, RUDDERSTACK_DATAPLANE_URL, {
      integrations: { All: true },
      useBeacon: true,
      beaconQueueOptions: {
        maxItems: 5,
        flushQueueInterval: 10000,
      },
      useServerSideCookies: RUDDERSTACK_USE_SERVER_SIDE_COOKIES,
    });

    (await rudderanalytics).ready(() => {
      debug('RudderStack::loaded');
    });
  } catch (e) {
    debug('RudderStack::Error - Unable to load');
    captureHTTPException(e);
  }
}

export async function identifyUser(
  user: User | undefined,
  role: UserRole,
  employeePersona: EmployeePersona,
  customTraits = {}
) {
  if (!isActive() || !user) return;

  try {
    const { identify } = await rudderanalytics;

    extraUserProperties = {
      companyID: getCompanyIdEventValue(user.companySlug),
    };

    impersonationProperties = {
      ...(!!user?.realUser && {
        impersonatedUserSlug: user?.slug,
      }),
    };

    const userTraits: Traits = {
      userType: employeePersona || role,
      ...(employeePersona && {
        employmentType: user.activeEmployment?.type,
        productEmploymentType: user.activeEmployment?.product?.employmentType,
      }),
      debugCompanyIDhasSetProperties: true,
      ...(!user.companySlug && {
        debugCompanyID: 'value missing',
      }),
      ...customTraits,
      ...extraUserProperties,
      ...impersonationProperties,
    };

    // If we have Remote Admin who is impersonating (mimicking) another user, we treat main identity userSlug as that impersonator, we also send additional impersonatedUserSlug property to identify user who is being impersonated
    const userIdentitySlug = user?.realUser ? user.realUser.slug : user.slug;

    identify(userIdentitySlug, userTraits, { integrations: { All: true } }, () =>
      debug(`RudderStack::identifyUser, user: ${userIdentitySlug}`)
    );
  } catch (e) {
    captureHTTPException(e);
  }
}

export async function addUserTraits(
  user: User,
  role: UserRole,
  employeePersona: EmployeePersona,
  traits = {}
) {
  // identify calls can be used to add traits to a user, properties stack up - don't remove
  // previously added properties. Separated method so that it is clear in the codebase where
  // is the real identification and where traits are added.
  return identifyUser(user, role, employeePersona, traits);
}

export async function trackEvent(event: Event, props: { [key: string]: any } = {}) {
  if (!isActive()) return;

  try {
    const { name, eventProps } = event || {};

    (await rudderanalytics).track(
      name,
      { ...eventProps, ...props, ...extraUserProperties, ...impersonationProperties },
      () =>
        debug(
          `RudderStack::trackEvent | name: ${name} props: ${JSON.stringify(
            { ...eventProps, ...props, ...extraUserProperties, ...impersonationProperties },
            null,
            2
          )}`
        )
    );
  } catch (e) {
    captureHTTPException(e);
  }
}

export async function trackPageView({
  category = '',
  name = '',
  properties = {},
}: PageTrackingParamsTypes = {}) {
  if (!isActive()) return;

  try {
    (await rudderanalytics).page(category, name, { ...properties, ...extraUserProperties });
  } catch (e) {
    captureHTTPException(e);
  }
}

export async function resetTrackingUser() {
  if (!isActive()) return;

  try {
    (await rudderanalytics).reset();
    extraUserProperties = {};
  } catch (e) {
    captureHTTPException(e);
  }
}

export async function getAnonymousId() {
  if (!isActive()) return;

  try {
    return (await rudderanalytics).getAnonymousId();
  } catch (e) {
    captureHTTPException(e);
  }
}
