import { datadogRum } from '@datadog/browser-rum';
import { identifyUser } from '@remote-com/analytics';
import { useEffect } from 'react';

import { useUserContext } from '@/src/components/UserProvider';
import type { UserContextUser } from '@/src/components/UserProvider/context';
import { identifyAppcuesUser } from '@/src/domains/appcues/methods';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import {
  hasContractorEmployees,
  hasHadTalentSubscription,
  isEorUser,
} from '@/src/domains/payroll/helpers';
import { getUserRole, isEmployer } from '@/src/domains/registration/auth/helpers';
import { initSprig } from '@/src/domains/sprig/Sprig';

function setDatadogUser(user: UserContextUser | undefined) {
  if (user) {
    datadogRum.setUser({
      id: user.slug,
      role: user.role,
    });
  } else {
    datadogRum.removeUser();
  }
}

export function Analytics() {
  const { user } = useUserContext();

  const isInRecommendedCandidatesBeta = useIsFeatureFlagEnabled('recommended_candidates_v1');
  const isInTalentInsightsBeta = useIsFeatureFlagEnabled('talent_insights');
  // @ts-expect-error
  const { role, employeePersona } = getUserRole(user || {});

  useEffect(() => {
    // @ts-expect-error
    identifyUser(user, role, employeePersona, {
      ...(isEmployer(user) && {
        hasEorEmployees: isEorUser(user),
        hasContractorEmployees: hasContractorEmployees(user),
        hasHadTalentSubscription: hasHadTalentSubscription(user),
        hasActiveRecommendedCandidatesBeta: isInRecommendedCandidatesBeta,
        hasActiveTalentInsightsBeta: isInTalentInsightsBeta,
      }),
    });
    identifyAppcuesUser(user);
    setDatadogUser(user);
    initSprig(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.slug]);

  return null;
}
