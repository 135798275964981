/* eslint-disable react/no-unused-prop-types */
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import { BlockErrorBoundary } from '@/src/components/BlockErrorBoundary';
import { EditColumnsProvider } from '@/src/components/Table/Components/ColumnsConfigurationDrawer/hooks/useEditColumns';
import { EmptyStateComponent } from '@/src/components/Table/Components/EmpyState';
import NoResults from '@/src/components/Table/Components/NoResults';
import { TableBody } from '@/src/components/Table/Components/TableBody';
import {
  DeprecatedCustomTableFooter,
  StateWrapper,
} from '@/src/components/Table/Components/TableComponents.styled';
import { getTableFootGroups, TableFoot } from '@/src/components/Table/Components/TableFoot';
import {
  TableUserSettingsProvider,
  useTableUserSettingsContext,
} from '@/src/components/Table/Components/TableUserSettingsProvider';
import { TableToolbar } from '@/src/components/Table/Components/Toolbar/Toolbar';
import { injectRowSelectionCheckbox } from '@/src/components/Table/helpers/injectCheckbox';
import { tableStickyColumns } from '@/src/components/Table/helpers/tableStickyColumns';
import { useTableViews } from '@/src/components/Table/hooks/useTableViews';
import { TableFooter } from '@/src/components/Table/TableFooter/TableFooter';
import { isDev } from '@/src/helpers/general';
import useComponentDidUpdate from '@/src/hooks/useDidUpdate';
import usePrevious from '@/src/hooks/usePrevious';

import { TextCell } from './Cells';
import { TableHeaders } from './Components/TableHeader';
import TableRow from './Components/TableRow';
import { TextFilter } from './Filters';
import { decodeQueryParam, filterHiddenHeaders } from './helpers';
import { useColumnsState } from './hooks/useColumnsState';
import { useTableTracker } from './hooks/useTableTracker';
import { StyledContentLayout, TableStyled, TableWrapper } from './Table.styled';

export const DEFAULT_TABLE_PAGE_SIZE = 25;

const BoxWithQuestionMarkIllustration = dynamic(
  () =>
    import('@remote-com/norma/illustrations/BoxWithQuestionMarkIllustration').then(
      (mod) => mod.BoxWithQuestionMarkIllustration
    ),
  { ssr: false }
);

function resetScrollTopOfScrolledParentToZero(element) {
  let current = element.parentElement;
  while (current) {
    // Check is element is scrolled
    if (current.scrollTop) {
      current.scrollTo({ top: 0, behavior: 'instant' });
      return;
    }
    // Continue climbing to find scrolled parent
    current = current.parentElement;
  }
}

function validateColumnIds(columns) {
  return columns.every((col) => isString(col.id) || isString(col.accessor));
}

const defaultColumn = {
  // Let's set up our default Filter UI
  Filter: TextFilter,
  Cell: TextCell,
};

const TableContent = ({
  name,
  data,
  columns,
  columnLabels,
  columnAccessorExclusionSet,
  columnAccessorInclusionSet,
  specialAdditionalColumnsConfig,
  isLoading,
  isEmpty,
  isError,
  error,
  onRowSelectionChange,
  onRowSelectableChange,
  onAllRowsSelectableChange,
  isCheckboxDisabled,
  hideCheckboxes,
  disabledCheckboxTooltip,
  disableToggleAllCheckbox,
  disabledToggleAllCheckboxTooltip,
  onStateChange,
  initialState,
  globalFilter,
  setGlobalFilter,
  filters,
  columnConfig = true,
  hideToolbar,
  columnConfigPersistenceKey,
  className,
  pageCount,
  hideHeader,
  Footer,
  controlledPageIndex,
  pageSizeSwitcher,
  pageSizes,
  metadata,
  clientSidePagination,
  autoResetPage,
  onReloadClick,
  shouldScrollToTopOnPageChange,
  shouldFetchAllPagesOnSelectAll,
  shouldRenderSelectAll,
  renderRowSubComponent,
  autoResetExpanded,
  getSubRows,
  getRowId,
  CustomFilters,
  allowConfigureAllColumns,
  forceColumnOrderOnExport,
  allowSaveFilters,
  hideFooter,
  exportCSVDisplayText,
  isolateStackingContext,
  'data-testid': dataTestid,
  addRecordAction,
  selectAllLabel,
  selectionSupportingText,
  maxBulkSelectRows,
  customFooterContent,
  footerTotalsLabel,
  withExternalFilterStateUpdates,
  showExportButton,
  isTableViewsEnabledExperimental = false,
  smartSearch,
  ...props
}) => {
  if (isDev && Footer) {
    // eslint-disable-next-line no-console
    console.warn(
      'We are deprecating `Footer` support for tables. Please talk to the design team to find an alternative for your use case. 🙏'
    );
  }

  if (onRowSelectionChange) {
    // eslint-disable-next-line no-console
    console.warn(
      "You're onRowSelectionChange property, please consider using TableSelectable component instead, as it persists the selection between pages navigation"
    );
  }
  const { viewColumns, columnsState, changeColumnsOrder, ...restColumnState } = useColumnsState({
    columnConfig,
    columnConfigPersistenceKey,
    columns,
    columnLabels,
    columnAccessorExclusionSet,
    specialAdditionalColumnsConfig,
    data,
    forceColumnOrderOnExport,
    columnAccessorInclusionSet,
  });
  const isBulkEditActivated =
    isFunction(props.bulkEditOnClick) || isFunction(props.BulkEditComponent);

  const hasSelectCheckboxes =
    !hideCheckboxes &&
    (isFunction(onRowSelectionChange) || isFunction(onRowSelectableChange) || isBulkEditActivated);

  const injectedColumns = useMemo(
    () => injectRowSelectionCheckbox(viewColumns, hasSelectCheckboxes),
    [hasSelectCheckboxes, viewColumns]
  );

  const tableProps = {
    columns: injectedColumns,
    data,
    defaultColumn,
    manualSortBy: true,
    // if clientSidePagination is true, manualPagination must be false
    manualPagination: !clientSidePagination,
    pageCount,
    metadata,
    autoResetPage,
    isCheckboxDisabled,
    disabledToggleAllCheckboxTooltip,
    disableToggleAllCheckbox,
    disabledCheckboxTooltip,
    onRowSelectableChange,
    onAllRowsSelectableChange,
    autoResetExpanded,
    getSubRows,
    getRowId,
    allowSaveFilters,
    footerTotalsLabel,
  };
  const tableFeatures = [useSortBy, useExpanded, usePagination, useRowSelect];
  const tableRef = useRef();

  const showColumnsFooter = useMemo(
    () => columns.find((column) => !!column.Footer) && !isLoading && !hideFooter,
    [columns, isLoading, hideFooter]
  );

  if (isDev() && !validateColumnIds(columns)) {
    // eslint-disable-next-line no-console
    console.warn('All table columns should have an id or string accessor');
  }

  if (globalFilter) {
    tableProps.manualGlobalFilter = true;
    tableFeatures.unshift(useGlobalFilter);
  }

  if (filters) {
    tableProps.manualFilters = true;
    tableFeatures.unshift(useFilters);
  }

  // Setting page a default page size, even if no initial state is provided to the table
  tableProps.initialState = {
    ...(initialState
      ? {
          ...initialState,
          // need this to decode "%3A" ==> ":"
          // filter still works, just UI looks bad
          globalFilter: decodeQueryParam(initialState.globalFilter),
        }
      : {}),
    pageSize: initialState?.pageSize || DEFAULT_TABLE_PAGE_SIZE,
  };

  const [loadedSavedFilter, setLoadedSavedFilter] = useState(null);

  const tableInstance = useTable(tableProps, ...tableFeatures, tableStickyColumns);

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    gotoPage,
    footerGroups,
    setPageSize,
    page,
    ...restTableInstance
  } = tableInstance;

  const columnsStateProps = { ...restColumnState, columnsState };

  const tableViews = useTableViews({
    persistenceKey: columnConfigPersistenceKey,
    tableStateFilters: state.filters,
    tableStateSortBy: state.sortBy,
    columnsStateProps: { ...columnsStateProps, viewColumns },
    setAllFilters: tableInstance.setAllFilters,
    setSortBy: tableInstance.setSortBy,
    isTableViewsEnabledExperimental,
    viewColumns,
  });

  const {
    exportDataEvent: handleOnExportData,
    saveTableConfigEvent: handleSaveTableConfiguration,
    saveFilterEvent: handleSaveFilter,
    changeColumnsOrderEvent,
  } = useTableTracker({
    tableName: name,
    tableInstance,
    totalCount: props.totalCount,
    isLoading,
    loadedSavedFilter,
  });

  const { sortBy, pageIndex, pageSize } = state;

  const externalState = useMemo(
    () => ({
      pageIndex,
      pageSize,
      sortBy,
      filters: state.filters || [],
      globalFilter: state.globalFilter,
    }),
    [pageIndex, pageSize, sortBy, state]
  );

  const previousState = usePrevious(externalState);

  useEffect(() => {
    if (
      withExternalFilterStateUpdates &&
      props.tableState?.filters &&
      !isEqual(props.tableState.filters, state.filters)
    ) {
      tableInstance.setAllFilters(props.tableState.filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tableState?.filters]);

  useComponentDidUpdate(() => {
    if (isFunction(onStateChange)) {
      // When using pagination from useTable changing the sort or filters, will reset the pagination.
      // This effect run twice when sortBy/filter changes, once with the current pageIndex and then again with the reset pageIndex.
      // for reference https://github.com/tannerlinsley/react-table/issues/1729
      // So we need to manually compare the previous pageIndex with the current value and set pageIndex to 0 so that these two calls run
      // onStateChange with the same state and therefore not causing an extra call to the useTableFetcher. useTableFetcher use useQuery
      // which doesn't refetch if the search key is the same (in our case search key externalState)

      // prevent pagination reset from updating when using clientSidePagination && controlledPageIndex is null
      if (
        clientSidePagination &&
        controlledPageIndex === null &&
        isEqual(externalState.filters, previousState.filters) &&
        isEqual(externalState.globalFilter, previousState.globalFilter)
      ) {
        return;
      }

      if (externalState.pageIndex === previousState?.pageIndex) {
        onStateChange({
          ...externalState,
          pageIndex: 0,
        });
      } else {
        onStateChange(externalState);
      }
    }
  }, [onStateChange, externalState]);

  const currentPage = useMemo(
    () => (isNil(controlledPageIndex) ? pageIndex : controlledPageIndex) + 1,
    [controlledPageIndex, pageIndex]
  );

  const handleGoToPage = useCallback(
    (index) => {
      if (!isNil(controlledPageIndex)) {
        onStateChange({
          ...externalState,
          pageIndex: index,
        });
      } else {
        gotoPage(index);
      }
    },
    [controlledPageIndex, externalState, gotoPage, onStateChange]
  );

  /**
   * In some cases when we are on a page that's not the first one and delete everything on that page we get stuck in it with no rows
   * In here we check if we the current page is bigger than the number of pages and if so we send to the last page with results
   * But before going to the last page with results, we need to also check if there is a page (pageCount >= 1),
   * Otherwise we might end up going to a negative index if pageCount is 0
   */
  useEffect(() => {
    if (!isLoading && currentPage > pageCount && currentPage > 1 && pageCount >= 1) {
      handleGoToPage(pageCount - 1);
    }
  }, [currentPage, handleGoToPage, isLoading, pageCount, pageIndex]);

  const numberOfColumns = filterHiddenHeaders(tableProps.columns).length;
  const widths = filterHiddenHeaders(tableProps.columns).map(({ style, maxWidth }) =>
    !isNil(style?.width) ? style?.width : maxWidth
  );

  /** Renders <tbody /> and its rows */
  let tableBody = <tbody />;
  if (!isEmpty || !(rows.length === 0 && preGlobalFilteredRows?.length)) {
    tableBody = (
      <TableBody
        {...props}
        isCheckboxDisabled={isCheckboxDisabled}
        isLoading={tableViews.isLoading || isLoading}
        numberOfColumns={numberOfColumns}
        prepareRow={prepareRow}
        renderRowSubComponent={renderRowSubComponent}
        rows={rows}
        page={page}
        clientSidePagination={clientSidePagination}
        hasSelectCheckboxes={hasSelectCheckboxes}
        {...restTableInstance}
      />
    );
  }

  const onPageChange = (updatedPageIndex) => {
    window.requestAnimationFrame(() => {
      if (tableRef.current && shouldScrollToTopOnPageChange) {
        resetScrollTopOfScrolledParentToZero(tableRef.current);
      }
    });
    handleGoToPage(updatedPageIndex);
  };

  const saveFiltersConfig = {
    enabled: allowSaveFilters,
    tableId: columnConfigPersistenceKey,
    trackOnSaveFilter: handleSaveFilter,
    setLoadedSavedFilter,
  };

  /**
   *
   * @param {object} changeColumnsOrderProps
   * @param {string} changeColumnsOrderProps.id
   * @param {number} changeColumnsOrderProps.index
   */
  const handleChangeColumnsOrder = (changeColumnsOrderProps) => {
    changeColumnsOrder(changeColumnsOrderProps);
    changeColumnsOrderEvent({ columnId: changeColumnsOrderProps.id });
  };

  const { isCompactTable } = useTableUserSettingsContext();
  return (
    <BlockErrorBoundary>
      {CustomFilters && (
        <CustomFilters
          state={state}
          setAllFilters={restTableInstance.setAllFilters}
          metadata={metadata}
        />
      )}
      <StyledContentLayout
        className={className}
        $space="narrow"
        $direction="column"
        $footerExists={Boolean(Footer)}
        $isolate={isolateStackingContext}
      >
        <EditColumnsProvider
          columnsStateProps={{
            ...restColumnState,
            columnsState,
            changeColumnsOrder: handleChangeColumnsOrder,
          }}
          allowConfigureAllColumns={allowConfigureAllColumns}
          onSaveColumnsConfiguration={handleSaveTableConfiguration}
          isTableViewsEnabledExperimental={isTableViewsEnabledExperimental}
        >
          <TableToolbar
            columnConfig={columnConfig}
            hideToolbar={hideToolbar}
            onRowSelectionChange={onRowSelectionChange}
            isBulkEditActivated={isBulkEditActivated}
            name={name}
            state={state}
            columnsStateProps={{ ...restColumnState, columnsState }}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            filters={filters}
            saveFiltersConfig={saveFiltersConfig}
            columns={columns}
            isCheckboxDisabled={isCheckboxDisabled}
            data={data}
            metadata={metadata}
            pageCount={pageCount}
            filterColumns={headerGroups.flatMap(({ headers }) =>
              headers.filter(({ canFilter }) => canFilter).map((column) => column)
            )}
            exportCSVDisplayText={exportCSVDisplayText}
            onReloadClick={onReloadClick}
            shouldFetchAllPagesOnSelectAll={shouldFetchAllPagesOnSelectAll}
            shouldRenderSelectAll={shouldRenderSelectAll}
            onExportData={handleOnExportData}
            addRecordAction={addRecordAction}
            showExportButton={showExportButton}
            selectAllLabel={selectAllLabel}
            selectionSupportingText={selectionSupportingText}
            maxBulkSelectRows={maxBulkSelectRows}
            clientSidePagination={clientSidePagination}
            allowConfigureAllColumns={allowConfigureAllColumns}
            columnConfigPersistenceKey={columnConfigPersistenceKey}
            tableViews={tableViews}
            queryKey={props.queryKey}
            smartSearch={smartSearch}
            {...props}
            {...restTableInstance}
          />
        </EditColumnsProvider>
        <TableWrapper data-loading={tableViews.isLoading || isLoading}>
          <TableStyled
            {...getTableProps()}
            data-testid={dataTestid}
            ref={tableRef}
            $columnsCount={numberOfColumns}
            $widths={widths}
            $isCompactTable={isCompactTable}
          >
            {!hideHeader && (
              <thead>
                {headerGroups.map((headerGroup) => {
                  const tableHeaders = filterHiddenHeaders(headerGroup.headers);
                  return (
                    <TableRow
                      data-testid="table-header"
                      key={headerGroup.key}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      <TableHeaders
                        // in the component we create one useRef for each column and when the number changes it breaks so this key forces it to re render
                        key={tableHeaders.length}
                        tableRef={tableRef}
                        tableName={name}
                        columns={tableHeaders}
                        columnResize={props.columnResize}
                        columnConfig={columnConfig}
                        toggleColumnVisibility={restColumnState.toggleColumnState}
                      />
                    </TableRow>
                  );
                })}
              </thead>
            )}
            {/* Heads up! This renders <tbody /> */}
            {tableBody}
            {showColumnsFooter && (
              <TableFoot
                groups={getTableFootGroups({
                  reactTableColumns: injectedColumns,
                  reactTableFooterGroups: footerGroups,
                })}
                footerTotalsLabel={footerTotalsLabel}
              />
            )}
          </TableStyled>

          {Footer && (
            <DeprecatedCustomTableFooter>
              <Footer />
            </DeprecatedCustomTableFooter>
          )}
        </TableWrapper>
        {!isLoading && isError && (
          <StateWrapper>
            <NoResults
              title={error?.message || 'Sorry, something went wrong on our end'}
              description={
                error?.message
                  ? 'Sorry, something went wrong on our end. Please retry or contact your Remote representative.'
                  : 'Please retry or contact your Remote representative.'
              }
              Illustration={BoxWithQuestionMarkIllustration}
            />
          </StateWrapper>
        )}
        {!isLoading && !isError && isEmpty && (
          <StateWrapper>
            <EmptyStateComponent
              externalState={externalState}
              EmptyBlock={props.EmptyBlock}
              colSpan={numberOfColumns}
            />
          </StateWrapper>
        )}
        <TableFooter
          isLoading={isLoading}
          hasPageSizeSupport={pageSizeSwitcher}
          onPageChange={onPageChange}
          onPageSizeChange={pageSizeSwitcher && ((size) => setPageSize(size))}
          pageIndex={currentPage - 1}
          pageSize={pageSize}
          pageSizeOptions={pageSizes}
          totalPages={pageCount}
          totalRecords={props.totalCount}
          customContent={customFooterContent}
          footerTotalsLabel={footerTotalsLabel}
        />
      </StyledContentLayout>
    </BlockErrorBoundary>
  );
};

const Table = (props) => (
  <TableUserSettingsProvider>
    <TableContent {...props} />
  </TableUserSettingsProvider>
);

Table.defaultProps = {
  getFilterLabel: (_, value) => value,
  globalFilter: false,
  globalFilterProps: {},
  disableToggleAllCheckbox: false,
  filters: false,
  pageCount: 1,
  hideHeader: false,
  transformFilters: (a) => a,
  pageSizeSwitcher: true,
  pageSizes: [25, 50, 100],
  columnConfig: true,
  columnResize: true,
  allowConfigureAllColumns: true,
  forceColumnOrderOnExport: false,
  columnConfigPersistenceKey: 'generic-table',
  totalCount: 0,
  clientSidePagination: false,
  autoResetPage: true,
  shouldScrollToTopOnPageChange: true,
  shouldFetchAllPagesOnSelectAll: true,
  shouldRenderSelectAll: true,
  autoResetExpanded: true,
  getSubRows: (row) => row.subRows || [],
  getRowId: undefined,
  allowSaveFilters: false,
  exportCSVDisplayText: 'Export data',
  exportFooter: false,
  isolateStackingContext: true,
  smartSearch: {
    enabled: false,
  },
};

export const columnsType = {
  /**
    [native]
    This string/function is used to build the data model for your column.
  */
  accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /**
    [native]
    This is the unique ID for the column. It is used by reference in things like sorting, grouping, filtering etc.
  */
  id: PropTypes.string,
  /**
    [native]
    A nested array of columns.
  */
  columns: PropTypes.arrayOf(PropTypes.shape),
  /**
    [native]
    If a function/component is passed, it will be used for formatting the header value, eg. You can use a Header function to dynamically format the header using any table or column state
  */
  Header: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.elementType]),
  /**
   * [native]
   * If a function/component is passed, it will be used for formatting the footer value, eg. You can use a Footer function to dynamically format the footer using any table or column state.
   *
   * **Deprecated** We are deprecating `Footer` support for tables. Please talk to the design team to find an alternative for your use case. 🙏
   */
  Footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.elementType]),
  /**
    [native]
    Specifies the width for the column (when using non-table-element layouts)
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
    [native]
    Specifically useful when using plugin hooks that allow the user to resize column widths
  */
  minWidth: PropTypes.number,
  /**
    [native]
    Specifies the maximum width for the column (when using non-table-element layouts)
  */
  maxWidth: PropTypes.number,
  /**
    [native]
    This function (or component) is primarily used for formatting the column value, eg. If your column accessor returns a date object, you can use a Cell function to format that date to a readable format.
  */
  Cell: PropTypes.oneOfType([
    PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.elementType]),
    PropTypes.func,
  ]),
  /**
    [custom]
    This function (or component) is used to render this column's filter UI
    */
  Filter: PropTypes.oneOfType([
    PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.elementType]),
    PropTypes.func,
  ]),
  /**
    [custom]
    Specifies the sort index the column filter, higher values are first to be rendered
    */
  filterSortIndex: PropTypes.number,
  /**
    [custom]
    If set it will stick this column to the right or left of the table.
  */
  sticky: PropTypes.oneOf(['right', 'left']),
  /**
    [custom]
    Column is disabled in view and export
  */
  hiddenColumn: PropTypes.bool,
  /**
    [custom]
    Should this be shown in the view?
  */
  disableInView: PropTypes.bool,
  /**
    [custom]
    Should this be shown in CSV export?
  */
  disableExport: PropTypes.bool,
  /**
    [custom]
    If true no filters will be applied to this column.
  */
  disableFilters: PropTypes.bool,
  /**
    [custom]
    If true no sort by will be visible for this column.
  */
  disableSortBy: PropTypes.bool,
  /**
    [custom]
    If true this column will not be resizable
  */
  disableResize: PropTypes.bool,
  /**
    [custom]
    String accesor like 'parent.child.value' or accessor function (sync or async).
    This function receives the row data and returns a string that will be displayed on the CSV export
  */
  exportData: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /**
    [custom]
    Accessor function.
    This function receives the table data and returns a string that will be displayed on the footer row of the CSV export
    It is only used if the table's prop 'exportFooter' is set to 'true'
  */
  footerExportData: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /**
    [custom]
     This property activates the ellipsis for the column. Remember to combine it with the maxWidth property
  */
  ellipsis: PropTypes.bool,
  /**
    [custom]
    Not required. If set it will align content to the right.
    Undefined by default (content is left-aligned).
  */
  align: PropTypes.oneOf(['right']),
};

Table.propTypes = {
  /**
   * Array of objects that will be used to generate table data.
   * It doesn't need to be the final shape, as custom data accessor functions
   * can be defined for each column.
   *
   * For most cases, it's safe to use the API response directly.
   *
   */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * Column specification. Required property: accessor.
   *
   * In most cases, you would want to defined Header as well.
   *
   * For more information see the type below
   */
  columns: PropTypes.arrayOf(PropTypes.shape(columnsType)).isRequired,

  /**
   * Data is loading. Will show a Remote loading indicator.
   */
  isLoading: PropTypes.bool,

  /**
   * Show No Results state of the table.
   *
   * It will be shown automatically in case of client-side filtering, for
   * controlled tables this explicit prop should be used.
   */
  isEmpty: PropTypes.bool,

  /**
   * Show error state of the table.
   */
  isError: PropTypes.bool,

  /**
   * Wheather or not to display the table footer
   */
  hideFooter: PropTypes.bool,

  /**
   * Component to render when table is empty
   */
  EmptyBlock: PropTypes.elementType,

  /**
   * Component to render as a footer of the table.
   */
  Footer: PropTypes.elementType,
  /**
   * If specified, enables row selection functionality. Receives an array of selected rows.
   *
   * todo: we should consider moving this into state to make row selection
   * API consistent with filters and other stata api's
   */
  onRowSelectionChange: PropTypes.func,
  /**
   * Only works when onRowSelectionChange is set
   * Function that receives the row and determines if the checkbox in that row is disabled
   * Defaults to false
   */
  isCheckboxDisabled: PropTypes.func,
  /**
   * Tooltip to be shown if a checkbox is disabled due to isCheckboxDisabled.
   * Can be a static string or a function that returns a string at runtime.
   */
  disabledCheckboxTooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Disable the ToggleAllCheckbox. If not all rows can be selected together (due to
   * isCheckboxDisabled), it should be false.
   */
  disableToggleAllCheckbox: PropTypes.bool,
  /**
   * If specified, enables row clicking functionality. Receives clicked row.
   */
  onRowClick: PropTypes.func,

  /**
   * This property controls global filter behavior. When it's defined,
   * a global filter will be rendered.
   */
  globalFilter: PropTypes.bool,

  /**
   * This property controls filters behavior. When it's defined,
   * filters block will be rendered for every column that can be filtered.
   */
  filters: PropTypes.bool,
  /**
   * This property allows to hide the "Filter" button,
   * irrespective of the `filters` prop value. This allows to enable filtering
   * functionality by other means, like via FilterToolbarComponent or CustomFilters.
   */
  hideToolbarFilterButton: PropTypes.bool,
  /**
   * Allows to render custom filter component(s) in the table toolbar.
   * They receive filter state as props.
   */
  FilterToolbarComponent: PropTypes.elementType,
  /**
   * This property is used to hide additional columns config
   */
  allowConfigureAllColumns: PropTypes.bool,

  /**
   * This property is used to force order of columns as send from component
   */
  forceColumnOrderOnExport: PropTypes.bool,

  /**
   * This callback is executed when the Filters button is clicked and the filters
   * popover is opened.
   */
  onFilterOpen: PropTypes.func,

  /**
   * Table has internal state. Think about selected sorting, filters, or page —
   * all that is stored in internal state.
   *
   * If table is populated by server data, internal state is used to fetch relevant
   * data from the backend.
   *
   * Roughly, we can illustrate data flow like this:
   * Table (sends internal state) → API → (returns relevant data) → Table
   *
   * Every time any of the internal state properties change, onStateChange callback is
   * invoked.
   *
   * interface TableState {
   *     pageIndex: number;
   *     pageSize: number;
   *     sortBy: [{
   *       id: string(column id),
   *       desc: boolean
   *     }],
   *     filters: [{
   *       id: string(column id),
   *       value: string
   *     }],
   *     globalFilter: string;
   * }
   */
  onStateChange: PropTypes.func,

  /**
   * In a real application, table state like filters, sorting or pagination might be persisted outside of the
   * table itself. For example, in the query string.
   *
   * initialState allows setting table state for the first render.
   *
   * For example, the data might flow like this
   *
   * State is created from Query String and set on the Table as initialState prop.
   * Every time state changes, onStateChange is invoked.
   * onStateChange saves new state into query string.
   * Query String values are used to fetch new data.
   *
   */
  initialState: PropTypes.shape({
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
    sortBy: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        desc: PropTypes.bool,
      })
    ),
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.string,
          PropTypes.number,
          PropTypes.arrayOf(PropTypes.string),
        ]),
      })
    ),
    globalFilter: PropTypes.string,
  }),
  /**
   * Sets the total number of pages. If not defined or equal to 1, Pagination component won't render
   */
  pageCount: PropTypes.number,
  /**
   * The total number of rows the given dataset have regardless of the number of pages it may have.
   */
  totalCount: PropTypes.number,
  /**
   * Sets the page index based on the server request. If not defined Pagination will use internal table state pageIndex
   */
  controlledPageIndex: PropTypes.number,
  /**
   * Props or attributes to be passed to each table row. Whene these attributes need to be dynamic (ie depend on row data),
   * we can pass a funcion that takes 2 arguments:
   * - 1st argument: An object with { key, role }
   * - 2nd argument: An object with { row, instance, userProps }
   *   Usually, what we need is the 'row' value, in order to do something like:
   * @examples
   * rowProps={(_, { row }) => ({
   *   href: `SOME_ROUTE?query=${row.original.slug}`,
   * })}
   */
  rowProps: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.string), PropTypes.func]),
  /**
   * Allows to override global search input placeholder
   */
  globalFilterPlaceholder: PropTypes.string,
  /**
   * An additional text that is rendered in the Table toolbar after globalFilter and filters
   */
  toolbarSupportingText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Hide thead content
   */
  hideHeader: PropTypes.bool,
  /**
   * Props or attributes to be passed to global filter (SearchField)
   */
  globalFilterProps: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number])
  ),

  /**
   * Allow to normalize filters between selecting them via widget and passing them to table state
   * For example change date input from "2000-mm-dd" to "2000", because API can handle it.
   */
  transformFilters: PropTypes.func,
  /**
    Do you wish to show a button to download the contents as a csv file?
    If true is passed a button will show that downloads a file called "export", if a string is passed the file will have that name
    IMPORTANT: The exportCSV features expects your data to be in either `data.data` or `data.data.data`
   */
  exportCSV: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Includes a byte-order mark (BOM) character at the beginning of the CSV file.
   * This allows to open a CSV file directly in Excel with the correct UTF-8 encoding.
   * More info at: https://stackoverflow.com/q/2223882, https://stackoverflow.com/a/155176
   *
   * IMPORTANT: only use this if the data is not processed by scripts.
   * Scripts ingesting data exported with a BOM character might need to be updated to trim it.
   */
  exportWithExcelSupport: PropTypes.bool,
  /**
   * When set to 'true' it will include the table's footer in the CSV export.
   *
   * This is specially useful when the table's footer includes some kind of aggregation.
   *
   * You can leverage the column's property 'footerExportData' to define what value
   * should be exported in the CSV's footer row, for the corresponding column.
   */
  exportFooter: PropTypes.bool,
  /**
    When TRUE it will display a confirmation modal before start downloading the exported file.
    The modal will only appear if the table content contains sufficient data to be considered a large file.

    When FALSE the file to be exported will start as soon as the export button is clicked.

    OBS: You should also provide "totalCount" prop

    IMPORTANT: Your component needs to be wrapped with <ModalProvider />.
   */
  requireConfirmationToDownloadLargeFile: PropTypes.bool,
  /**
    An array of actions that will be available in action menu together with exportCSV if present
   */
  menuActionItems: PropTypes.arrayOf(
    PropTypes.shape({
      actionIcon: PropTypes.elementType.isRequired,
      label: PropTypes.node.isRequired,
      action: PropTypes.func.isRequired,
    })
  ),
  /**
   Label for the filters
   */
  getFilterLabel: PropTypes.func,

  /**
   * Show or hide the page size switcher
   */
  pageSizeSwitcher: PropTypes.bool,

  /**
   * Array of numbers to generate page size switcher options.
   * eg: [25, 50, 100]
   */
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  /**
   * columnResize — Adds the ability for columns to be resizable. Defaults to true
   */

  columnResize: PropTypes.bool,
  /**
   * columnConfig — enable a menu that allows column configuration. Defaults to true
   */
  columnConfig: PropTypes.bool,
  /**
   * hideToolbar — force hide toolbar. For example, when we don't have filters or
   * columnConfig, but have bulkEdit with a component outside table component
   */
  hideToolbar: PropTypes.bool,

  /**
   * This property allows to scope column configuration settings. For example, to
   * separate settings of Expenses table and those of Incentives table. Unique string identifier, not
   * visible in the interface.
   */
  columnConfigPersistenceKey: PropTypes.string,

  /**
   * Set of accessors that are excluded from column configuration.
   */
  columnAccessorExclusionSet: PropTypes.instanceOf(Set),

  /**
   * Set of accessors that are allowed.
   */
  columnAccessorInclusionSet: PropTypes.instanceOf(Set),

  /**
   * What to do when bulk edit is enabled and the edit rows button is clicked?
   * Receives the selected rows as a parameter.
   */
  bulkEditOnClick: PropTypes.func,

  /**
   * Show a custom component instead of the default selected rows action button.
   *
   * **NOTE:** Make sure to pass down `props` from `BulkEditComponent`.
   *
   * @example
   * // Primary action button
   * <Table
   *   BulkEditComponent={(props) => (
   *     <SelectedRowsActionButton {...props} />
   *   )}
   * />
   *
   * @example
   * // Primary and secondary action buttons
   * <Table
   *   BulkEditComponent={(props) => (
   *     <SelectedRowsActionButton {...props} onClick={() => {}} />
   *     <SelectedRowsActionButton {...props} tone="secondary" onClick={() => {}} />
   *   )}
   * />
   *
   * @example
   * // Multiple actions in menu
   * <Table
   *   BulkEditComponent={(props) => (
   *     <SelectedRowsActionsDropdownMenu {...props}>
   *       <SelectedRowsActionsDropdownMenu.Item onSelect={() => {}} />
   *       <SelectedRowsActionsDropdownMenu.Item onSelect={() => {}} />
   *       <SelectedRowsActionsDropdownMenu.Item onSelect={() => {}} />
   *     </SelectedRowsActionsDropdownMenu>
   *   )}
   * />
   */
  BulkEditComponent: PropTypes.func,

  /**
   * Allow the pagination being handled by react table instead of being handled server side.
   * This is useful when we don't have pagination from the backend and there are a lot of rows to be
   * shown.
   *
   * A better example is bulk uploading. We can receive hundreds of rows of data that can be inline
   * edited and we want to load them accross different pages instead of loading everything without
   * pagination.
   *
   * To use clientSidePagination, there are some props you should pass as well. Like:
   * - pageCount
   * - pageSize
   */
  clientSidePagination: PropTypes.bool,
  /**
   * autoResetPage prop is `true` by default by react-table.
   * This prop is necessary when client side pagination is true and we need inline editing as well.
   * We do not want to reset the page when data changes!
   */
  autoResetPage: PropTypes.bool,

  /**
   * A handler to be able to react to the clicks in the reload button
   */
  onReloadClick: PropTypes.func,

  /**
   * If set to `true`, scrolls to the top of the table on page change.
   */
  shouldScrollToTopOnPageChange: PropTypes.bool,

  /**
   * Since we do not have batch update all endpoints for all resources, we need to fetch all slugs
   * when clicking to select all rows, to be able to pass these slugs to the update endpoint.
   * If we do have an endpoint to update and delete in batch, we don't need to fetch anything
   */
  shouldFetchAllPagesOnSelectAll: PropTypes.bool,

  /**
   * If set to `true`, on tables with bulk selection, it will render a "Select all N"
   * button on the toolbar to select all rows across all pages.
   *
   * In some cases we don't want to have the ability to select all table rows,
   * for instance if we don't have a bulk endpoint, we want to avoid making too
   * many parallel requests to the BE.
   */
  shouldRenderSelectAll: PropTypes.bool,

  /**
   * A function that renders whatever we want to render when a row is expanded.
   */
  renderRowSubComponent: PropTypes.func,
  /**
   * If set to `true`, all expanded rows will be collapsed when table data is updated.
   */
  autoResetExpanded: PropTypes.bool,
  /**
   * Change how react-table detects subrows. By default it looks for a `subRows` property.
   * Here you can specify a custom key or method to get subrows data.
   */
  getSubRows: PropTypes.func,
  /**
   * Change how react-table detects unique rows. By default it uses the row index, and the parent row id if there's any.
   * Here you can pass in a function to build a custom id.
   */
  getRowId: PropTypes.func,
  /**
   * A custom component that can be used to set filters outside the table. It will receive
   * setAllFilters, state and metadata props which can be used to set the filters and render some metadata
   */
  CustomFilters: PropTypes.func,
  /**
   * If set to `true`, together with `hideToolbar` set to `false` will allow users to save/load filters in/from localstorage
   */
  allowSaveFilters: PropTypes.bool,
  /**
   * The name property defines the name that will be used to report to Rudderstack
   */
  name: PropTypes.string.isRequired,
  exportCSVDisplayText: PropTypes.string,
  /**
   * Each table starts a new stacking context by default. You can opt out of it by
   * setting this to false.
   */
  isolateStackingContext: PropTypes.bool,
  /**
   * If provided, sets the value of the `data-testid` attribute on the `table` element DOM node
   */
  'data-testid': PropTypes.string,
  /**
   * React component for "add record" button
   *
   * Preferably use `AddTableRecordButton` which comes preconfigured with the correct variant and size.
   */
  addRecordAction: PropTypes.element,
  /**
   * Toggle to render "Export data" button outside of menu
   */
  showExportButton: PropTypes.bool,
  /**
   * Toggle the table views feature, that allows users to save and restore
   * The full table state including column visibility, order, filters and sorting.
   */
  isTableViewsEnabledExperimental: PropTypes.bool,
  /**
   * Custom label for the select all button
   */
  selectAllLabel: PropTypes.string,
  /**
   * Helper text for edit/select all functionality
   */
  selectionSupportingText: PropTypes.string,
  /**
   * Validation schema for the filters, to be used with `yup`. Currently doesn't include the global filter.
   * Important that the keys in the schema match the column id of the filter you want to validate.
   */
  filterValidationSchema: PropTypes.object,
  /**
   * Limits the number of rows that can be fetched in bulk
   */
  maxBulkSelectRows: PropTypes.number,
  /**
   * Pass-through property for injecting elements before the pagination in the footer
   */
  customFooterContent: PropTypes.element,
  /**
   * Element to be displayed in the footer to indicate the total number of records, will default to 'Total'
   */
  footerTotalsLabel: PropTypes.element,
  /**
   * If true the table will listen to for updates on the external filter state and update the table accordingly
   */
  withExternalFilterStateUpdates: PropTypes.bool,
  /**
   * If true the table will use cursor based pagination for faster exports
   *
   * Note: the cursor strategy is ONLY used during exports
   */
  useCursorPaginationForExports: PropTypes.bool,
  /**
   * Smart search configuration consisting of a boolean flag to enable/disable the feature
   * and an array of objects with label and value properties to pass as additional context to Remote AI service.
   */
  smartSearch: PropTypes.shape({
    /**
     * Whether the smart search feature is enabled for this table
     */
    enabled: PropTypes.bool,
    /**
     * Placeholder for the smart search input
     */
    placeholder: PropTypes.string,
    /**
     * What field or fields are searchable by the table's global search (eg: "name", "name and email", "slug")
     */
    globalSearchFields: PropTypes.string,
  }),
  /**
   * Metadata to be passed to the table. You can access this value in react-table's internals when rendering cells, footers, etc.
   */
  metadata: PropTypes.object,
};

export default Table;

// We want the column types to show in the documentation and this is the only way :(
export const Column = (props) => <>{JSON.stringify(props)}</>;

Column.propTypes = columnsType;
