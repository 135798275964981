import { Stack, Text } from '@remote-com/norma';
import type { ComponentProps } from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { LegalEntityPayElementApi } from '@/src/api/config/employ/legalEntityPayElement.types';
import { PaginatedSearchSelectField } from '@/src/components/Ui/Select/PaginatedSearchSelectField';
import {
  PAYROLL_DATA_SOURCE_LABELS,
  PAY_ELEMENT_BENEFICIARY_TYPES_LABELS,
  PAY_ELEMENT_PAYOR_LABELS,
  PAY_ELEMENT_UNIT_TYPES_LABELS,
} from '@/src/domains/payElements/constants';
import { getDataWithRenamedProperty } from '@/src/helpers/api';
import { friendlyKeyToLabel } from '@/src/helpers/general';
import { getFromConstObject } from '@/src/helpers/getFromConstObject';

interface LepeOptionProps extends OptionProps {
  data: LegalEntityPayElementApi.LegalEntityPayElement;
}

function LepeOption(props: LepeOptionProps) {
  const { data } = props;
  const subtleColor = props.isDisabled ? undefined : 'grey.500';

  return (
    <components.Option {...props}>
      <Stack gap={4}>
        <Stack direction="row" flexWrap="wrap" alignItems="baseline" gap={2}>
          <Text>{data.name}</Text>{' '}
          <Text variant="2xs" color={subtleColor}>
            ({data.code})
          </Text>
        </Stack>

        <Stack>
          <Text variant="2xs" color={subtleColor}>
            Type:{' '}
            {getFromConstObject(
              PAY_ELEMENT_UNIT_TYPES_LABELS,
              data.type,
              friendlyKeyToLabel(data.type)
            )}
          </Text>
          <Text variant="2xs" color={subtleColor}>
            Data source:{' '}
            {getFromConstObject(
              PAYROLL_DATA_SOURCE_LABELS,
              data.payrollDataSource,
              friendlyKeyToLabel(data.payrollDataSource)
            )}
          </Text>

          {data.beneficiaryType && (
            <Text variant="2xs" color={subtleColor}>
              Beneficiary type:{' '}
              {getFromConstObject(
                PAY_ELEMENT_BENEFICIARY_TYPES_LABELS,
                data.beneficiaryType,
                friendlyKeyToLabel(data.beneficiaryType)
              )}
            </Text>
          )}

          {data.payor && (
            <Text variant="2xs" color={subtleColor}>
              Payor:{' '}
              {getFromConstObject(
                PAY_ELEMENT_PAYOR_LABELS,
                data.payor,
                friendlyKeyToLabel(data.payor)
              )}
            </Text>
          )}
        </Stack>
      </Stack>
    </components.Option>
  );
}

type Props = ComponentProps<typeof PaginatedSearchSelectField> & {
  filters: LegalEntityPayElementApi.Admin.LegalEntityPayElementsRequest['queryParams'];
};

export function LegalEntityPayElementSelectField({ filters = {}, ...selectFieldProps }: Props) {
  return (
    <PaginatedSearchSelectField
      query={{
        path: '/api/v1/rivendell/legal-entity-pay-elements',
        params: { queryParams: { ...filters } },
        options: {
          select: (res: LegalEntityPayElementApi.Admin.LegalEntityPayElementsResponse) =>
            getDataWithRenamedProperty(res, 'legalEntityPayElements'),
        },
      }}
      label="Pay item"
      placeholder="Select a pay item"
      loadingMessage={() => 'Loading pay items…'}
      getOptionLabel={({ name }: LegalEntityPayElementApi.LegalEntityPayElement) => name}
      getOptionValue={({ slug }: LegalEntityPayElementApi.LegalEntityPayElement) => slug}
      transformValue={({ slug }: LegalEntityPayElementApi.LegalEntityPayElement) => slug}
      description="Search by pay item name"
      data-testid="pay-elements-select-field"
      isControlled
      components={{
        Option: LepeOption,
      }}
      {...selectFieldProps}
    />
  );
}
