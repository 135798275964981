import type { BadgeType, PillTone } from '@remote-com/norma';

import type {
  Billable,
  BillableAmendmentStatus,
  BillableEventName,
  BillableParentResource,
  BillableStatus,
} from '@/src/api/config/employ/billables.types';
import type { Product } from '@/src/api/config/employ/shared.types';

export const billableStatuses: Record<string, BillableStatus> = {
  CREATED: 'created',
  SENT: 'sent',
  PROCESSED: 'processed',
  CANCELLED: 'cancelled',
};

export const billableAmendmentStatus: Record<
  Uppercase<BillableAmendmentStatus>,
  BillableAmendmentStatus
> = {
  CREATED: 'created',
  CANCELLED: 'cancelled',
};

export const adjustableBillableStatuses = new Set([billableStatuses.CREATED]);
export const correctableBillableStatuses = new Set([billableStatuses.PROCESSED]);

export const billableStatusLabels: Record<keyof typeof billableStatuses, string> = {
  [billableStatuses.CREATED]: 'Created',
  [billableStatuses.SENT]: 'In progress',
  [billableStatuses.PROCESSED]: 'Invoiced',
  [billableStatuses.CANCELLED]: 'Cancelled',
};

export const billableStatusBadges: Record<keyof typeof billableStatuses, BadgeType> = {
  [billableStatuses.CREATED]: 'info',
  [billableStatuses.SENT]: 'scheduled',
  [billableStatuses.PROCESSED]: 'active',
  [billableStatuses.CANCELLED]: 'archived',
};

type BillableProducts = Product['employmentType'];

export const billableProductLabels: Record<BillableProducts, string> = {
  eor: 'Employer of record',
  eor_team_plan: 'Employer of record',
  eor_annual_plan: 'Employer of record',
  contractor: 'Contractors',
  global_payroll: 'Global payroll',
  direct: 'Services',
  deposit_substitute: 'Services',
  peo: 'PEO MVP',
  recruit: 'Recruit package',
};

export const billableProductPillTones: Record<BillableProducts, PillTone> = {
  eor: 'warning',
  eor_team_plan: 'warning',
  eor_annual_plan: 'warning',
  contractor: 'pink',
  global_payroll: 'cyan',
  direct: 'purple',
  deposit_substitute: 'purple',
  peo: 'purple',
  recruit: 'purple',
};

export const billableEvents: Record<Uppercase<BillableEventName>, BillableEventName> = {
  STATUS_TRANSITION: 'status_transition',
  ADJUSTMENT: 'adjustment',
  CORRECTION: 'correction',
};

export const billableBusinessIdentifierType: Record<string, Billable['businessIdentifierType']> = {
  INCENTIVE: 'tiger.tiger.incentives.slug',
  SERVICE_COST: 'tiger.tiger.remote_paid_services.slug',
  OFF_PAYSLIP_COST: 'tiger.tiger.off_payslip_costs.slug',
  PAYROLL_OUTPUT: 'tiger.tiger.payroll_outputs.slug',
};

export const billablesTableColumnAccessorExclusionSet = new Set([
  'cancelledAt',
  'company.billingEmail',
  'company.companyCohorts',
  'company.hasTeamDeal',
  'company.logo',
  'company.name',
  'company.phoneNumber',
  'company.registrationNumber',
  'company.signupSource',
  'company.termsOfServiceAcceptedAt',
  'company.useWiseHybrid',
  'hireeContractIdentifier',
  'hireeContractIdentifierType',
  'hireeEmploymentIdentifier',
  'hireeEmploymentIdentifierType',
  'hireeUser.email',
  'hireeUserIdentifier',
  'hireeUserIdentifierType',
  'hireeUser.integrationUsers',
  'hireeUser.invitedBy',
  'hireeUser.name',
  'hireeUser.profilePicture',
  'hireeUser.role',
  'hireeUser.signupSource',
  'name',
  'product.insertedAt',
  'source',
  'status',
  'transactionDate',
]);

export const billableParentResources: Record<
  Uppercase<BillableParentResource>,
  BillableParentResource
> = {
  INCENTIVE: 'incentive',
  INTERNAL_SUPPLEMENTAL_SERVICE: 'internal_supplemental_service',
  OFF_PAYSLIP_COST: 'off_payslip_cost',
  PAYROLL_OUTPUT: 'payroll_output',
  PREFUNDING_ESTIMATE: 'prefunding_estimate',
  REVERSAL_PREFUNDING_ESTIMATE: 'reversal_prefunding_estimate',
  RISK_RESERVE: 'risk_reserve',
  SUPPLEMENTAL_SERVICE: 'supplemental_service',
};

export const billableAmendmentColumnSetId = {
  NAME: 'name',
  SOURCE: 'source',
  PRODUCT: 'product',
  EMPLOYEE_IDENTIFIER: 'employeeIdentifier',
  AMOUNT: 'amount',
  CORRECTION_AMOUNT: 'correctionAmount',
  NOTE: 'note',
  ADJUSTMENT_AMOUNT: 'adjustmentAmount',
} as const;

export type BillableAmendmentColumnSetType =
  (typeof billableAmendmentColumnSetId)[keyof typeof billableAmendmentColumnSetId];
