import type { AxiosError, InternalAxiosRequestConfig } from 'axios';

import { useImpersonationStorageModalStore } from './ImpersonationStorageModal';

export const NAME = 'onForbiddenFailInterceptor';

/**
 * Axios interceptor to handle requests failed because they are forbidden for the current user.
 */
export function onImpersonationFail() {
  return async (
    error: AxiosError & {
      config: InternalAxiosRequestConfig | undefined;
    }
  ) => {
    const { response } = error || {};

    if (
      (typeof window !== 'undefined' &&
        response?.status === 403 &&
        (response?.data as { code?: string })?.code === 'impersonation_write_forbidden') ||
      (response?.data as { code?: string })?.code === 'impersonation_forbidden_ui_warning'
    ) {
      useImpersonationStorageModalStore.setState({ isOpen: true });
      return Promise.reject(error);
    }
    return Promise.reject(error);
  };
}
