import type { UseMutationOptions } from 'react-query';
// eslint-disable-next-line remote/prefer-using-the-data-layer
import { useMutation } from 'react-query';

import { useApiService } from '../ApiServiceContext';
import type { DeletePath, DeleteRequest, DeleteResponse } from '../DELETE.types';
import type { DefaultApiError } from '../types/utilities';

const METHOD = 'DELETE';

export type UseDeleteOptions<
  KPath extends DeletePath,
  TError = DefaultApiError,
  TContext = unknown
> = Omit<
  UseMutationOptions<DeleteResponse<KPath>, TError, DeleteRequest<KPath>, TContext>,
  'mutationFn'
>;

export function useDelete<KPath extends DeletePath, TError = DefaultApiError, TContext = unknown>(
  path: KPath,
  options: UseDeleteOptions<KPath, TError, TContext> = {}
) {
  const { createService } = useApiService();
  const service = createService(path, METHOD);

  return useMutation((params) => service(params), options);
}
