/* eslint-disable import/no-extraneous-dependencies */
import type { TippyProps } from '@tippyjs/react';
import type { ReactNode } from 'react';
import { isForwardRef } from 'react-is';
import type { FlattenSimpleInterpolation, CSSObject } from 'styled-components';
import { isTabbable } from 'tabbable';

import { handleAccessibility } from './Tooltip.helpers';
import { Label, StyledTippy } from './Tooltip.styled';

export type BaseTooltipPosition =
  | 'top'
  | 'bottom'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end';

export type ExtendedTooltipPosition = BaseTooltipPosition | 'left' | 'right';

export type TooltipPosition<Type extends TooltipType> = Type extends 'caption'
  ? BaseTooltipPosition
  : ExtendedTooltipPosition;

export type TooltipType = 'default' | 'caption';

export interface TooltipProps<Type extends TooltipType = 'default'>
  extends Omit<TippyProps, 'children' | 'theme'> {
  /**
   * Tooltip content.
   *
   * We are overriding this from Tippy because we
   * want to force consumers of this component to always pass
   * children to it! It can never be undefined or null.
   */
  children: NonNullable<ReactNode>;
  /** Text that shows inside the tooltip */
  label?: ReactNode;
  /** Tooltip position relative to container */
  position?: TooltipPosition<Type>;
  /** Determines the theme of the tooltip */
  type?: TooltipType;
  /** Allows so set a custom delay before showing the tooltip */
  animationDelay?: number;
  /** Makes the display of the tooltip controllable */
  visible?: boolean;
  /** When we want to control if the Tooltip should appear or not */
  disabled?: boolean;
  /** Sets the maximum allowed width for the tooltip container */
  maxWidth?: number | string;
  /** Custom CSS-in-JS styles override */
  styles?: {
    wrapper?: FlattenSimpleInterpolation | CSSObject;
    label?: FlattenSimpleInterpolation | CSSObject;
  };
  /** Sets interactive prop in Tippy, if false it won't add aria-expanded attributes, check Tippy docs if you want to learn more about it https://atomiks.github.io/tippyjs/v5/accessibility/#interactivity */
  interactive?: boolean;
}

const arrowSvg =
  '<svg fill="none" height="7" viewBox="0 0 16 1" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m8.70472.799622 3.28298 3.848828c.19.22274.4681.35104.7608.35104h-9.60922c.29277 0 .57083-.1283.76082-.35104l3.28302-3.848829c.3992-.468046 1.1224-.468045 1.5216.000001z"/><path d="m9.00434.510817 3.34326 3.343213c.0937.09374.2209.14646.3536.14646h2.3003v1h-2.3003c-.3979 0-.7794-.15803-1.0607-.43934l-3.34327-3.34323c-.19524-.19525-.51186-.19527-.70709-.00002l-3.34324 3.34324c-.2813.28131-.66283.43935-1.06066.43935h-2.300315v-1h2.300315c.1326 0 .25977-.05268.35355-.14645l3.34322-3.343223c.58576-.5857849 1.53557-.5857826 2.12133 0z"/></svg>';

export const Tooltip = ({
  label,
  children,
  position = 'top',
  type = 'default',
  styles = { wrapper: [], label: [] },
  animationDelay = 500,
  maxWidth = 250,
  hideOnClick = true,
  trigger = 'click focus mouseenter',
  interactive = true,
  aria,
  // Check @tippyjs/react and Tippy.js props (https://github.com/atomiks/tippyjs-react / https://atomiks.github.io/tippyjs/v6/all-props/) for additional configuration
  ...props
}: TooltipProps) => {
  const handleOnCreate: TippyProps['onCreate'] = (instance) => {
    const childElement = instance.reference;

    // All SVG elements wrapped with Tooltips should be tab focusable
    if (childElement?.tagName === 'svg') {
      const svgChildElement = childElement as SVGElement;

      svgChildElement.tabIndex = 0;
    }
  };

  // @tippyjs/react needs to bind to a single element.
  // This might cause a strange positioning in case the span doesn't take its children's width/height.
  // Children that are Components fail to render the tooltip if they are not wrapped by React.forwardRef
  // See: https://github.com/atomiks/tippyjs-react#component-children
  const isUnsupportedChildren =
    typeof children === 'string' || Array.isArray(children) || !isForwardRef(children);

  const handleOnMount: TippyProps['onMount'] = (instance) => {
    const isTabbableChild = instance.reference && isTabbable(instance.reference);

    if (process.env.NODE_ENV === 'development') {
      if (!isForwardRef(children)) {
        console.error(
          'Tooltip direct child node should forward refs. Read more here https://github.com/atomiks/tippyjs-react#component-children.'
        );
      } else if (!isTabbableChild || isUnsupportedChildren) {
        console.error(
          'Tooltip direct child node must be tab focusable, so tooltip content becomes accessible by keyboard. You may use <Button variant="raw"> to do so.\n\nConfused why the element is not tab focusable? Read tabbable’s docs: https://github.com/focus-trap/tabbable/blob/master/README.md?plain=1#L11-L35.\n\nIf the element is actually tab focusable across different browsers, ignore this message.'
        );
      }
    }
  };

  if (!label) {
    /**
     * We could wrap this in a Fragment to avoid "is not a valid JSX element" errors
     *
     * However to avoid unforeseen consequences of such a fix, let's just cast as JSX.Element.
     */
    return children as JSX.Element;
  }
  // https://github.com/atomiks/tippyjs-react#visible-boolean-controlled-mode
  const uncontrolledModeProps =
    props.visible === undefined
      ? {
          hideOnClick,
          trigger,
        }
      : {};

  /**
   * Default to aria-describedby for screen readers to announce tooltip content
   *
   * Without this, interactive tooltips would be silent to screen readers since
   * Tippy.js doesn't add any ARIA attributes by default in interactive mode.
   * Non-interactive tooltips already use aria-describedby.
   */
  const adjustedAria: TippyProps['aria'] = { content: 'describedby', ...aria };

  return (
    <StyledTippy
      $type={type}
      $styles={styles.wrapper || {}}
      placement={position}
      delay={[animationDelay, 0]}
      duration={[300, 100]}
      content={<Label $styles={styles.label || {}}>{label}</Label>}
      arrow={arrowSvg}
      // Offset set to 10 because beak size takes 4px, keeps a 6px distance
      offset={[0, 10]}
      appendTo={typeof window !== 'undefined' ? document.body : undefined}
      onTrigger={handleAccessibility}
      onCreate={handleOnCreate}
      onMount={handleOnMount}
      zIndex={1000000}
      maxWidth={maxWidth}
      interactive={interactive}
      aria={adjustedAria}
      {...uncontrolledModeProps}
      {...props}
    >
      {isUnsupportedChildren ? <span>{children}</span> : children}
    </StyledTippy>
  );
};
