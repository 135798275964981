import { resetTrackingUser } from '@remote-com/analytics';
import { useImpersonationStorageModalStore } from '@remote-com/auth';
import { useDelete } from '@remote-com/data-layer';
import { Avatar, Stack, Text } from '@remote-com/norma';
import { useState } from 'react';

import { Button } from '@/src/components/Button';
import { ModalActionable } from '@/src/components/Modal/ModalActionable';
import { useUserContext } from '@/src/components/UserProvider';
import { ActionRestrictedModal } from '@/src/domains/account/ActionRestrictedModal';
import { BROADCAST_MESSAGE } from '@/src/domains/broadcast/constants';
import { postMessage } from '@/src/domains/broadcast/postMessage';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import { useDebouncedValue } from '@/src/hooks/useDebouncedValue';
import { useModalContext } from '@/src/hooks/useModalContext';
import type { $TSFixMe } from '@/types';

function ConfirmModal({ hideModal, name }: { hideModal: () => void; name: string }) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { mutate, isLoading: isLoadingMutation } = useDelete('/api/v1/impersonation', {
    onSuccess: () => {
      resetTrackingUser();
      postMessage(BROADCAST_MESSAGE.reload);
    },
    onError: (err: $TSFixMe) => {
      setErrorMessage(err.response?.data?.error || 'Failed to stop impersonation');
    },
  });

  const isLoading = useDebouncedValue(isLoadingMutation, 200);

  return (
    <ModalActionable
      open
      title={`Stop mimicking ${name}`}
      description="If you made changes to the user’s account, none of them were saved. To actually make changes to their account, please use your own admin account. This session’s activity will be kept for our internal records."
      confirmLabel="End session"
      isLoading={isLoading}
      onConfirm={() => mutate({})}
      onCancel={hideModal}
      errorMessage={errorMessage}
    />
  );
}

export function ImpersonatedBanner() {
  const { user } = useUserContext();
  const { showModal } = useModalContext();

  const isModalOpen = useImpersonationStorageModalStore((st) => st.isOpen);
  const isFlagEnabled = useIsFeatureFlagEnabled('login_as_customer');

  if (!isFlagEnabled || !user?.realUser) return null;

  function handleModalAction() {
    useImpersonationStorageModalStore.setState({ isOpen: false });
  }

  return (
    <>
      <Stack alignItems="center">
        <Stack
          position="fixed"
          top={3}
          zIndex={100}
          direction="row"
          justifyContent="center"
          alignItems="center"
          backgroundColor="blank"
          p={2}
          gap={5}
          borderRadius="89px"
          border="0.5px solid"
          borderColor="grey.300"
          boxShadow="0px 0px 24px 0px #0F141929"
        >
          <Avatar src={user?.profilePicture} size="xxs" fallback={user.email} />
          <Text color="grey.600" variant="sm" data-testid="impersonated-banner-text">
            Mimicking <strong>{user?.name}</strong>
          </Text>
          <Button
            ml={3}
            onClick={() => {
              showModal({
                component: ConfirmModal,
                modalProps: {
                  name: user.name,
                },
              });
            }}
            size="xs"
            tone="destructive"
          >
            End session
          </Button>
        </Stack>
      </Stack>
      {isModalOpen && <ActionRestrictedModal handleModalAction={handleModalAction} />}
    </>
  );
}
