import type { BadgeType, PillTone } from '@remote-com/norma';
import type { ValueOf } from 'type-fest';

import type { InvoiceTypeCodes } from '@/src/api/config/employ/billingDocuments.types';
import type {
  CorrectionReason,
  InvoiceReportValidatorResult,
  PrimaryCorrectionReason,
  SecondaryCorrectionReason,
} from '@/src/api/config/employ/invoiceReport.types';
import { getPrimaryCorrectionReasonOptions } from '@/src/domains/invoices/helpers';
import { camelCaseKeepDots } from '@/src/helpers/general';
import type { ScreamingSnakeCaseMapping } from '@/src/types/case';

export const invoiceStatus = {
  DELETED: 'deleted',
  ISSUED: 'issued',
  APPROVED: 'approved',
  PAID_IN: 'paid_in',
  PAID_OUT: 'paid_out',
  REJECTED: 'rejected',
  // Virtual status when Contractor of Record invoice is declined from Coupa
  REJECTED_BY_REMOTE: 'rejected_by_remote',
  ENQUEUED: 'enqueued',
  PROCESSING: 'processing',
  PENDING_PAYMENT: 'pending_payment',
  IN_REVIEW: 'in_review',
  // CONTRACTOR_PAYMENT_INVOICE statuses are virtual statuses used for the Contractor of Record feature
  CONTRACTOR_PAYMENT_INVOICE_GENERATING: 'contractor_payment_invoice_generating',
  CONTRACTOR_PAYMENT_INVOICE_DUE: 'contractor_payment_invoice_due',
  CONTRACTOR_PAYMENT_INVOICE_OVERDUE: 'contractor_payment_invoice_overdue',
  CONTRACTOR_PAYMENT_INVOICE_FAILED: 'contractor_payment_invoice_failed',
  PAY_IN_FAILED: 'pay_in_failed',
  PAY_OUT_FAILED: 'pay_out_failed',
  EXTERNALLY_PAID: 'externally_paid',
  MANUAL_PAYOUT: 'manual_payout',
  FUNDS_RETURNED: 'funds_returned',
  PAY_OUT_SCHEDULED: 'pay_out_scheduled',
} as const;

export const invoiceStatuses = Object.values(invoiceStatus);

export const INVOICE_NON_FILTERABLE_STATUSES: Set<Partial<InvoiceStatus>> = new Set([
  // We're not showing deleted invoices to employers
  invoiceStatus.DELETED,
  // Virtual contractor invoice statuses used for the Contractor of Record feature.
  // These statuses are not stored in the database but are calculated at runtime. Currently, filtering by these statuses is not supported.
  invoiceStatus.IN_REVIEW,
  invoiceStatus.REJECTED_BY_REMOTE,
  invoiceStatus.CONTRACTOR_PAYMENT_INVOICE_GENERATING,
  invoiceStatus.CONTRACTOR_PAYMENT_INVOICE_DUE,
  invoiceStatus.CONTRACTOR_PAYMENT_INVOICE_OVERDUE,
  invoiceStatus.CONTRACTOR_PAYMENT_INVOICE_FAILED,
]);

export const CM_PROTECT_PAYABLE_INVOICE_STATUSES: Set<Partial<InvoiceStatus>> = new Set([
  invoiceStatus.CONTRACTOR_PAYMENT_INVOICE_DUE,
  invoiceStatus.CONTRACTOR_PAYMENT_INVOICE_OVERDUE,
  invoiceStatus.CONTRACTOR_PAYMENT_INVOICE_FAILED,
]);

export type InvoiceStatus = ValueOf<typeof invoiceStatus>;

export const invoiceGeneratedBy = {
  CONTRACTOR: 'contractor',
  AUTOMATION: 'automation',
} as const;

export const invoiceGeneratedByLabels = {
  [invoiceGeneratedBy.CONTRACTOR]: 'Uploaded',
  [invoiceGeneratedBy.AUTOMATION]: 'Generated',
};

/*
  TODO: Is there a way to merge employeeInvoiceDataSources with employeeInvoiceTypes?
*/

export const employeeInvoiceDataSources: Record<Uppercase<InvoiceTypeCodes>, InvoiceTypeCodes> = {
  // Legacy sources
  ESTIMATES: 'estimates',
  ACTUALS: 'actuals',
  CONTRACTOR_FEES: 'contractor_fees',
  MANUAL_EOR: 'manual_eor',
  EOR_ONBOARDING_RESERVE: 'eor_onboarding_reserve',
  REMOTE_PAID_SERVICES: 'remote_paid_services',
  // Zuora sources
  CONTRACTORS_SUBSCRIPTION: 'contractors_subscription',
  CONTRACTOR_PROTECT_RISK_RESERVE: 'contractor_protect_risk_reserve',
  CONTRACTOR_PROTECT_MANAGEMENT_FEES: 'contractor_protect_management_fees',
  CONTRACTOR_PAYMENTS: 'contractor_payments',
  EOR_PREFUNDING: 'eor_prefunding',
  EOR_PREPAID: 'eor_prepaid',
  EOR_RECONCILIATION: 'eor_reconciliation',
  EOR_RISK_RESERVE: 'eor_risk_reserve',
  EOR_SUPPLEMENTAL_SERVICES: 'eor_supplemental_services',
  RECRUIT_SUBSCRIPTION: 'recruit_subscription',
  HRIS_SUBSCRIPTIONS: 'hris_subscriptions',
  // Common name for both
  GLOBAL_PAYROLL: 'global_payroll',
} as const;

export type EmployeeInvoiceDataSource = ValueOf<typeof employeeInvoiceDataSources>;

export const employeeInvoiceTypes = {
  REMOTE_INVOICE: 'remote_invoice',
  CREDIT_NOTE: 'credit_note',
  ESTIMATES: 'estimates',
  ACTUALS: 'actuals',
  CONTRACTOR_FEES: 'contractor_fees',
  MANUAL_EOR: 'manual_eor',
  ONBOARDING_RESERVE: 'eor_onboarding_reserve',
  GLOBAL_PAYROLL: 'global_payroll',
  REMOTE_PAID_SERVICES: 'remote_paid_services',
} as const;

export const employeeInvoiceDisplayTypes = {
  REMOTE_INVOICE: 'remote_invoice',
  CREDIT_NOTE: 'credit_note',
  REMOTE_INVOICE_ESTIMATES: 'remote_invoice_estimates',
  REMOTE_RECONCILIATION_INVOICE: 'remote_reconciliation_invoice',
  REMOTE_RECONCILIATION_CREDIT: 'remote_reconciliation_credit',
  CONTRACTOR_SUBSCRIPTION_BILL: 'contractor_subscription_bill',
  CONTRACTOR_PROTECT_RISK_RESERVE: 'contractor_protect_risk_reserve',
  CONTRACTOR_PROTECT_MANAGEMENT_FEES: 'contractor_protect_management_fees',
  CONTRACTOR_PAYMENTS: 'contractor_payments',
  MANUAL_EOR: 'manual_eor',
  ONBOARDING_RESERVE_INVOICE: 'eor_onboarding_reserve',
  REMOTE_PAID_SERVICES: 'remote_paid_services',
  REMOTE_PAID_SERVICES_CREDIT_NOTE: 'remote_paid_services_credit_note',
  GLOBAL_PAYROLL: 'global_payroll',
  FULL_INVOICE: 'full_invoice',
  FULL_CREDIT_NOTE: 'full_credit_note',
  PARTIAL_CREDIT_NOTE: 'partial_credit_note',
  PARTIAL_INVOICE: 'partial_remote_invoice',
  // Zuora only types
  PREPAID_INVOICE: 'prepaid_invoice',
  RECRUIT_SUBSCRIPTION: 'recruit_subscription',
  HRIS_SUBSCRIPTIONS: 'hris_subscriptions',
};

export const employeeInvoiceDisplayTypeLabels = {
  [employeeInvoiceDisplayTypes.REMOTE_INVOICE]: 'Invoice',
  [employeeInvoiceDisplayTypes.CREDIT_NOTE]: 'Credit note',
  [employeeInvoiceDisplayTypes.REMOTE_INVOICE_ESTIMATES]: 'Payroll pre-funding invoice',
  [employeeInvoiceDisplayTypes.REMOTE_RECONCILIATION_INVOICE]: 'Reconciliation invoice',
  [employeeInvoiceDisplayTypes.REMOTE_RECONCILIATION_CREDIT]: 'Reconciliation credit',
  [employeeInvoiceDisplayTypes.CONTRACTOR_SUBSCRIPTION_BILL]: 'Contractor subscription bill',
  [employeeInvoiceDisplayTypes.CONTRACTOR_PROTECT_RISK_RESERVE]: 'COR reserve invoice',
  [employeeInvoiceDisplayTypes.CONTRACTOR_PROTECT_MANAGEMENT_FEES]: 'COR service invoice',
  [employeeInvoiceDisplayTypes.CONTRACTOR_PAYMENTS]: 'COR payment invoice',
  [employeeInvoiceDisplayTypes.MANUAL_EOR]: 'One-off EOR',
  [employeeInvoiceDisplayTypes.ONBOARDING_RESERVE_INVOICE]: 'Reserve invoice',
  [employeeInvoiceDisplayTypes.REMOTE_PAID_SERVICES]: 'Supplemental service invoice',
  [employeeInvoiceDisplayTypes.REMOTE_PAID_SERVICES_CREDIT_NOTE]:
    'Supplemental service credit note',
  [employeeInvoiceDisplayTypes.GLOBAL_PAYROLL]: 'Global payroll invoice',
  [employeeInvoiceDisplayTypes.FULL_INVOICE]: 'Debit note',
  [employeeInvoiceDisplayTypes.FULL_CREDIT_NOTE]: 'Credit note',
  [employeeInvoiceDisplayTypes.PARTIAL_CREDIT_NOTE]: 'Credit note',
  [employeeInvoiceDisplayTypes.PARTIAL_INVOICE]: 'Debit note',
  // Zuora only labels
  [employeeInvoiceDisplayTypes.PREPAID_INVOICE]: 'EOR annual plan invoice',
  [employeeInvoiceDisplayTypes.RECRUIT_SUBSCRIPTION]: 'Recruit subscription invoice',
  [employeeInvoiceDisplayTypes.HRIS_SUBSCRIPTIONS]: 'Remote subscription invoice',
};

export const employeeInvoiceStatuses = {
  DRAFT: 'draft',
  DRAFT_READY: 'draft_ready',
  IN_REVIEW: 'in_review',
  SCHEDULED: 'scheduled',
  BLOCKED: 'blocked',
  CLOSED: 'closed',
  FINAL: 'final',
} as const;

export type EmployeeInvoiceStatus = ValueOf<typeof employeeInvoiceStatuses>;

export const employeeInvoiceStatusLabels: Record<EmployeeInvoiceStatus, string> = {
  [employeeInvoiceStatuses.DRAFT]: 'Draft',
  [employeeInvoiceStatuses.DRAFT_READY]: 'Ready',
  [employeeInvoiceStatuses.IN_REVIEW]: 'In Review',
  [employeeInvoiceStatuses.SCHEDULED]: 'Scheduled',
  [employeeInvoiceStatuses.BLOCKED]: 'Blocked',
  [employeeInvoiceStatuses.CLOSED]: 'Closed',
  [employeeInvoiceStatuses.FINAL]: 'Final',
};

export const employeeInvoiceStatusBadges: Record<EmployeeInvoiceStatus, BadgeType> = {
  [employeeInvoiceStatuses.DRAFT]: 'inactive',
  [employeeInvoiceStatuses.DRAFT_READY]: 'info',
  [employeeInvoiceStatuses.IN_REVIEW]: 'pending',
  [employeeInvoiceStatuses.SCHEDULED]: 'scheduled',
  [employeeInvoiceStatuses.BLOCKED]: 'error',
  [employeeInvoiceStatuses.CLOSED]: 'closed',
  [employeeInvoiceStatuses.FINAL]: 'active',
};

export const invoicesTabs = {
  INVOICES_TABLE_TAB: 'invoices-table',
  INVOICES_PAYROLL_STATUS_TAB: 'invoices-payroll-status',
  INVOICES_SETTINGS: 'invoices-settings',
};

export const invoiceTabs = {
  INVOICE_OVERVIEW: 'invoice-overview',
  INVOICE_REPORT: 'invoice-report',
  INVOICE_EVENTS: 'invoice-events',
};

export const invoiceReportViewTypes = {
  HIRE_BREAKDOWN: 'hire-breakdown',
  COUNTRY_BREAKDOWN: 'country-breakdown',
};

export const invoiceDisplayTypes = {
  ...employeeInvoiceDisplayTypes,
  CONTRACTOR_INVOICE: 'contractor_invoice',
};

export const invoiceDisplayTypesLabel = {
  ...employeeInvoiceDisplayTypeLabels,
  [invoiceDisplayTypes.CONTRACTOR_INVOICE]: 'Transaction receipt',
};

export const invoicesOrderedForAdminBillingView: Array<InvoiceStatus> = [
  // actionable invoices prioritized for PayOps
  invoiceStatus.MANUAL_PAYOUT,
  invoiceStatus.PAY_OUT_FAILED,
  invoiceStatus.PAY_IN_FAILED,
  // others
  invoiceStatus.ISSUED,
  invoiceStatus.PENDING_PAYMENT,
  invoiceStatus.ENQUEUED,
  invoiceStatus.PROCESSING,
  invoiceStatus.PAID_IN,
  invoiceStatus.PAID_OUT,
  invoiceStatus.EXTERNALLY_PAID,
  invoiceStatus.REJECTED,
  invoiceStatus.DELETED,
];

export const invoiceReportTypeFilterValues = {
  REMOTE_INVOICE: 'invoice',
  CREDIT_NOTE: 'credit_note',
  ESTIMATES: 'pre_funding_invoice',
  ACTUALS: 'reconciliation_invoice',
  RECONCILIATION_CREDIT: 'reconciliation_credit_note',
  CONTRACTOR_FEES: 'contractor_subscription_invoice',
  CONTRACTOR_PAYMENTS_INVOICE: 'contractor_payments_invoice',
  CONTRACTOR_PROTECT_MANAGEMENT_FEES: 'contractor_protect_management_fees',
  MANUAL_EOR: 'one_off_invoice',
  ONBOARDING_RESERVE: 'reserve_invoice',
  GLOBAL_PAYROLL: 'global_payroll_invoice',
  REMOTE_PAID_SERVICES: 'service_invoice',
  EOR_PREPAID: 'eor_prepaid',
  RECRUIT_SUBSCRIPTION: 'recruit_subscription',
  CORRECTION_INVOICE: 'correction_invoice',
  CORRECTION_CREDIT_NOTE: 'correction_credit_note',
  HRIS_SUBSCRIPTIONS: 'hris_subscriptions',
};

export type InvoiceReportTypeFilterValue = ValueOf<typeof invoiceReportTypeFilterValues>;

export const invoiceReportFilterTypeLabels: Record<InvoiceReportTypeFilterValue, string> = {
  [invoiceReportTypeFilterValues.REMOTE_INVOICE]: 'Invoice',
  [invoiceReportTypeFilterValues.CREDIT_NOTE]: 'Credit note',
  [invoiceReportTypeFilterValues.ESTIMATES]: 'Payroll pre-funding invoice',
  [invoiceReportTypeFilterValues.ACTUALS]: 'Reconciliation invoice',
  [invoiceReportTypeFilterValues.RECONCILIATION_CREDIT]: 'Reconciliation credit',
  [invoiceReportTypeFilterValues.CONTRACTOR_FEES]: 'Contractor subscription bill',
  [invoiceReportTypeFilterValues.CONTRACTOR_PAYMENTS_INVOICE]: 'COR contractor payment invoice',
  [invoiceReportTypeFilterValues.CONTRACTOR_PROTECT_MANAGEMENT_FEES]: 'COR service invoice',
  [invoiceReportTypeFilterValues.MANUAL_EOR]: 'One-off EOR',
  [invoiceReportTypeFilterValues.ONBOARDING_RESERVE]: 'Reserve invoice',
  [invoiceReportTypeFilterValues.GLOBAL_PAYROLL]: 'Global payroll',
  [invoiceReportTypeFilterValues.REMOTE_PAID_SERVICES]: 'Supplemental service invoice',
  [invoiceReportTypeFilterValues.EOR_PREPAID]: 'EOR annual plan',
  [invoiceReportTypeFilterValues.CORRECTION_INVOICE]: 'Correction invoice',
  [invoiceReportTypeFilterValues.CORRECTION_CREDIT_NOTE]: 'Correction credit note',
  [invoiceReportTypeFilterValues.RECRUIT_SUBSCRIPTION]: 'Recruit subscription',
  [invoiceReportTypeFilterValues.HRIS_SUBSCRIPTIONS]: 'Remote subscription',
};

export const invoiceReportFilterTypeLabelsForEmployers: Record<
  Exclude<InvoiceReportTypeFilterValue, 'correction_invoice'>,
  string
> = {
  [invoiceReportTypeFilterValues.REMOTE_INVOICE]: 'All invoices',
  [invoiceReportTypeFilterValues.CREDIT_NOTE]: 'All credit notes',
  [invoiceReportTypeFilterValues.ESTIMATES]: 'Payroll pre-funding',
  [invoiceReportTypeFilterValues.ACTUALS]: 'Reconciliation',
  [invoiceReportTypeFilterValues.RECONCILIATION_CREDIT]: 'Reconciliation credit',
  [invoiceReportTypeFilterValues.CONTRACTOR_FEES]: 'Contractor subscription',
  [invoiceReportTypeFilterValues.CONTRACTOR_PAYMENTS_INVOICE]: 'COR contractor payment invoice',
  [invoiceReportTypeFilterValues.CONTRACTOR_PROTECT_MANAGEMENT_FEES]: 'COR service invoice',
  [invoiceReportTypeFilterValues.MANUAL_EOR]: 'One-off',
  [invoiceReportTypeFilterValues.ONBOARDING_RESERVE]: 'Reserve',
  [invoiceReportTypeFilterValues.GLOBAL_PAYROLL]: 'Global payroll',
  [invoiceReportTypeFilterValues.REMOTE_PAID_SERVICES]: 'Supplemental services',
  [invoiceReportTypeFilterValues.CORRECTION_CREDIT_NOTE]: 'Credit notes',
  [invoiceReportTypeFilterValues.EOR_PREPAID]: 'EOR annual plan',
  [invoiceReportTypeFilterValues.RECRUIT_SUBSCRIPTION]: 'Recruit subscription',
  [invoiceReportTypeFilterValues.HRIS_SUBSCRIPTIONS]: 'Remote subscription',
};

export const invoiceReportTypeByGroupForEmployers = [
  {
    label: '',
    options: [
      invoiceReportTypeFilterValues.REMOTE_INVOICE,
      invoiceReportTypeFilterValues.CREDIT_NOTE,
    ],
  },
  {
    label: 'Invoices',
    options: [
      invoiceReportTypeFilterValues.ESTIMATES,
      invoiceReportTypeFilterValues.ACTUALS,
      invoiceReportTypeFilterValues.MANUAL_EOR,
      invoiceReportTypeFilterValues.REMOTE_PAID_SERVICES,
      invoiceReportTypeFilterValues.ONBOARDING_RESERVE,
      invoiceReportTypeFilterValues.CONTRACTOR_FEES,
      invoiceReportTypeFilterValues.CONTRACTOR_PAYMENTS_INVOICE,
      invoiceReportTypeFilterValues.CONTRACTOR_PROTECT_MANAGEMENT_FEES,
      invoiceReportTypeFilterValues.GLOBAL_PAYROLL,
      invoiceReportTypeFilterValues.EOR_PREPAID,
      invoiceReportTypeFilterValues.RECRUIT_SUBSCRIPTION,
    ],
  },
  {
    label: 'Credit notes',
    options: [
      invoiceReportTypeFilterValues.CORRECTION_CREDIT_NOTE,
      invoiceReportTypeFilterValues.RECONCILIATION_CREDIT,
    ],
  },
] as const;

export const INVOICES_LIST_EXPORT_EXCLUSION_SET = new Set([
  'outstandingRefund',
  'outstandingRefund.currency.name',
  'outstandingRefund.currency.slug',
  'outstandingRefund.currency.symbol',
  'outstandingPayment',
  'outstandingPayment.currency.name',
  'outstandingPayment.currency.slug',
  'outstandingPayment.currency.symbol',
  'remoteInvoice.invoiceReport.dataSource',
  'remoteInvoice.invoiceReport.insertedAt',
  'remoteInvoice.invoiceReport.invoiceCurrency',
  'remoteInvoice.invoiceReport.invoiceCurrency.code',
  'remoteInvoice.invoiceReport.invoiceCurrency.name',
  'remoteInvoice.invoiceReport.invoiceCurrency.slug',
  'remoteInvoice.invoiceReport.invoiceCurrency.symbol',
  'remoteInvoice.invoiceReport.invoicePeriod',
  'remoteInvoice.invoiceReport.isEdited',
  'remoteInvoice.invoiceReport.isManualImport',
  'remoteInvoice.invoiceReport.isRebill',
  'remoteInvoice.invoiceReport.preTaxAmount',
  'remoteInvoice.invoiceReport.slug',
  'remoteInvoice.invoiceReport.status',
  'remoteInvoice.invoiceReport.taxTotal',
  'remoteInvoice.invoiceReport.totalInvoiceAmount',
  'remoteInvoice.invoiceReport.totals',
  'remoteInvoice.invoiceReport.totals.adjustments',
  'remoteInvoice.invoiceReport.totals.baseSalary',
  'remoteInvoice.invoiceReport.totals.benefits',
  'remoteInvoice.invoiceReport.totals.companyOtherFees',
  'remoteInvoice.invoiceReport.totals.deposits',
  'remoteInvoice.invoiceReport.totals.employerContribution',
  'remoteInvoice.invoiceReport.totals.employmentOtherFees',
  'remoteInvoice.invoiceReport.totals.expenses',
  'remoteInvoice.invoiceReport.totals.incentives',
  'remoteInvoice.invoiceReport.totals.managementFees',
  'remoteInvoice.invoiceReport.totals.contractorPlusProratedFees',
  'remoteInvoice.invoiceReport.totals.contractorPlusInAdvanceFees',
  'remoteInvoice.invoiceReport.totals.offPayslipBenefits',
  'remoteInvoice.invoiceReport.totals.onPayslipBenefits',
  'remoteInvoice.invoiceReport.updatedAt',
  'remoteInvoice.formattedNumber',
  'remoteInvoice.status',
  'remoteEntity.address.address',
  'remoteEntity.address.addressLine2',
  'remoteEntity.address.city',
  'remoteEntity.address.country.code',
  'remoteEntity.address.country.features',
  'remoteEntity.address.country.name',
  'remoteEntity.address.country.slug',
  'remoteEntity.address.localDetails',
  'remoteEntity.address.postalCode',
  'remoteEntity.address.state',
  'remoteEntity.company',
  'remoteEntity.kvk',
  'remoteEntity.allowedContractTypes',
  'finalizedAt',
  'formattedNumber',
  'invoiceGroup',
  'type',
  'company.billingEmail',
  'company.logo',
  'company.name',
  'company.phoneNumber',
  'company.registrationNumber',
  'company.termsOfServiceAcceptedAt',
  'invoiceGroup.description',
  'invoiceGroup.name',
  'invoiceGroup.slug',
]);

export const BILLING_DASHBOARD_INVOICES_LIST_EXCLUSION_SET = new Set(
  [
    'company',
    'company.billingEmail',
    'company.hasTeamDeal',
    'company.logo',
    'company.name',
    'company.phoneNumber',
    'company.registrationNumber',
    'company.signupSource',
    'company.slug',
    'company.status',
    'company.termsOfServiceAcceptedAt',
    'company.useWiseHybrid',
    'company.vat',
    'company.company_cohorts',
    'creditNote.formattedNumber',
    'creditNote.insertedAt',
    'creditNote.files',
    'creditNote.outstandingRefund.amount',
    'creditNote.outstandingRefund.company',
    'creditNote.outstandingRefund.createdBy.integrationUsers',
    'creditNote.outstandingRefund.createdBy.invitedBy',
    'creditNote.outstandingRefund.creditNote',
    'creditNote.outstandingRefund.creditedThroughOutstandingPayment',
    'creditNote.outstandingRefund.currency',
    'creditNote.outstandingRefund.description',
    'creditNote.outstandingRefund.insertedAt',
    'creditNote.outstandingRefund.reference',
    'creditNote.outstandingRefund.refundDate',
    'creditNote.outstandingRefund.remoteEntity',
    'creditNote.outstandingRefund.slug',
    'creditNote.outstandingRefund.status',
    'creditNote.outstandingRefund.updatedAt',
    'creditNote.slug',
    'dataSource',
    'description',
    'employeeName',
    'hasCorrectedInvoiceReport',
    'insertedAt',
    'invoiceCurrency.code',
    'invoiceCurrency.name',
    'invoiceCurrency.slug',
    'invoiceCurrency.symbol',
    'invoicePeriod',
    'invoiceReportTaxGroups',
    'invoice_group.name',
    'invoice_group.slug',
    'invoice_group.description',
    'isEdited',
    'isManualImport',
    'isPartialCorrection',
    'isRebill',
    'is_correction',
    'remoteInvoice.actualsRemoteInvoiceSlug',
    'remoteInvoice.company.address',
    'remoteInvoice.creditNoteSlug',
    'remoteInvoice.actualsRemoteInvoiceSlug',
    'remoteInvoice.earliestInvoiceDate',
    'remoteInvoice.estimatesRemoteInvoiceSlug',
    'remoteInvoice.files',
    'remoteInvoice.finalized_at',
    'remoteInvoice.formatted_number',
    'remoteInvoice.inserted_at',
    'remoteInvoice.invoice_report',
    'remoteInvoice.rebill_original_remote_invoice_slug',
    'remoteInvoice.rebill_slug',
    'remoteInvoice.slug',
    'remoteInvoice.status',
    'remoteInvoice.remoteEntity',
    'remoteInvoice.suggestedDueDate',
    'remoteInvoice.updatedAt',
    'remoteInvoice.rebill_original_remote_invoice_slug',
    'remoteInvoice.outstandingPayment.status',
    'remoteInvoice.outstandingPayment.type',
    'remoteInvoice.outstandingPayment.description',
    'remoteInvoice.outstandingPayment.currency',
    'remoteInvoice.outstandingPayment.inserted_at',
    'remoteInvoice.outstandingPayment.updated_at',
    'remoteInvoice.outstandingPayment.scheduled_at',
    'remoteInvoice.outstandingPayment.slug',
    'remoteInvoice.outstandingPayment.company',
    'remoteInvoice.outstandingPayment.installments',
    'remoteInvoice.outstandingPayment.contractor_invoices',
    'remoteInvoice.outstandingPayment.latest_pay_in_attempt',
    'remoteInvoice.outstandingPayment.fee_amount',
    'remoteInvoice.outstandingPayment.due_date',
    'remoteInvoice.outstandingPayment.payment_date',
    'remoteInvoice.outstandingPayment.remote_entity',
    'remoteInvoice.outstandingPayment.stripe_charge',
    'remoteInvoice.outstandingPayment.remote_invoices',
    'remoteInvoice.outstandingPayment.contractor_pay_out_method',
    'remoteInvoice.outstandingPayment.transaction_receipts',
    'remoteInvoice.outstandingPayment.remote_bank_details',
    'remoteInvoice.outstandingPayment.created_by',
    'remoteInvoice.outstandingPayment.wire_charge',
    'remoteInvoice.outstandingPayment.credit_note',
    'remoteInvoice.outstandingPayment.credit_note_formatted_number',
    'remoteInvoice.outstandingPayment.payable',
    'remoteInvoice.outstandingPayment.pay_out_details',
    'remoteInvoice.outstandingPayment.stripe_charge.stripe_dispute',
    'remoteInvoice.outstandingPayment.wire_charge.inserted_at',
    'remoteInvoice.outstandingPayment.wire_charge.type',
    'remoteInvoice.outstandingRefund',
    'outstandingRefund',
    'outstandingRefund.currency.name',
    'outstandingRefund.currency.slug',
    'outstandingRefund.currency.symbol',
    'outstandingPayment',
    'outstandingPayment.currency.name',
    'outstandingPayment.currency.slug',
    'outstandingPayment.currency.symbol',
    'slug',
    'status',
    'totalInvoiceAmount',
    'tax_summary',
    'updatedAt',
    'totals.adjustments',
    'totals.benefits',
    'totals.deposits',

    // Billing documents fields
    'amountApplied.amount',
    'amountApplied.currency',
    'amountApplied.currency.name',
    'amountApplied.currency.slug',
    'amountApplied.currency.symbol',
    'amountApplied.currency.code',
    'amountRefunded.amount',
    'amountRefunded.currency',
    'amountRefunded.currency.name',
    'amountRefunded.currency.slug',
    'amountRefunded.currency.symbol',
    'amountRefunded.currency.code',
    'amountPaid.amount',
    'amountPaid.currency',
    'amountPaid.currency.name',
    'amountPaid.currency.slug',
    'amountPaid.currency.symbol',
    'amountPaid.currency.code',
    'amount.amount',
    'amount.currency',
    'amount.currency.name',
    'amount.currency.slug',
    'amount.currency.symbol',
    'amount.currency.code',
    'amountDue.amount',
    'amountDue.currency',
    'amountDue.currency.name',
    'amountDue.currency.slug',
    'amountDue.currency.symbol',
    'amountDue.currency.code',
    'amountCredited',
    'amountCredited.amount',
    'amountCredited.currency',
    'amountCredited.currency.name',
    'amountCredited.currency.slug',
    'amountCredited.currency.symbol',
    'amountCredited.currency.code',
    'billableItems',
    'dueDate',
    'insertedAt',
    'invoiceCode',
    'invoiceNumber',
    'invoicePeriod',
    'issueDate',
    'issuedBy',
    'legalEntity',
    'legalEntity.address',
    'legalEntity.address.address',
    'legalEntity.address.addressLine_2',
    'legalEntity.address.city',
    'legalEntity.address.country',
    'legalEntity.address.country.alpha_2_code',
    'legalEntity.address.country.code',
    'legalEntity.address.country.features',
    'legalEntity.address.country.name',
    'legalEntity.address.country.slug',
    'legalEntity.address.postal_code',
    'legalEntity.address.state',
    'legalEntity.allowed_contractTypes',
    'legalEntity.company',
    'legalEntity.company.hasTeamDeal',
    'legalEntity.company.name',
    'legalEntity.company.paymentSettings',
    'legalEntity.company.slug',
    'legalEntity.ein',
    'legalEntity.isDefault',
    'legalEntity.isInternal',
    'legalEntity.kvk',
    'legalEntity.name',
    'legalEntity.onboardingTasks',
    'legalEntity.payElements',
    'legalEntity.phoneNumber',
    'legalEntity.sendToEngineAction_options',
    'legalEntity.sendToEngineAction_options.doNotSend',
    'legalEntity.sendToEngineAction_options.sendBenefit',
    'legalEntity.sendToEngineAction_options.sendPayElement',
    'legalEntity.slug',
    'legalEntity.status',
    'legalEntity.vat',
    'originating_system',
    'outstandingPayments',
    'preTaxAmount',
    'preTaxAmount.amount',
    'preTaxAmount.currency',
    'preTaxAmount.currency.code',
    'preTaxAmount.currency.name',
    'preTaxAmount.currency.slug',
    'preTaxAmount.currency.symbol',
    'remoteLegalEntity',
    'remoteLegalEntity',
    'remoteLegalEntity.address',
    'remoteLegalEntity.address.address',
    'remoteLegalEntity.address.addressLine_2',
    'remoteLegalEntity.address.city',
    'remoteLegalEntity.address.country',
    'remoteLegalEntity.address.country.alpha_2_code',
    'remoteLegalEntity.address.country.code',
    'remoteLegalEntity.address.country.features',
    'remoteLegalEntity.address.country.name',
    'remoteLegalEntity.address.country.slug',
    'remoteLegalEntity.address.postalCode',
    'remoteLegalEntity.address.state',
    'remoteLegalEntity.allowedContractTypes',
    'remoteLegalEntity.allowedContractTypes.0',
    'remoteLegalEntity.company',
    'remoteLegalEntity.company.hasTeamDeal',
    'remoteLegalEntity.company.name',
    'remoteLegalEntity.company.paymentSettings',
    'remoteLegalEntity.company.slug',
    'remoteLegalEntity.desiredCurrency',
    'remoteLegalEntity.ein',
    'remoteLegalEntity.engagedByEmploymentCount',
    'remoteLegalEntity.isDefault',
    'remoteLegalEntity.isInternal',
    'remoteLegalEntity.kvk',
    'remoteLegalEntity.localCurrency',
    'remoteLegalEntity.name',
    'remoteLegalEntity.net_pay_varianceThreshold',
    'remoteLegalEntity.onboardingTasks',
    'remoteLegalEntity.payElements',
    'remoteLegalEntity.payrollIntegration',
    'remoteLegalEntity.payrollStatus',
    'remoteLegalEntity.phoneNumber',
    'remoteLegalEntity.sendToEngineActionOptions',
    'remoteLegalEntity.sendToEngineActionOptions.doNotSend',
    'remoteLegalEntity.sendToEngineActionOptions.sendBenefit',
    'remoteLegalEntity.sendToEngineActionOptions.sendCompensation',
    'remoteLegalEntity.sendToEngineActionOptions.sendPayElement',
    'remoteLegalEntity.settings',
    'remoteLegalEntity.slug',
    'remoteLegalEntity.status',
    'remoteLegalEntity.vat',
    'resource_identifier',
    'resourceType',
    'totalTaxAmount',
    'totalTaxAmount.amount',
    'totalTaxAmount.currency',
    'totalTaxAmount.currency.code',
    'totalTaxAmount.currency.name',
    'totalTaxAmount.currency.slug',
    'totalTaxAmount.currency.symbol',
    'legacyFiles',
    'legacyOutstandingRefund',
    'legacyOutstandingRefund.amount',
    'legacyOutstandingRefund.company',
    'legacyOutstandingRefund.createdBy',
    'legacyOutstandingRefund.createdBy.integrationUsers',
    'legacyOutstandingRefund.createdBy.invitedBy',
    'legacyOutstandingRefund.creditNote',
    'legacyOutstandingRefund.creditedThroughOutstandingPayment',
    'legacyOutstandingRefund.currency',
    'legacyOutstandingRefund.description',
    'legacyOutstandingRefund.insertedAt',
    'legacyOutstandingRefund.remoteEntity',
    'legacyOutstandingRefund.reference',
    'legacyOutstandingRefund.refundDate',
    'legacyOutstandingRefund.slug',
    'legacyOutstandingRefund.status',
    'legacyOutstandingRefund.updatedAt',
    'zuoraFileId',
  ].map((entry) => camelCaseKeepDots(entry))
);

export const INVOICE_REPORT_HEADER_TOOLTIPS = {
  INVOICE_CURRENCY: 'Invoice currency',
  PAYSLIP_FX_RATE: {
    CUSTOMER:
      'This is the Remote FX rate used to convert amounts from the payslip currency to your invoice currency.',
    ADMIN: 'Invoice currency / Local currency',
  },
  CONTRACTOR_PROTECT_ONBOARDING_RESERVE_FX_RATE: {
    CUSTOMER:
      'This is the Remote FX rate used to convert amounts from the contractor compensation currency to your invoice currency.',
    ADMIN: 'Invoice currency / Contractor compensation currency',
  },
  COST_FX_RATE: 'Invoice currency / Cost currency',
  PAYSLIP_BENEFITS: 'Benefits paid directly to employees. These appear on payslips.',
  OTHER_BENEFITS:
    'Benefits paid to third parties on behalf of employees. These typically do not appear on payslips.',
  TAX_COSTS: 'This includes any tax costs that are non-deductible by Remote.',
  CONTRACTOR_INVOICE_AMOUNT: 'This is what the contractor has billed before taxes are applied.',
};

export const INVOICE_REPORT_COMMON_COLUMNS_HEADER = {
  GROSS_ANNUAL: 'Gross annual',
  GROSS_PER_PERIOD: 'Gross per period',
  BASE_SALARY: 'Base salary',
  CONTRIBUTION: 'Contribution',
  PAYSLIP_BENEFITS: 'Payslip benefits',
  BENEFITS: 'Benefits',
  EXPENSES: 'Expenses',
  INCENTIVES: 'Incentives',
  OFF_PAYSLIP_COSTS: 'Off payslip costs',
  OTHER_BENEFITS: 'Other benefits',
  DEPOSITS: 'Deposits',
  MANAGEMENT_FEE: 'Mng fee',
  SUBSCRIPTION_FEE: 'Subscription fee',
  PAYROLL_FEE: 'Payroll fee',
  PREMIUM_FEE: 'Premium fee',
  TOTAL: 'Total',
  SERVICE_COST: 'Service cost',
};

export const INVOICE_REPORT_EXPORT_COLUMNS_TO_EXCLUDE = new Set([
  'type',
  'breakdowns',
  'sourceAmount',
  'sourceCurrency.name',
  'sourceCurrency.code',
  'sourceCurrency.id',
  'sourceCurrency.slug',
  'sourceCurrency.symbol',
  'convertedAmount',
  'convertedCurrency.name',
  'convertedCurrency.code',
  'convertedCurrency.id',
  'convertedCurrency.slug',
  'convertedCurrency.symbol',
  'invoiceAmount',
  'invoiceCurrency.name',
  'invoiceCurrency.code',
  'invoiceCurrency.id',
  'invoiceCurrency.slug',
  'invoiceCurrency.symbol',
  'meta.amount',
  'meta.companyId',
  'meta.costName',
  'meta.currencyId',
  'meta.employmentId',
  'meta.id',
  'meta.insertedAt',
  'meta.invoicePeriod',
  'meta.note',
  'meta.purchaseDate',
  'meta.remoteInvoiceId',
  'meta.slug',
  'meta.status',
  'meta.type',
  'meta.updatedAt',
  'rate',
  'resourceId',
  'resourceSlug',
  'resourceType',
  'country.features',
  'country.slug',
  'employment.bankAccountDetails',
  'employment.user.name',
  'employment.name',
  'employment.user.invitedBy',
  'employment.user.integrationUsers',
  'employment.user.profilePicture',
  'employment.user.role',
  'employment.user.signupSource',
  'employment.snapshot',
  'employment.snapshot.name',
  'name',
  'slug',
  'employment.user.email',
  'employment.contract',
  'employment.contract.compensations',
  'employment.contract.firstPayrollRun',
  'employment.contract.effectiveDate',
  'employment.contract.firstPayrollRun',
  'employment.contract.payrollCycle',
  'employment.contract.provisionalStartDate',
  'employment.contract.reasonForChange',
  'employment.contract.reasonForChangeDescription',
  'employment.contract.signingBonus',
  'employment.department',
  'employment.manager',
  'employment.product',
  'localCurrency',
  'localCurrency.slug',
  'supplementalService',
  'supplementalService.area',
  'supplementalService.service',
  'remotePaidServiceInvoiceAmount',
  'remotePaidServiceLocalAmount',
]);

export const SUPPLEMENTAL_SERVICE_EXPORT_COLUMNS_TO_EXCLUDE = new Set([
  ...INVOICE_REPORT_EXPORT_COLUMNS_TO_EXCLUDE,
  'baseSalaryInvoiceAmount',
  'baseSalaryLocalAmount',
  'contractorCompensationInvoiceAmount',
  'contractorCompensationLocalAmount',
  'contributionInvoiceAmount',
  'contributionLocalAmount',
  'exchangeRate',
  'expensesInvoiceAmount',
  'expensesLocalAmount',
  'incentivesInvoiceAmount',
  'incentivesLocalAmount',
  'managementFeesInvoiceAmount',
  'expensesInvoiceAmount',
  'expensesLocalAmount',
  'incentivesInvoiceAmount',
  'incentivesLocalAmount',
  'managementFeesInvoiceAmount',
  'offPayslipBenefitsInvoiceAmount',
  'onPayslipBenefitsInvoiceAmount',
  'onPayslipBenefitsLocalAmount',
  'otherFeesInvoiceAmount',
  'premiumFeesInvoiceAmount',
  'remotePaidServiceInvoiceAmount.amount',
  'remotePaidServiceInvoiceAmount.currency',
  'remotePaidServiceLocalAmount.amount',
  'remotePaidServiceLocalAmount.currency',
  'totalAmount.amount',
  'totalAmount.currency',
  'totalInvoiceAmount.amount',
  'totalInvoiceAmount.currency',
]);

export const INVOICE_VALIDATORS_STATUSES_ICONS = {
  REGULAR: {
    passed: '🟢',
    warning: '🟡',
    failed: '🔴',
  },
  AUTOMATION: {
    passed: '🟩',
    warning: '🟨',
    failed: '🟥',
  },
};

export const propertiesInLocalCurrency = [
  'baseSalary',
  'contribution',
  'onPayslipBenefits',
  'expenses',
  'incentives',
  'reserveBaseSalary',
  'reserveContribution',
] as const;

export const propertiesInInvoiceCurrency = [
  'otherFees',
  'offPayslipBenefits',
  'upfrontFee', // deposits
  'managementFees',
  'premiumFees',
  // NOTE: `contractorPlusProratedFees` and `contractorPlusInAdvanceFees`
  // properties will be available for adjustment depending on the outcome in
  // https://linear.app/remote/issue/CM-1080.
] as const;

export type EditableProperties = Record<string, { inputLabel: string }>;

export const eorInvoiceAdjustmentProperties: EditableProperties = {
  companyCost: { inputLabel: 'Company cost?' },
  baseSalary: { inputLabel: 'Base Salary' },
  contribution: { inputLabel: 'Employer Contribution' },
  onPayslipBenefits: { inputLabel: 'On Payslip Benefits' },
  expenses: { inputLabel: 'Expenses' },
  incentives: { inputLabel: 'Incentives' },
  otherFees: { inputLabel: 'Off Payslip Costs' },
  offPayslipBenefits: { inputLabel: 'Off Payslip Benefits' },
  upfrontFee: { inputLabel: 'Deposits' },
  managementFees: { inputLabel: 'Management Fees' },
  premiumFees: { inputLabel: 'Premium Fees' },
} as const;

export const contractorBillAdjustmentProperties: EditableProperties = {
  managementFees: {
    inputLabel: 'Subscription Fee',
  },
  // NOTE: `contractorPlusProratedFees` and `contractorPlusInAdvanceFees`
  // properties will be available for adjustment depending on the outcome in
  // https://linear.app/remote/issue/CM-1080.
} as const;

export const globalPayrollAdjustmentProperties: EditableProperties = {
  managementFees: { inputLabel: 'Payroll Fee' },
} as const;

export const onboardingReserveAdjustmentProperties: EditableProperties = {
  reserveBaseSalary: { inputLabel: 'Base Salary' },
  reserveContribution: {
    inputLabel: 'Employer Contribution',
  },
} as const;

export const validatorStatuses: Record<string, InvoiceReportValidatorResult> = {
  PASSED: 'passed',
  WARNING: 'warning',
  FAILED: 'failed',
};

export const adminValidatorStatusLabel = {
  [validatorStatuses.PASSED]: 'Passed',
  [validatorStatuses.WARNING]: 'Warning',
  [validatorStatuses.FAILED]: 'Failed',
};

export const adminValidatorStatusTone: Record<string, PillTone> = {
  [validatorStatuses.PASSED]: 'success',
  [validatorStatuses.WARNING]: 'warning',
  [validatorStatuses.FAILED]: 'error',
};

export const customerValidatorStatusLabel = {
  [validatorStatuses.PASSED]: 'Passed',
  [validatorStatuses.WARNING]: 'Warning',
  [validatorStatuses.FAILED]: 'Warning',
};

export const customerValidatorStatusTone: Record<string, PillTone> = {
  [validatorStatuses.PASSED]: 'success',
  [validatorStatuses.WARNING]: 'warning',
  [validatorStatuses.FAILED]: 'warning',
};

export const reversalTypes = {
  CREDIT_NOTE: 'credit_note',
  REMOTE_INVOICE: 'remote_invoice',
  PARTIAL_CREDIT_NOTE: 'partial_credit_note',
} as const;

export const reversalTypeLabels = {
  [reversalTypes.CREDIT_NOTE]: 'Credit note',
  [reversalTypes.REMOTE_INVOICE]: 'Invoice',
  [reversalTypes.PARTIAL_CREDIT_NOTE]: 'Partial credit note',
};

export type InvoiceReportReversalType = keyof typeof reversalTypeLabels;

export const invoiceReportCorrectionTypes = {
  FULL: 'full',
  PARTIAL: 'partial',
} as const;

export const creditNoteTypeLabels = {
  [invoiceReportCorrectionTypes.FULL]: 'Full correction',
  [invoiceReportCorrectionTypes.PARTIAL]: 'Partial correction',
};

export type InvoiceReportCorrectionType = keyof typeof creditNoteTypeLabels;

export const correctionReasonLabels: Record<CorrectionReason, string> = {
  /** PRIMARY REASON LABELS */
  payroll_error: 'Payroll',
  benefits_error: 'Benefit',
  deposit_refund: 'Reserve/Deposit refund',
  customer_goodwill: 'Goodwill discount on management fee',
  remote_absorbing_cost: 'Cost absorption',
  service_fee: 'Service fee',
  lifecycle_cost: 'Lifecycle',
  team_plan_cost: 'Team plan',
  vat_update: 'VAT update',
  customer_input: 'Customer input',
  manual_error: 'Manual error',
  engineering_test: 'Engineering test',
  /** SECONDARY REASON LABELS */
  // Payroll Error
  output_mapping_error: 'Output mapping error/duplicate',
  overpayment: 'Overpayment (e.g. salary, bonus, severance, vacation)',
  equity_processing: 'Equity processing',
  er_contribution: 'ER contribution',
  advance_payroll_correction: 'Advance payroll correction / Recouped overpayments',
  // Benefits Error
  opt_in_out: 'Opt-in/Opt-out',
  plan_change: 'Plan change',
  // Deposit Refund
  reserve_requirement_reversed: 'Reserve requirement reversed',
  failed_kyb: 'Failed KYB',
  ee_offboarded: 'EE offboarded/terminated',
  ee_not_onboarded: 'EE not onboarded',
  deposit_contract_change: 'Contract/Salary change',
  customer_setup_dd: 'Customer set up DD',
  old_deposit_refund: 'Old deposit refund',
  // Customer Goodwill
  goodwill_payroll: 'Payroll',
  goodwill_benefits: 'Benefit',
  goodwill_mobility: 'Mobility',
  goodwill_lifecycle_onboarding: 'Lifecycle - Onboarding',
  goodwill_lifecycle_offboarding: 'Lifecycle - Offboarding',
  goodwill_lifecycle_ta: 'Lifecycle - T&A',
  goodwill_lifecycle_ohs: 'Lifecycle - OHS',
  goodwill_lifecycle_contract: 'Lifecycle - Contract Management',
  goodwill_compliance: 'Compliance',
  goodwill_cash_application: 'Cash Application',
  goodwill_cx_collections: 'Customer Experience (CX) - Collections',
  goodwill_cx_care: 'Customer Experience (CX) - Customer Care',
  goodwill_cx_customer_success: 'Customer Experience (CX) - Customer Success',
  goodwill_collection_write_off: 'Collection Full Write Off/Bad Debt',
  goodwill_collection_partial_settlement: 'Collections Partial Settlement',
  goodwill_legal_settlement: 'Legal Settlement',
  goodwill_sales: 'Sales',
  // Cost Absorption
  cost_absorption_payroll: 'Payroll',
  cost_absorption_benefits: 'Benefit',
  cost_absorption_mobility: 'Mobility',
  cost_absorption_lifecycle_onboarding: 'Lifecycle - Onboarding',
  cost_absorption_lifecycle_offboarding: 'Lifecycle - Offboarding',
  cost_absorption_lifecycle_ta: 'Lifecycle - T&A',
  cost_absorption_lifecycle_ohs: 'Lifecycle - OHS',
  cost_absorption_lifecycle_contract: 'Lifecycle - Contract Management',
  cost_absorption_compliance: 'Compliance',
  cost_absorption_cash_application: 'Cash Application',
  cost_absorption_cx_collections: 'Customer Experience (CX) - Collections',
  cost_absorption_cx_care: 'Customer Experience (CX) - Customer Care',
  cost_absorption_cx_customer_success: 'Customer Experience (CX) - Customer Success',
  cost_absorption_collection_write_off: 'Collection Full Write Off/Bad Debt',
  cost_absorption_collection_partial_settlement: 'Collections Partial Settlement',
  cost_absorption_legal_settlement: 'Legal Settlement',
  cost_absorption_sales: 'Sales',
  // Service Fee
  mf_annual_to_monthly: 'MF annual to monthly',
  mf_monthly_to_annual: 'MF monthly to annual',
  ee_terminated_correction: 'EE terminated correction',
  ee_relocation_correction: 'EE relocation correction',
  sf_input_error: 'SF input error',
  price_update: 'Price/discount update',
  service_not_used: 'Service not used',
  // Lifecycle cost
  lifecycle_contract_change: 'Contract change and salary amendment',
  start_date_change: 'Start date error/change',
  termination_date_change: 'Termination date error/change',
  cost_input: 'Cost input',
  // Team plan cost
  employee_transition: 'Transitioning existing EEs to new team plan from PAYG',
  payment_frequency: 'Payment frequency (ie agreed quarterly select annual)',
  plan_cancellation: 'Team plan cancellation',
  plan_renegotiation: 'Team plan renegotiation',
  // Customer Input
  rebill_request: 'Rebill request',
  credit_request: 'Credit application request',
  currency_change: 'Currency change',
  discount_change: 'Discount change',
  product_change: 'MF Product change',
  /** LEGACY LABELS */
  billing_error: 'Billing error',
  employee_offboarding: 'Employee offboarding',
  start_date_error: 'Start date error',
  write_off: 'Write-off',
  contract_change: 'Contract change',
  other_error: 'Other',
} as const;

// Primary reasons along with associated secondary reasons
export const correctionReasons: Record<PrimaryCorrectionReason, SecondaryCorrectionReason[]> = {
  payroll_error: [
    'output_mapping_error',
    'overpayment',
    'equity_processing',
    'er_contribution',
    'advance_payroll_correction',
  ],
  benefits_error: ['opt_in_out', 'plan_change'],
  deposit_refund: [
    'reserve_requirement_reversed',
    'failed_kyb',
    'ee_offboarded',
    'ee_not_onboarded',
    'deposit_contract_change',
    'customer_setup_dd',
    'old_deposit_refund',
  ],
  customer_goodwill: [
    'goodwill_payroll',
    'goodwill_benefits',
    'goodwill_mobility',
    'goodwill_lifecycle_onboarding',
    'goodwill_lifecycle_offboarding',
    'goodwill_lifecycle_ta',
    'goodwill_lifecycle_ohs',
    'goodwill_lifecycle_contract',
    'goodwill_compliance',
    'goodwill_cash_application',
    'goodwill_cx_collections',
    'goodwill_cx_care',
    'goodwill_cx_customer_success',
    'goodwill_collection_write_off',
    'goodwill_collection_partial_settlement',
    'goodwill_legal_settlement',
    'goodwill_sales',
  ],
  remote_absorbing_cost: [
    'cost_absorption_payroll',
    'cost_absorption_benefits',
    'cost_absorption_mobility',
    'cost_absorption_lifecycle_onboarding',
    'cost_absorption_lifecycle_offboarding',
    'cost_absorption_lifecycle_ta',
    'cost_absorption_lifecycle_ohs',
    'cost_absorption_lifecycle_contract',
    'cost_absorption_compliance',
    'cost_absorption_cash_application',
    'cost_absorption_cx_collections',
    'cost_absorption_cx_care',
    'cost_absorption_cx_customer_success',
    'cost_absorption_collection_write_off',
    'cost_absorption_collection_partial_settlement',
    'cost_absorption_legal_settlement',
    'cost_absorption_sales',
  ],
  service_fee: [
    'mf_annual_to_monthly',
    'mf_monthly_to_annual',
    'ee_terminated_correction',
    'ee_relocation_correction',
    'sf_input_error',
    'price_update',
    'service_not_used',
  ],
  lifecycle_cost: [
    'lifecycle_contract_change',
    'start_date_change',
    'termination_date_change',
    'cost_input',
  ],
  team_plan_cost: [
    'employee_transition',
    'payment_frequency',
    'plan_cancellation',
    'plan_renegotiation',
  ],
  vat_update: [],
  customer_input: [
    'rebill_request',
    'credit_request',
    'currency_change',
    'discount_change',
    'product_change',
  ],
  manual_error: [],
  engineering_test: [],
};

// For more information about why this was changed to a function, please refer to the MR description
// https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/29488#note_2032771887
export const loadPrimaryCorrectionReasonOptions = () => getPrimaryCorrectionReasonOptions();

export const invoiceStaleTime = 60 * 1000 * 2;

export const invoiceBreakdownMismatchFAQ =
  'https://support.remote.com/hc/en-us/articles/13982375441421-Why-does-my-monthly-invoice-breakdown-report-not-match-my-invoice-amount';

export const contractorInvoiceBreakdownFeeTypes = {
  ACTUALS: 'actuals',
  PRORATED: 'prorated',
  IN_ADVANCE: 'in_advance',
} as const;

export type GenerationSource = 'company' | 'contractor';

export const invoiceGenerationSource: ScreamingSnakeCaseMapping<GenerationSource> = {
  COMPANY: 'company',
  CONTRACTOR: 'contractor',
};
