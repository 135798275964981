function identifyAppcuesUser(user) {
  // The Appcues SDK is imported via Rudderstack.
  // Find out more about device mode here:
  // https://www.rudderstack.com/docs/destinations/rudderstack-connection-modes/#device-mode
  if (user && window.Appcues) {
    window.Appcues.identify(user.slug);
  }
}

export { identifyAppcuesUser };
