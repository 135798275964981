import { isDev, isTest } from '@/src/helpers/general';
import { makeGet } from '@/src/services/ApiClient/functions/makeRequest';

import type { FeatureFlagAudience } from './audience';
import type { FeatureFlagConfig } from './config';
import type { FeatureFlagName } from './types';

interface Flag {
  enabled: boolean;
  key: FeatureFlagName;
  audiences: FeatureFlagAudience[];
}

interface AllFlag {
  flags: Flag[];
  version: string;
}

export interface FeatureFlagFetchResponse {
  featureFlags: AllFlag;
}

export async function fetchFeatureFlagConfig(): Promise<FeatureFlagConfig> {
  const shouldFetchDisabledFlags = isDev() || isTest();
  // Purposefully not including the param, to expose less of the API to the client
  const { data } = await makeGet(`/api/v1/config`, {
    queryParams: shouldFetchDisabledFlags
      ? {
          includeDisabledFeatureFlags: shouldFetchDisabledFlags,
        }
      : {},
  });
  const config = data.featureFlags.flags.reduce<FeatureFlagConfig>((acc, flag) => {
    const { key, enabled, audiences } = flag;
    acc[flag.key] = { name: key, enabled, audiences };
    return acc;
  }, {});
  return config;
}
