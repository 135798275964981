import type { BadgeType, PillTone } from '@remote-com/norma';
import { IconV2OutlineAlertCircle } from '@remote-com/norma/icons/IconV2OutlineAlertCircle';
import { IconV2OutlineCheckCircle } from '@remote-com/norma/icons/IconV2OutlineCheckCircle';
import { IconV2OutlineMinusCircle } from '@remote-com/norma/icons/IconV2OutlineMinusCircle';
import { IconV2OutlineSpinner } from '@remote-com/norma/icons/IconV2OutlineSpinner';
import startCase from 'lodash/startCase';
import type { ValueOf } from 'type-fest';

import { generateSelectOptions } from '@/src/helpers/forms';

import type { PayElementOccurrenceForForm } from './types';

export const PAY_ELEMENTS_GROSS_UP_SUPPORTED = {
  NEVER: 'never',
  OPTIONAL: 'optional',
} as const;

export const PAY_ELEMENTS_GROSS_UP_SUPPORTED_LABELS: {
  [key in ValueOf<typeof PAY_ELEMENTS_GROSS_UP_SUPPORTED>]: string;
} = {
  [PAY_ELEMENTS_GROSS_UP_SUPPORTED.NEVER]: 'Never',
  [PAY_ELEMENTS_GROSS_UP_SUPPORTED.OPTIONAL]: 'Optional',
} as const;

export const PAY_ELEMENTS_GROSS_UP_SUPPORTED_OPTIONS = generateSelectOptions(
  PAY_ELEMENTS_GROSS_UP_SUPPORTED_LABELS
);

export const PAY_ELEMENT_CATEGORIES = {
  EMPLOYER_CONTRIBUTION: 'employer_contribution',
  PAYMENT: 'payment',
  DEDUCTION: 'deduction',
  NOTIONAL: 'notional',
  ATTENDANCE: 'time_attendance',
} as const;

export const PAY_ELEMENT_CATEGORIES_LABELS = {
  [PAY_ELEMENT_CATEGORIES.EMPLOYER_CONTRIBUTION]: 'Employer contribution',
  [PAY_ELEMENT_CATEGORIES.PAYMENT]: 'Payment',
  [PAY_ELEMENT_CATEGORIES.DEDUCTION]: 'Deduction',
  [PAY_ELEMENT_CATEGORIES.NOTIONAL]: 'Notional',
  [PAY_ELEMENT_CATEGORIES.ATTENDANCE]: 'Attendance',
} as const;

export const PAY_ELEMENT_CATEGORIES_OPTIONS = generateSelectOptions(PAY_ELEMENT_CATEGORIES_LABELS);

export const PAY_ELEMENT_UNIT_TYPES = {
  AMOUNT: 'amount',
  PERCENTAGE: 'percentage',
  UNIT: 'unit',
  DURATION: 'duration',
} as const;

export const PAY_ELEMENT_UNIT_TYPES_LABELS = {
  [PAY_ELEMENT_UNIT_TYPES.AMOUNT]: 'Amount',
  [PAY_ELEMENT_UNIT_TYPES.PERCENTAGE]: 'Percentage (%)',
  [PAY_ELEMENT_UNIT_TYPES.UNIT]: 'Unit',
  [PAY_ELEMENT_UNIT_TYPES.DURATION]: 'Duration',
} as const;

export const PAY_ELEMENT_UNIT_TYPES_OPTIONS = generateSelectOptions(PAY_ELEMENT_UNIT_TYPES_LABELS);

export const PAY_ELEMENT_OCCURRENCES = {
  TEMPORARY: 'temporary',
  PERMANENT: 'permanent',
} as const;

export const PAY_ELEMENT_OCCURRENCES_LABELS = {
  [PAY_ELEMENT_OCCURRENCES.TEMPORARY]: 'Once',
  [PAY_ELEMENT_OCCURRENCES.PERMANENT]: 'Recurring',
} as const;

// These are used when creating employee pay elements as the value of `occurrence` is slightly different
export const PAY_ELEMENT_OCCURRENCES_OPTIONS = generateSelectOptions(
  PAY_ELEMENT_OCCURRENCES_LABELS
);

// In certain scenarios, we don't want to filter exclusively by 'occurrence = temporary' or 'occurrence = permanent',
// but we want a more complex filter that includes several fields.
// More info in https://linear.app/remote/issue/PPX-702/apply-occurrence-filter-taking-into-account-if-tepes-are-related-to
export const PAY_ELEMENT_OCCURRENCES_COMPOSED_FILTERS = {
  ONCE: 'once',
  RECURRING: 'recurring',
} as const;

export const PAY_ELEMENT_OCCURRENCES_COMPOSED_FILTERS_LABELS = {
  [PAY_ELEMENT_OCCURRENCES_COMPOSED_FILTERS.ONCE]: 'Once',
  [PAY_ELEMENT_OCCURRENCES_COMPOSED_FILTERS.RECURRING]: 'Recurring',
} as const;

export const PAY_ELEMENT_OCCURRENCES_COMPOSED_FILTERS_OPTIONS = generateSelectOptions(
  PAY_ELEMENT_OCCURRENCES_COMPOSED_FILTERS_LABELS
);

export const PAY_ELEMENT_OCCURRENCES_FOR_FORM = {
  TEMPORARY: 'once',
  MONTHLY: 'monthly',
  BI_MONTHLY_PRORATA: 'bi_monthly_prorata',
  BI_MONTHLY_IDENTICAL: 'bi_monthly_identical',
} as const;

export const PAY_ELEMENT_OCCURRENCES_FOR_FORM_LABELS = {
  [PAY_ELEMENT_OCCURRENCES_FOR_FORM.TEMPORARY]: 'Once',
  [PAY_ELEMENT_OCCURRENCES_FOR_FORM.MONTHLY]: 'Monthly',
  [PAY_ELEMENT_OCCURRENCES_FOR_FORM.BI_MONTHLY_PRORATA]: 'Bi-monthly (pro-rata)',
  [PAY_ELEMENT_OCCURRENCES_FOR_FORM.BI_MONTHLY_IDENTICAL]: 'Bi-monthly (identical)',
} as const;

export const PAY_ELEMENT_OCCURRENCES_FOR_FORM_OPTIONS = generateSelectOptions(
  PAY_ELEMENT_OCCURRENCES_FOR_FORM_LABELS
) as { value: PayElementOccurrenceForForm; label: string }[];

export const PAY_ELEMENT_VALUE_METHOD = {
  EVERY_PAY_ELEMENT: 'every_pay_element',
  MONTHLY_GOAL: 'monthly_goal',
} as const;

export const PAY_ELEMENT_FREQUENCY = {
  MONTHLY: 'monthly',
  BI_MONTHLY: 'bi_monthly',
  BI_WEEKLY: 'bi_weekly',
} as const;

export const PAY_ELEMENT_TAXABLE_ENUM_TYPES = {
  REGULAR: 'yes',
  FIXED_RATE: 'fixed',
  NON_TAXABLE: 'no',
  NOT_APPLICABLE: 'not_applicable',
};

export const PAY_ELEMENT_TAXABLE_ENUM_LABELS = {
  [PAY_ELEMENT_TAXABLE_ENUM_TYPES.REGULAR]: 'Taxable - regular',
  [PAY_ELEMENT_TAXABLE_ENUM_TYPES.FIXED_RATE]: 'Taxable - fixed rate',
  [PAY_ELEMENT_TAXABLE_ENUM_TYPES.NON_TAXABLE]: 'Non taxable',
  [PAY_ELEMENT_TAXABLE_ENUM_TYPES.NOT_APPLICABLE]: 'N/A',
};

export const PAY_ELEMENT_TAXABLE_ENUM_OPTIONS = generateSelectOptions(
  PAY_ELEMENT_TAXABLE_ENUM_LABELS
);

export const PAYROLL_DATA_SOURCE = {
  INPUT: 'input',
  OUTPUT: 'output',
  BOTH: 'both',
} as const;

export const PAYROLL_DATA_SOURCE_LABELS = {
  [PAYROLL_DATA_SOURCE.INPUT]: 'Input',
  [PAYROLL_DATA_SOURCE.OUTPUT]: 'Output',
  [PAYROLL_DATA_SOURCE.BOTH]: 'Both',
} as const;

export const PAYROLL_DATA_SOURCE_OPTIONS = generateSelectOptions(PAYROLL_DATA_SOURCE_LABELS);

export const PAY_ELEMENT_REQUIREMENT_TYPES = {
  LEGALLY_REQUIRED: 'legally_required',
  OPTIONAL: 'optional',
} as const;

export const PAY_ELEMENT_REQUIREMENT_LABELS = {
  [PAY_ELEMENT_REQUIREMENT_TYPES.OPTIONAL]: 'Optional',
  [PAY_ELEMENT_REQUIREMENT_TYPES.LEGALLY_REQUIRED]: 'Legally required',
} as const;

export const PAY_ELEMENT_REQUIREMENT_OPTIONS = generateSelectOptions(
  PAY_ELEMENT_REQUIREMENT_LABELS
);

export const PAY_ELEMENT_SEND_TO_ENGINE_ACTION_VALUES = {
  SEND_PAY_ELEMENT: 'send_compensation',
  SEND_BENEFIT: 'send_benefit',
};

export const PAY_ELEMENT_SEND_TO_ENGINE_ACTION_LABELS = {
  [PAY_ELEMENT_SEND_TO_ENGINE_ACTION_VALUES.SEND_PAY_ELEMENT]: 'Pay item',
  [PAY_ELEMENT_SEND_TO_ENGINE_ACTION_VALUES.SEND_BENEFIT]: 'Benefit',
};

export const PAY_ELEMENT_ENTITY_FORM_INITIAL_VALUES = {
  name: '',
  code: '',
  pspCode: '',
  category: PAY_ELEMENT_CATEGORIES.EMPLOYER_CONTRIBUTION,
  type: PAY_ELEMENT_UNIT_TYPES.AMOUNT,
  taxableEnum: PAY_ELEMENT_TAXABLE_ENUM_TYPES.NOT_APPLICABLE,
  requirement: PAY_ELEMENT_REQUIREMENT_TYPES.OPTIONAL,
  grossUpSupported: PAY_ELEMENTS_GROSS_UP_SUPPORTED.OPTIONAL,
};

export const getPayElementEmployeeFormInitialValues = ({
  employeeCurrency,
}: {
  employeeCurrency?: { code: string };
}) => ({
  legalEntityPayElement: undefined,
  occurrence: PAY_ELEMENT_OCCURRENCES.TEMPORARY,
  effectiveDate: '',
  endDate: '',
  value: '',
  currencyCode: employeeCurrency?.code ?? '',
});

export const PAY_ELEMENT_TYPES = {
  LEGAL_ENTITY: 'legal-entity',
  EMPLOYEE: 'employee',
} as const;

export const PAY_ELEMENT_DATA_SOURCES = {
  EXPENSE: 'expense',
  INCENTIVE: 'incentive',
  BENEFIT: 'benefit',
  TIMEOFF: 'timeoff',
  TIMESHEET: 'timesheet',
  SALARY: 'salary',
} as const;

export const PAY_ELEMENT_DATA_SOURCE_LABELS = {
  [PAY_ELEMENT_DATA_SOURCES.EXPENSE]: 'Expense',
  [PAY_ELEMENT_DATA_SOURCES.INCENTIVE]: 'Incentive',
  [PAY_ELEMENT_DATA_SOURCES.BENEFIT]: 'Benefit',
  [PAY_ELEMENT_DATA_SOURCES.TIMEOFF]: 'Timeoff',
  [PAY_ELEMENT_DATA_SOURCES.TIMESHEET]: 'Timesheet',
  [PAY_ELEMENT_DATA_SOURCES.SALARY]: 'Salary',
} as const;

export const PAY_ELEMENT_DATA_SOURCE_OPTIONS = [
  { value: 'none', label: 'None' },
  ...generateSelectOptions(PAY_ELEMENT_DATA_SOURCE_LABELS),
];

export const EMPLOYEE_PAY_ELEMENT_ENGINE_SYNC_STATUSES = {
  NOT_SYNCED: 'not_synced',
  INITIATED: 'initiated',
  COMPLETED: 'completed',
  FAILED: 'failed',
} as const;

export const EMPLOYEE_PAY_ELEMENT_ENGINE_SYNC_STATUSES_LABELS = {
  [EMPLOYEE_PAY_ELEMENT_ENGINE_SYNC_STATUSES.NOT_SYNCED]: 'Not synced',
  [EMPLOYEE_PAY_ELEMENT_ENGINE_SYNC_STATUSES.INITIATED]: 'Initiated',
  [EMPLOYEE_PAY_ELEMENT_ENGINE_SYNC_STATUSES.COMPLETED]: 'Completed',
  [EMPLOYEE_PAY_ELEMENT_ENGINE_SYNC_STATUSES.FAILED]: 'Failed',
} as const;

export const EMPLOYEE_PAY_ELEMENT_ENGINE_SYNC_STATUSES_OPTIONS = generateSelectOptions(
  EMPLOYEE_PAY_ELEMENT_ENGINE_SYNC_STATUSES_LABELS
);

export const EMPLOYEE_PAY_ELEMENT_ENGINE_SYNC_STATUSES_ICONS = {
  [EMPLOYEE_PAY_ELEMENT_ENGINE_SYNC_STATUSES.NOT_SYNCED]: IconV2OutlineMinusCircle,
  [EMPLOYEE_PAY_ELEMENT_ENGINE_SYNC_STATUSES.INITIATED]: IconV2OutlineSpinner,
  [EMPLOYEE_PAY_ELEMENT_ENGINE_SYNC_STATUSES.COMPLETED]: IconV2OutlineCheckCircle,
  [EMPLOYEE_PAY_ELEMENT_ENGINE_SYNC_STATUSES.FAILED]: IconV2OutlineAlertCircle,
} as const;

export const PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS = {
  COMPENSATION_BASE_SALARY_AMOUNT: 'compensation_base_salary_amount', // Salary
  COMPENSATION_INCENTIVES_AMOUNT: 'compensation_incentives_amount', // Incentives
  COMPENSATION_EXPENSES_AMOUNT: 'compensation_expenses_amount', // Expenses
  COMPENSATION_OTHER_AMOUNT: 'compensation_other_amount', // Other
  COMPENSATION_BENEFITS_CASH_AMOUNT: 'compensation_benefits_cash_amount', // Benefits
  COMPENSATION_BENEFITS_NONCASH_AMOUNT: 'compensation_benefits_noncash_amount', // Benefits
  DEDUCTION_STATUTORY_AMOUNT: 'deduction_statutory_amount', // Deductions
  DEDUCTION_NONSTATUTORY_AMOUNT: 'deduction_nonstatutory_amount', // Deductions
  /** @deprecated */
  NET_PAY_ADJUSTMENT_AMOUNT: 'net_pay_adjustment_amount', // Adjustments
  NET_PAY_AMOUNT: 'net_pay_amount', // Salary
  /** @deprecated */
  ACCRUAL_HOLIDAY_AMOUNT: 'accrual_holiday_amount', // Accrual
  /** @deprecated */
  ACCRUAL_SEVERANCE_AMOUNT: 'accrual_severance_amount', // Accrual
  /** @deprecated */
  ACCRUAL_OTHER_AMOUNT: 'accrual_other_amount', // Accrual
  EMPLOYER_CONTRIBUTION_AMOUNT: 'employer_contribution_amount', // Employer Contribution
  /** @deprecated */
  TOTAL_PAYROLL_COST_AMOUNT: 'total_payroll_cost_amount', // Total Cost
} as const;

const DISABLED_PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS: string[] = [
  PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.NET_PAY_ADJUSTMENT_AMOUNT,
  PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.ACCRUAL_HOLIDAY_AMOUNT,
  PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.ACCRUAL_SEVERANCE_AMOUNT,
  PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.ACCRUAL_OTHER_AMOUNT,
  PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.TOTAL_PAYROLL_COST_AMOUNT,
];

export const PAY_ELEMENT_OUTPUT_AMOUNT_OPTIONS = Object.values(
  PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS
).map((value) => ({
  value,
  label: startCase(value),
  disabled: DISABLED_PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.includes(value),
  suffix: DISABLED_PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.includes(value) ? 'Deprecated' : undefined,
}));

type FieldCopy = {
  label: string;
  color?: PillTone;
};

const GENERIC_FIELD_COPY: {
  [key in string]: FieldCopy;
} = {
  SALARY: {
    label: 'Salary',
    color: 'success',
  },
  INCENTIVE: {
    label: 'Incentives',
    color: 'warning',
  },
  EXPENSE: {
    label: 'Expenses',
    color: 'purple',
  },
  BENEFIT: {
    label: 'Benefits',
    color: 'cyan',
  },
  DEDUCTION: {
    label: 'Deduction',
    color: 'warning',
  },
  EMPLOYER_CONTRIBUTION: {
    label: 'Employer Contribution',
    color: 'fuchsia',
  },
  OTHER: { label: 'Other' },
};

export const PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS_COPY: {
  [key in ValueOf<typeof PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS>]: FieldCopy;
} = {
  [PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.COMPENSATION_BASE_SALARY_AMOUNT]: GENERIC_FIELD_COPY.SALARY,
  [PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.COMPENSATION_INCENTIVES_AMOUNT]: GENERIC_FIELD_COPY.INCENTIVE,
  [PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.COMPENSATION_EXPENSES_AMOUNT]: GENERIC_FIELD_COPY.EXPENSE,
  [PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.COMPENSATION_OTHER_AMOUNT]: GENERIC_FIELD_COPY.OTHER,
  [PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.COMPENSATION_BENEFITS_CASH_AMOUNT]: GENERIC_FIELD_COPY.BENEFIT,
  [PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.COMPENSATION_BENEFITS_NONCASH_AMOUNT]:
    GENERIC_FIELD_COPY.BENEFIT,
  [PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.DEDUCTION_STATUTORY_AMOUNT]: GENERIC_FIELD_COPY.DEDUCTION,
  [PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.DEDUCTION_NONSTATUTORY_AMOUNT]: GENERIC_FIELD_COPY.DEDUCTION,
  [PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.NET_PAY_ADJUSTMENT_AMOUNT]: { label: 'Adjustments' },
  [PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.NET_PAY_AMOUNT]: GENERIC_FIELD_COPY.SALARY,
  [PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.ACCRUAL_HOLIDAY_AMOUNT]: { label: 'Accrual' },
  [PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.ACCRUAL_SEVERANCE_AMOUNT]: { label: 'Accrual' },
  [PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.ACCRUAL_OTHER_AMOUNT]: { label: 'Accrual' },
  [PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.EMPLOYER_CONTRIBUTION_AMOUNT]:
    GENERIC_FIELD_COPY.EMPLOYER_CONTRIBUTION,
  [PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.TOTAL_PAYROLL_COST_AMOUNT]: { label: 'Total Cost' },
};

/**
 * Groups the above const by `label` to create a structure like.
 *
 * ```
 * [
 *  {
 *     "label": "Salary",
 *     // This needs to be a string because react-select doesn't play nice with arrays
 *     // We then should split this string before sending the filter to the backend
 *     "value": "compensation_base_salary_amount, net_pay_amount"
 *   },
 *   ...etc
 * ]
 * ```
 *
 */
export const PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS_OPTIONS = Object.values(
  Object.entries(PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS_COPY).reduce((memo, [key, value]) => {
    const existing = memo[value.label];
    const newValue = existing ? [...existing.value, key] : [key];

    memo[value.label] = {
      label: value.label,
      value: newValue,
    };

    return memo;
  }, {} as Record<string, { label: string; value: string[] }>)
).map((option) => ({ ...option, value: option.value.join(',') }));

export const GROSS_TO_NET_CATEGORY_FIELDS = {
  SALARY: 'salary',
  INCENTIVES: 'incentives',
  EXPENSES: 'expenses',
  OTHERS: 'others',
  BENEFITS: 'benefits',
  DEDUCTIONS: 'deductions',
  EMPLOYER_CONTRIBUTIONS: 'employer_contributions',
} as const;

export const GROSS_TO_NET_CATEGORY_ORDER = {
  [GROSS_TO_NET_CATEGORY_FIELDS.SALARY]: 0,
  [GROSS_TO_NET_CATEGORY_FIELDS.BENEFITS]: 1,
  [GROSS_TO_NET_CATEGORY_FIELDS.INCENTIVES]: 2,
  [GROSS_TO_NET_CATEGORY_FIELDS.EXPENSES]: 3,
  [GROSS_TO_NET_CATEGORY_FIELDS.OTHERS]: 4,
  [GROSS_TO_NET_CATEGORY_FIELDS.DEDUCTIONS]: 5,
  [GROSS_TO_NET_CATEGORY_FIELDS.EMPLOYER_CONTRIBUTIONS]: 6,
} as const;

export const GROSS_TO_NET_CATEGORY_COPY: {
  [key in ValueOf<typeof GROSS_TO_NET_CATEGORY_FIELDS>]: {
    label: string;
    color?: PillTone;
  };
} = {
  [GROSS_TO_NET_CATEGORY_FIELDS.SALARY]: GENERIC_FIELD_COPY.SALARY,
  [GROSS_TO_NET_CATEGORY_FIELDS.INCENTIVES]: GENERIC_FIELD_COPY.INCENTIVE,
  [GROSS_TO_NET_CATEGORY_FIELDS.EXPENSES]: GENERIC_FIELD_COPY.EXPENSE,
  [GROSS_TO_NET_CATEGORY_FIELDS.OTHERS]: GENERIC_FIELD_COPY.OTHER,
  [GROSS_TO_NET_CATEGORY_FIELDS.BENEFITS]: GENERIC_FIELD_COPY.BENEFIT,
  [GROSS_TO_NET_CATEGORY_FIELDS.DEDUCTIONS]: GENERIC_FIELD_COPY.DEDUCTION,
  [GROSS_TO_NET_CATEGORY_FIELDS.EMPLOYER_CONTRIBUTIONS]: GENERIC_FIELD_COPY.EMPLOYER_CONTRIBUTION,
};

export const PAY_ELEMENT_STATUS = {
  SCHEDULED: 'scheduled',
  COMPLETED: 'completed',
  PROCESSING: 'processing',
  ARCHIVED: 'archived',
} as const;

export const PAY_ELEMENT_STATUS_LABELS = {
  [PAY_ELEMENT_STATUS.SCHEDULED]: 'Scheduled',
  [PAY_ELEMENT_STATUS.COMPLETED]: 'Completed',
  [PAY_ELEMENT_STATUS.PROCESSING]: 'Processing',
  [PAY_ELEMENT_STATUS.ARCHIVED]: 'Archived',
} as const;

export const PAY_ELEMENT_STATUS_OPTIONS = generateSelectOptions(PAY_ELEMENT_STATUS_LABELS);

export const PAY_ELEMENT_STATUS_COPY: {
  [key in ValueOf<typeof PAY_ELEMENT_STATUS>]: {
    badge: BadgeType;
  };
} = {
  [PAY_ELEMENT_STATUS.SCHEDULED]: {
    badge: 'scheduled',
  },
  [PAY_ELEMENT_STATUS.COMPLETED]: {
    badge: 'active',
  },
  [PAY_ELEMENT_STATUS.PROCESSING]: {
    badge: 'pending',
  },
  [PAY_ELEMENT_STATUS.ARCHIVED]: {
    badge: 'archived',
  },
};

export const PAY_ELEMENT_BENEFICIARY_TYPES = {
  EMPLOYEE: 'employee',
} as const;

export const PAY_ELEMENT_BENEFICIARY_TYPES_LABELS = {
  [PAY_ELEMENT_BENEFICIARY_TYPES.EMPLOYEE]: 'Employee',
} as const;

export const PAY_ELEMENT_BENEFICIARY_TYPES_OPTIONS = generateSelectOptions(
  PAY_ELEMENT_BENEFICIARY_TYPES_LABELS
);

export const PAY_ELEMENT_PAYOR = {
  CUSTOMER_PAYROLL: 'customer_payroll',
  CUSTOMER_RECHARGE: 'customer_recharge',
  CUSTOMER_BIK: 'customer_bik',
  EMPLOYEE: 'employee',
  REMOTE: 'remote',
  NOT_BILLED: 'not_billed',
} as const;

export const PAY_ELEMENT_PAYOR_LABELS = {
  [PAY_ELEMENT_PAYOR.CUSTOMER_PAYROLL]: 'Customer Payroll',
  [PAY_ELEMENT_PAYOR.CUSTOMER_RECHARGE]: 'Customer Recharge',
  [PAY_ELEMENT_PAYOR.CUSTOMER_BIK]: 'Customer BIK',
  [PAY_ELEMENT_PAYOR.EMPLOYEE]: 'Employee',
  [PAY_ELEMENT_PAYOR.REMOTE]: 'Remote',
  [PAY_ELEMENT_PAYOR.NOT_BILLED]: 'Not Billed',
} satisfies Record<ValueOf<typeof PAY_ELEMENT_PAYOR>, string>;

export const PAY_ELEMENT_PAYOR_OPTIONS = generateSelectOptions(PAY_ELEMENT_PAYOR_LABELS);
