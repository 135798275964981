import { convertUtcStringToDateObj } from '@remote-com/norma';
import { differenceInCalendarMonths } from 'date-fns';
import isBefore from 'date-fns/isBefore';

import type {
  Benefit,
  BenefitCost,
  BenefitDetails,
  BenefitGroup,
  BenefitOffer,
  BenefitPlan,
  BenefitTier,
  Carrier,
  CarrierOrPlatform,
  EmploymentBenefitOffer,
} from '@/src/api/config/employ/benefits.types';
import type { LegalEntityApi } from '@/src/api/config/employ/legalEntity.types';
import type { UserAccount } from '@/src/api/config/employ/userAccount.types';
import {
  benefitCostInvoicePaymentStatus,
  benefitGroupFamilyLabels,
  benefitPlanCostTypes,
  countriesWithHiddenPensionCosts,
  benefitPlanType,
  benefitPlanTypeLabels,
} from '@/src/domains/benefits/constants';
import { employmentType } from '@/src/domains/employment/constants';
import { isContractor, isEmployee, isEmployer } from '@/src/domains/registration/auth/helpers';
import { convertToCents, friendlyMoney } from '@/src/helpers/currency';
import { friendlyLabel, friendlyPlaceholderDash } from '@/src/helpers/general';

/**
 * Returns a structured name for the benefit plan.
 * It will only return if the benefit plan exists and it's not an array (this is a special
 * fix when we're using a multi select field).
 */
export const getBenefitPlanShortDisplayName = (
  benefitPlan: BenefitPlan,
  { showSlug = false, prependInactive = false } = {}
) => {
  return benefitPlan && !Array.isArray(benefitPlan)
    ? `${prependInactive && benefitPlan.status === 'inactive' ? '(Inactive) ' : ''}${
        benefitPlan.name
      } (${benefitPlan.carrier?.name || benefitPlan?.country?.name})${
        showSlug ? ` [${benefitPlan.slug}]` : ''
      }`
    : friendlyLabel(null);
};

const isValidCost = (value: any) => !!value || value === 0;

export function friendlyBenefitPlanCost({
  planCostType,
  currency,
  employerPremiumFlat,
  employerPremiumPercentage,
}: Partial<BenefitPlan> = {}) {
  const flatCost = employerPremiumFlat;
  const percentageCost = employerPremiumPercentage;

  if (planCostType === benefitPlanCostTypes.FLAT && isValidCost(flatCost)) {
    return friendlyMoney(flatCost, currency);
  }
  if (planCostType === benefitPlanCostTypes.PERCENTAGE && isValidCost(percentageCost)) {
    return `${percentageCost}%`;
  }

  return friendlyPlaceholderDash;
}

export const friendlyPercentage = (value: any) =>
  isValidCost(+value) && value !== null ? `${value}%` : friendlyPlaceholderDash;

export const benefitsFieldDisplayFieldInfoBlock = ({
  name,
  title = 'Benefits',
  value,
}: {
  name?: string;
  title?: string | React.ReactElement;
  value: Object | any;
}) => ({
  title,
  ...(name ? { name } : {}),
  value:
    value && typeof value === 'object'
      ? Object.entries(value).map(([key, val]) => ({
          title: ['defaultGroup', 'default_group'].includes(key) ? '' : key,
          value: val === 'no' ? 'None' : val,
        }))
      : value,
});

// Checks if user should see Benefits entry in sidebar menu
export function shouldShowBenefitsSidebarEntry(
  user: UserAccount,
  eligibleForSimplyInsured: Boolean,
  hasAccessToSimplyInsured: Boolean
): Boolean {
  if (isContractor(user) || isEmployer(user)) return true;
  if (!isEmployee(user)) return false;

  if (eligibleForSimplyInsured) return hasAccessToSimplyInsured;

  return user.activeEmployment?.type !== employmentType.DIRECT;
}

/**
 * Splits renewal instruction by new lines. Removes empty entries.
 * @param {String} value - string renewal instruction
 * @returns {String[]}
 */
export const splitRenewalInstructions = (value: string = '') =>
  (value || '').split(/\r?\n/).filter(Boolean);

/**
 * Returns the list of countries that are already using the benefit costs ledger
 * @param {BenefitDetails[]} - list of country benefit details
 */
export function getCostLedgerCountryCodes(benefitDetails: BenefitDetails[] = []): string[] | [] {
  const today = new Date();
  return benefitDetails
    .filter(
      ({ benefitCostsLaunchOn }) =>
        benefitCostsLaunchOn && isBefore(new Date(benefitCostsLaunchOn), today)
    )
    .map(({ country }) => country.code);
}

type Option = {
  value: string;
  label: string;
};

/**
 * Returns a string composed of the labels from an OptionType array having the same values
 * @param {string[]} values - list of values to be found in the options
 * @param {Option[]} options - list of options to search in
 * @param {string} delimiter - delimiter for joining strings
 */
export const joinLabelsFromOptionsValues = (
  values: string[],
  options: Option[] = [],
  delimiter: string = ', '
): string => {
  const valuesSet = new Set(values);

  return options
    .reduce((acc, option) => {
      if (valuesSet.has(option.value)) {
        acc.push(option.label);
      }
      return acc;
    }, [] as string[])
    .join(delimiter);
};

/**
 * Returns an object with the Provider info, given a Carrier.
 * Will return null if the Provider has been hidden.
 * @param {Carrier} carrier - carrier
 */
export function getProvider(carrier: Carrier | null) {
  if (!carrier || carrier.hideFromEmployee) return null;
  return {
    description: carrier.description,
    name: carrier.name,
    url: carrier.url,
  };
}

/**
 * Returns an object with the Platform info, given a Carrier.
 * Will return null if the Platform has been hidden.
 * @param {Carrier} carrier - carrier
 */
export function getPlatform(carrier: Carrier | null) {
  if (!carrier || carrier.benefitPlatformHideFromEmployee || !carrier.benefitPlatformName)
    return null;

  return {
    description: carrier.benefitPlatformDescription,
    name: carrier.benefitPlatformName,
    url: carrier.benefitPlatformUrl,
  };
}

/**
 * Returns an object with the providers and platforms, from a list of benefits.
 * To be used in Employee-facing scenarios, since it removes providers/carriers that have been
 * configured by Admins to be hidden from employees
 * @param {Benefit[]} benefits - list of benefits
 */
export function getPlatformsAndProviders(benefits: Benefit[] | undefined): {
  providers: CarrierOrPlatform[];
  platforms: CarrierOrPlatform[];
} {
  const result = (benefits || []).reduce(
    ({ providers, platforms }, { benefitPlan }) => {
      const { carrier } = benefitPlan;
      const provider = getProvider(carrier);
      const platform = getPlatform(carrier);

      return {
        providers: {
          ...providers,
          ...(provider ? { [provider.name]: provider } : {}),
        },
        platforms: {
          ...platforms,
          ...(platform ? { [platform.name]: platform } : {}),
        },
      };
    },
    {
      providers: {},
      platforms: {},
    }
  );

  return {
    providers: Object.values(result.providers),
    platforms: Object.values(result.platforms),
  };
}

export const getInitialValuesFromOffers = (offers: EmploymentBenefitOffer[]) =>
  offers.reduce(
    (acc, { benefitGroup: { slug, filter }, benefitTier }) => ({
      ...acc,
      [slug]: {
        value: benefitTier?.slug || 'no',
        ...(filter?.slug ? { filter: filter.slug } : {}),
      },
    }),
    {}
  );

export function shouldForceNewPillForEmployers(activeLegalEntities: LegalEntityApi.LegalEntity[]) {
  const includedCountries = ['USA', 'ESP', 'IRL', 'GBR'];

  for (const entity of activeLegalEntities) {
    const countryCheck = includedCountries.includes(entity.address.country.code);
    const globalPayrollCheck = entity.activeServices?.some((service) => {
      return service.status === 'active' && service.group === 'global_payroll';
    });

    if (countryCheck && globalPayrollCheck) {
      return true;
    }
  }

  return false;
}

export function getOfferedGroupFamiliesWithRenewDates(benefitOffers: BenefitOffer[]) {
  return benefitOffers
    .filter(
      (offer) =>
        Boolean(offer.benefitTier) &&
        offer.benefitGroup.family &&
        offer.benefitGroup.family in benefitGroupFamilyLabels
    )
    .map((offer) => {
      const benefitGroup = offer.benefitGroup as BenefitGroup & {
        successorBenefitGroup?: BenefitGroup;
      };
      return benefitGroup.family
        ? {
            family: benefitGroupFamilyLabels[benefitGroup.family],
            renewalSelectionEndDate: benefitGroup.successorBenefitGroup?.renewalSelectionEndDate,
            renewalSelectionStartDate:
              benefitGroup.successorBenefitGroup?.renewalSelectionStartDate,
          }
        : null;
    });
}

export function isCostComingSoon({
  country,
  isAdmin,
  type,
}: {
  country?: { code: string };
  isAdmin: boolean;
  type?: string;
}) {
  if (isAdmin) return false;

  const hiddenCountry = countriesWithHiddenPensionCosts.includes(country?.code || '');
  const hiddenType = type === benefitPlanType.PENSION;

  return hiddenCountry && hiddenType;
}

export function formatBenefitOffersForTableExport(
  offers: { benefitTier: BenefitTier | null }[] = []
) {
  const plans = offers.flatMap(({ benefitTier }) =>
    (benefitTier?.benefitPlans || []).map(
      ({ name, type }) => `${name} (${benefitPlanTypeLabels[type]})`
    )
  );

  return [...new Set(plans)].join('\n') || '—';
}

export function getBenefitCostInvoicePaymentStatus(
  effectiveMonth: string,
  invoiceable: boolean,
  billableItem?: BenefitCost['billableItem']
) {
  // If it's not invoiceable, there's no payment status.
  if (!invoiceable) {
    return null;
  }

  // If there's not Billable Item yet, but it's invoiceable
  if (!billableItem) {
    // Check the effective month against the current date
    const effectiveMonthDate = convertUtcStringToDateObj(effectiveMonth)!;
    const calendarMonthDifference = differenceInCalendarMonths(effectiveMonthDate, new Date());

    // If the Benefit Cost has is more than one month in the past, it should
    // have a Billable Item. If it doesn't have one, it means that Billable
    // Items were not used yet during its Effective Month, so we return null
    // status
    if (calendarMonthDifference < -1) {
      return null;
    }
    // If effectiveMonth is in previous month and we don't have a Billable
    // Item yet it means we are in the days before EOR reconciliation.
    // Return PREPARING to signal that a Billable Item will be created in
    // the current month.
    if (calendarMonthDifference === -1) {
      return benefitCostInvoicePaymentStatus.PREPARING;
    }
    // Otherwise return SCHEDULED to signal that a Billable Item will be
    // created in the future.
    return benefitCostInvoicePaymentStatus.SCHEDULED;
  }

  // If billableItem exists but has no billingDocument, return CREATED
  if (!billableItem.billingDocument) {
    return benefitCostInvoicePaymentStatus.CREATED;
  }

  // Lastly, if we have a Billing Document, we know it's been invoiced.
  // If we also have an amountDue and it is exactly 0, we know it's been PAID.
  const amountDueInCents = convertToCents(billableItem.billingDocument.amountDue?.amount);
  if (amountDueInCents === 0) return benefitCostInvoicePaymentStatus.PAID;

  // Otherwise, we return INVOICED
  return benefitCostInvoicePaymentStatus.INVOICED;
}
