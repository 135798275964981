import type { InvoiceReportTypeFilterValue } from '@/src/domains/invoices/constants';
import {
  invoiceReportFilterTypeLabels,
  invoiceReportFilterTypeLabelsForEmployers,
  invoiceReportTypeByGroupForEmployers,
  invoiceReportTypeFilterValues,
} from '@/src/domains/invoices/constants';

export const getInvoiceReportTypeFilterLabel = (
  type: InvoiceReportTypeFilterValue,
  userIsAdmin?: boolean
) => {
  if (!userIsAdmin) {
    return invoiceReportFilterTypeLabelsForEmployers[type];
  }

  return invoiceReportFilterTypeLabels[type];
};

export function getInvoiceReportTypeFilterOptions(
  userIsAdmin: boolean,
  isRecruitPackageEnabled: boolean,
  isHrisSubscriptionsEnabled: boolean
) {
  const types: Partial<typeof invoiceReportTypeFilterValues> = {
    ...invoiceReportTypeFilterValues,
  };

  const isInvoiceReportFilterSupported = (type: InvoiceReportTypeFilterValue) => {
    if (type === invoiceReportTypeFilterValues.RECRUIT_SUBSCRIPTION) {
      return isRecruitPackageEnabled;
    }

    if (type === invoiceReportTypeFilterValues.HRIS_SUBSCRIPTIONS) {
      return isHrisSubscriptionsEnabled;
    }

    return true;
  };

  if (!userIsAdmin) {
    return invoiceReportTypeByGroupForEmployers.map((group) => ({
      ...group,
      options: group.options.filter(isInvoiceReportFilterSupported).map((option) => ({
        label: getInvoiceReportTypeFilterLabel(option, false),
        value: option,
      })),
    }));
  }

  if (!userIsAdmin) {
    delete types.CORRECTION_INVOICE;
    delete types.CORRECTION_CREDIT_NOTE;
  }

  return Object.values(types)
    .filter(isInvoiceReportFilterSupported)
    .map((type: InvoiceReportTypeFilterValue) => {
      return {
        label: getInvoiceReportTypeFilterLabel(type, userIsAdmin),
        value: type,
      };
    });
}
