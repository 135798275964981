import { useState } from 'react';
import styled, { css } from 'styled-components';

import { Box } from '../../foundations/layout/Box';
import { Stack } from '../../foundations/layout/Stack';
import { IconChevronDown } from '../../icons/build/IconChevronDown';
import { IconChevronUp } from '../../icons/build/IconChevronUp';
import { StandaloneLink } from '../button';
import { Text } from '../text/Text';
import { Tooltip } from '../tooltip/Tooltip';
import type { TooltipProps } from '../tooltip/Tooltip';

type WarningDetail = {
  title: string;
  description: string;
  action?: {
    onClick: () => void;
    label: string;
  };
};

const StyledBox = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  p: 0,
  m: 0,
})`
  text-align: left;
  align-items: flex-start;
  :not(:first-child) {
    padding-top: ${({ theme }) => theme.space[4]}px;
  }
  :not(:last-child) {
    padding-bottom: ${({ theme }) => theme.space[4]}px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[300]};
  }
`;

const WarningItem = ({ detail }: { detail: WarningDetail }) => {
  return (
    <StyledBox>
      <Text color="red.700" variant="xsMedium">
        {detail.title}
      </Text>
      <Text color="grey.900" variant="xs">
        {detail.description}
      </Text>
      {detail.action && (
        <StandaloneLink size="sm" onClick={detail.action.onClick}>
          {detail.action.label}
        </StandaloneLink>
      )}
    </StyledBox>
  );
};

const ShowMore = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'row',
  gap: 2,
  pt: 4,
  pb: 2,
})``;

const WarningList = ({
  warnings,
  defaultItemsLimit,
  singularPluralItemsKind,
}: {
  warnings: Array<WarningDetail>;
} & Required<Pick<WarningTooltipProps, 'defaultItemsLimit' | 'singularPluralItemsKind'>>) => {
  const additionalItemsCount = warnings.length - defaultItemsLimit;
  const [showAll, setShowAll] = useState(false);

  return (
    <Stack justifyContent="flex-start" px={4} py={3} width="100%">
      {(showAll ? warnings : warnings.slice(0, defaultItemsLimit)).map((warning, index) => (
        <WarningItem key={`${warning.title}-${index}`} detail={warning} />
      ))}
      {warnings.length > defaultItemsLimit && (
        <ShowMore>
          {!showAll && (
            <Text color="grey.900" variant="xs">
              {`Plus ${additionalItemsCount} more ${
                additionalItemsCount === 1
                  ? singularPluralItemsKind.singular
                  : singularPluralItemsKind.plural
              }.`}
            </Text>
          )}
          <StandaloneLink
            size="sm"
            IconAfter={showAll ? IconChevronUp : IconChevronDown}
            onClick={() => setShowAll(!showAll)}
          >
            View {showAll ? 'less' : 'all'} {singularPluralItemsKind.plural}
          </StandaloneLink>
        </ShowMore>
      )}
    </Stack>
  );
};

export type WarningTooltipProps = Omit<TooltipProps, 'label' | 'content'> & {
  content: Array<WarningDetail> | WarningDetail;
  /**
   * The number of items to show by default.
   */
  defaultItemsLimit?: number;
  /**
   * The kind of singular and plural copy to use when the items limit is surpassed.
   */
  singularPluralItemsKind?: { singular: string; plural: string };
};

const wrapperStyle = css`
  width: 324px;
  max-height: 380px;
  overflow-y: scroll;
  padding: 0;
  margin: 0;
`;

export const WarningTooltip = ({
  content,
  defaultItemsLimit = 10,
  singularPluralItemsKind = { singular: 'error', plural: 'errors' },
  ...rest
}: WarningTooltipProps) => {
  const contentAsArray = Array.isArray(content) ? content : [content];
  const labelComponent = (
    <WarningList
      warnings={contentAsArray}
      defaultItemsLimit={defaultItemsLimit}
      singularPluralItemsKind={singularPluralItemsKind}
    />
  );

  return (
    <Tooltip
      {...rest}
      label={labelComponent}
      type="default"
      maxWidth="324px"
      styles={{
        wrapper: wrapperStyle,
      }}
    />
  );
};
