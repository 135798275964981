import type { DefaultTheme } from 'styled-components';
import styled, { css, keyframes } from 'styled-components';
import { space } from 'styled-system';

import { IconArrowUpRight } from '../../icons/build/IconArrowUpRight';
import { cursor } from '../../utils/system-constants';
import { sharedTransition } from '../animations';
import { focusVisible, focusRingBorderCSS } from '../common.styled';

import type { ButtonProps } from './Button';
import type { ButtonIconVariant } from './ButtonIcon';

type VariantType = {
  text?: string | null;
  bg?: string | null;
  border?: string | null;
  hoverBg?: string | null;
  hoverBorder?: string | null;
  focusBg?: string | null;
  focusBorder?: string | null;
  textHover?: string | null;
};

const tones = (colors: DefaultTheme['colors']) => ({
  primary: {
    solid: {
      text: colors.blank,
      bg: colors.primary,
      border: 'transparent',
      hoverBg: colors.brand[700],
      hoverBorder: 'transparent',
      focusBg: colors.primary,
      focusBorder: colors.brand[500],
    },
    outline: {
      text: colors.primary,
      bg: colors.blank,
      border: colors.brand[300],
      hoverBg: colors.brand[100],
      hoverBorder: colors.brand[500],
      focusBg: null,
      focusBorder: colors.brand[500],
      textHover: colors.brand[800],
    },
    ghost: {
      text: colors.primary,
      bg: 'transparent',
      border: 'transparent',
      hoverBg: colors.brand[100],
      focusBg: null,
      focusBorder: colors.brand[500],
      textHover: colors.brand[800],
    },
    raw: {},
  },
  secondary: {
    solid: {
      /* N.B.: In v2 a solid variant for the secondary tone does not exist anymore, so it will eventually need to be deleted */
      text: colors.grey[600],
      bg: colors.blank,
      border: colors.grey[300],
      hoverBg: colors.grey[100],
      hoverBorder: colors.grey[400],
      focusBg: null,
      focusBorder: colors.grey[500],
      textHover: colors.grey[900],
    },
    outline: {
      text: colors.grey[600],
      bg: colors.blank,
      border: colors.grey[300],
      hoverBg: colors.grey[100],
      hoverBorder: colors.grey[400],
      focusBg: null,
      focusBorder: colors.grey[500],
      textHover: colors.grey[900],
    },
    ghost: {
      text: colors.grey[600],
      bg: 'transparent',
      border: 'transparent',
      hoverBg: colors.grey[200],
      focusBg: null,
      focusBorder: colors.grey[500],
      textHover: colors.grey[900],
    },
    raw: {},
  },
  destructive: {
    solid: {
      text: colors.blank,
      bg: colors.red[600],
      border: 'transparent',
      hoverBg: colors.red[700],
      hoverBorder: 'transparent',
      focusBg: null,
      focusBorder: colors.red[600],
    },
    outline: {
      text: colors.red[600],
      bg: colors.blank,
      border: colors.red[300],
      hoverBg: colors.red[100],
      hoverBorder: colors.red[400],
      focusBg: null,
      focusBorder: colors.red[600],
      textHover: colors.red[800],
    },
    ghost: {
      text: colors.red[600],
      bg: 'transparent',
      border: 'transparent',
      hoverBg: colors.red[200],
      focusBg: null,
      focusBorder: colors.red[600],
      textHover: colors.red[800],
    },
    raw: {},
  },
});

const ButtonBaseStyle = css`
  position: relative;
  display: inline-flex;
  align-items: center;
  color: inherit;
  background-color: transparent;
  border: none;
  padding: 0;
  text-decoration: none;
  font-family: inherit;
  font-weight: 500;
  justify-content: center;
`;

/* eslint-disable-next-line remote/norma-prefer-button */
export const ButtonBase = styled.button`
  ${ButtonBaseStyle}
`;

const commonButtonSizeInPx = {
  xs: '26px',
  sm: '34px',
  md: '40px',
  lg: '48px',
};

// ============= Button (Main) ============== //
const btnMainSizes = {
  lg: css`
    min-height: ${commonButtonSizeInPx.lg};
    min-width: 120px;
    padding: 0 24px;
    ${({ theme }) => theme.typography.smMedium}
  `,
  md: css`
    min-height: ${commonButtonSizeInPx.md};
    min-width: 88px;
    padding: 0 24px;
    ${({ theme }) => theme.typography.smMedium}
  `,
  sm: css`
    min-height: ${commonButtonSizeInPx.sm};
    min-width: 80px;
    padding: 0 20px;
    ${({ theme }) => theme.typography.smMedium}
  `,
  xs: css`
    min-height: ${commonButtonSizeInPx.xs};
    min-width: 64px;
    padding: 0 12px;
    ${({ theme }) => theme.typography.smMedium}
  `,
};

export type ButtonMainProps = {
  $size: keyof typeof btnMainSizes;
  $variant: ButtonProps['variant'];
  $tone: keyof ReturnType<typeof tones>;
  $isLoading?: boolean;
};

export const ButtonMain = styled(ButtonBase)<ButtonMainProps>`
  line-height: 1; /* prevent breaking the layout if it has 2 lines */

  border-radius: 50px;
  /* Set an border invisible so that all variants look similar
   in width, regardless if they have or not an actual border. */
  border: 1px solid transparent;
  ${sharedTransition('color, background-color, border-color, opacity')};

  color: var(--button-colorText);

  ${({ $size }) => btnMainSizes[$size]};

  ${({ theme, $variant, $tone }) => {
    const colors = tones(theme.colors)[$tone];

    const variantColors = ($variant && (colors[$variant] as VariantType)) || {};

    const sharedStyles = css`
      --button-colorText: ${variantColors.text};
      &:hover {
        border-color: ${variantColors.hoverBorder};
        background-color: ${variantColors.hoverBg};
        color: ${variantColors.textHover};
      }
    `;

    const focusStyles = css`
      ${focusVisible(css`
        background-color: ${variantColors.hoverBg};
        box-shadow: 0 0 0 2px ${theme.colors.blank}, 0 0 0 4px ${variantColors.focusBorder};
      `)}
    `;

    switch ($variant) {
      case 'raw':
        return css`
          all: unset;
          cursor: pointer;
          ${focusVisible(css`
            ${focusRingBorderCSS(colors.solid.bg)};
          `)};
        `;
      default:
        return css`
          background-color: ${variantColors.bg};
          border-color: ${variantColors.border};
          ${sharedStyles}
          ${focusStyles}
        `;
    }
  }}

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      pointer-events: none;
      color: transparent;
    `}

  ${space}
  ${cursor}

  &[aria-disabled="true"] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const IconAddon = styled.svg<{
  $size?: 'xs' | 'sm' | 'md' | 'lg';
  $place: 'before' | 'after';
  $variant: ButtonProps['variant'];
}>`
  flex-shrink: 0;

  ${({ $size }) => {
    switch ($size) {
      case 'xs':
      case 'sm':
        return css`
          width: 16px;
        `;
      case 'md':
      case 'lg':
        return css`
          width: 20px;
        `;
      default:
        return css`
          width: 20px;
        `;
    }
  }}

  ${({ $place, $size }) => {
    switch ($place) {
      case 'before':
        return css`
          margin-left: ${$size === 'xs' ? '-4px' : '-8px'};
          margin-right: ${$size === 'xs' ? '4px' : '8px'};
        `;
      case 'after':
      default:
        return css`
          margin-right: ${$size === 'xs' ? '-4px' : '-8px'};
          margin-left: ${$size === 'xs' ? '4px' : '8px'};
        `;
    }
  }}
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingAddon = styled.span`
  position: absolute;
  display: block;
  width: 1em;
  height: 1em;
  top: calc(50% - 0.5em);
  left: calc(50% - 0.5em);
  border-width: 2px;
  border-color: var(--button-colorText);
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-style: solid;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  animation: ${spin} 0.45s linear infinite;
`;

const sharedButtonColors = (theme: DefaultTheme) => ({
  primary: {
    text: theme.colors.primary,
    textHover: theme.colors.brand[800],
    focusBorder: theme.colors.brand[500],
  },
  secondary: {
    text: theme.colors.grey[600],
    textHover: theme.colors.grey[900],
    focusBorder: theme.colors.grey[600],
  },
  destructive: {
    text: theme.colors.negative.foreground,
    textHover: theme.colors.red[800],
    focusBorder: theme.colors.red[600],
  },
  default: {
    text: null,
    textHover: null,
    focusBorder: null,
  },
});

// ============= ButtonInline ============== //
const buttonInlineStyles = css<{
  $tone: keyof ReturnType<typeof sharedButtonColors>;
  $variant: 'underline' | 'default';
}>`
  ${({ theme, $tone, $variant }) => {
    const colors = sharedButtonColors(theme)[$tone] || sharedButtonColors(theme).default;

    const variantColors = {
      text: colors.text,
      textHover: colors.textHover,
      focusBorder: colors.focusBorder,
    };

    return css`
      --button-colorText: ${colors.text};
      --button-colorTextHover: ${colors.textHover};

      ${focusVisible(css`
        box-shadow: 0 0 0 2px ${theme.colors.blank}, 0 0 0 4px ${variantColors.focusBorder};
        border-radius: ${theme.borderRadius['2xs']};
        outline-offset: 2px;
      `)}

      ${$variant === 'underline' &&
      css`
        text-decoration: underline;
        text-decoration-color: var(--button-lineColor);
      `}
    `;
  }}

  font-size: inherit;
  cursor: pointer;
  text-align: inherit;
  text-underline-offset: ${({ theme }) => theme.space[2]}px;
  text-decoration-thickness: ${({ theme }) => theme.space[1]}px;

  &:hover {
    color: var(--button-colorText);
    text-decoration-line: underline;
  }
`;

// NOTE: We're defining the styles separately here so that we can reuse them in places
// where we can't use styled components (e.g. where we expose links via a public API)
export const ButtonInlineStyles = css`
  ${ButtonBaseStyle}
  position: relative;
  display: inline;

  &[target='_blank'] {
    white-space: nowrap;
  }

  color: var(--button-colorText);

  ${buttonInlineStyles}

  &[aria-disabled="true"] {
    cursor: not-allowed;
    opacity: 0.5;

    /* Ensures these styles only apply to buttons, not links */
    &:not([href]) {
      pointer-events: none;
    }
  }
`;

/* eslint-disable-next-line remote/norma-prefer-button */
export const ButtonInline = styled.button`
  ${ButtonInlineStyles}
`;

export const ButtonInlineExternalIcon = styled(IconArrowUpRight)`
  width: 16px;
  position: relative;
  top: ${({ theme }) => theme.space[1]}px;
  margin-left: -${({ theme }) => theme.space[2]}px;
  /* to compensate for white space in svg */
  margin-right: -${({ theme }) => theme.space[1]}px;
`;

// ============= ButtonIcon ============== //
export const ButtonIconArea = styled(ButtonMain)<{
  $size: keyof typeof commonButtonSizeInPx;
  $variant: ButtonIconVariant;
  $tone: keyof ReturnType<typeof tones>;
}>`
  min-width: ${({ $size }) => commonButtonSizeInPx[$size]};
  padding: 0;
`;

// ================== StandaloneLink ================ //
const btnStandaloneTypography = {
  xs: '2xsMedium',
  sm: 'xsMedium',
  md: 'smMedium',
  lg: 'baseMedium',
} as const;

const standaloneLinkStyles = css<{
  $size: keyof typeof btnStandaloneTypography;
  $tone: keyof ReturnType<typeof sharedButtonColors>;
  $variant: ButtonProps['variant'];
}>`
  ${({ theme, $size, $tone }) => {
    const colors = sharedButtonColors(theme)[$tone] || sharedButtonColors(theme).default;
    return css`
      --button-colorText: ${colors.text};
      --button-colorTextHover: ${colors.textHover};
      color: var(--button-colorText);
      &:hover {
        color: var(--button-colorTextHover) !important;
      }
      box-sizing: border-box;
      position: relative;
      ${focusVisible(css`
        box-shadow: 0 0 0 2px ${theme.colors.blank}, 0 0 0 4px ${colors.focusBorder};
        border-radius: ${theme.borderRadius['2xs']};
        outline-offset: 2px;
      `)}
      ${theme.typography[btnStandaloneTypography[$size]]};
      ${sharedTransition('color, background-color, border-color, opacity')};
    `;
  }};
`;

export const StandaloneLinkIcon = styled.svg<{
  $size?: 'xs' | 'sm' | 'md' | 'lg';
  $place: 'before' | 'after';
}>`
  flex-shrink: 0;
  height: 16px;
  width: 16px;
  ${({ $place, $size }) => {
    switch ($place) {
      case 'before':
        return css`
          margin-right: ${$size === 'lg' ? '8px' : '4px'};
        `;
      case 'after':
      default:
        return css`
          margin-left: ${$size === 'lg' ? '8px' : '4px'};
        `;
    }
  }}
`;

// NOTE: We're defining the styles separately here so that we can reuse them in places
// where we can't use styled components (e.g. where we expose links via a public API)
export const StandaloneLinkStyles = css`
  ${ButtonBaseStyle}
  ${standaloneLinkStyles}
`;

/* eslint-disable-next-line remote/norma-prefer-button */
export const StandaloneLink = styled.button`
  ${StandaloneLinkStyles}
  &[aria-disabled="true"] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
