import type { ValueOf } from 'type-fest';

import type { PayrollCycle } from '@/src/domains/payroll/PayrollCycles/constants';
import { generateSelectOptions } from '@/src/helpers/forms';

import type { Amount } from './shared.types';

export const COMPENSATION_WAGE_TYPES = {
  HOURLY: 'hourly',
  SALARY: 'salary',
} as const;

export const COMPENSATION_WAGE_TYPES_LABELS = {
  [COMPENSATION_WAGE_TYPES.HOURLY]: 'Hourly',
  [COMPENSATION_WAGE_TYPES.SALARY]: 'Salaried',
} as const satisfies Record<ValueOf<typeof COMPENSATION_WAGE_TYPES>, string>;

export const COMPENSATION_WAGE_TYPES_OPTIONS = generateSelectOptions(
  COMPENSATION_WAGE_TYPES_LABELS
);

export type Compensation = {
  amount: Amount;
  grossRecurringSalary: Amount;
  slug: string;
  status: string;
  overtimeEligible?: boolean;
  wageType: ValueOf<typeof COMPENSATION_WAGE_TYPES>;
  grossRegularHourlyRate: Amount | null;
  payrollCycle: PayrollCycle;
  salaryRecurrence?: PayrollCycle;
};
