import { datadogRum } from '@datadog/browser-rum';

import { featureFlagEvents, trackEvent } from '../rudderstack';

const MIXPANEL_EVENTS_ENABLED = false;

/**
 * Represents a feature flag that has been evaluated during the current user session
 */
interface TrackedFlag {
  flagName: string;
  enabled: boolean;
}

/**
 * Singleton class that tracks feature flag evaluations during a user session.
 * It serves two main purposes:
 * 1. Enriches Datadog RUM with feature flag context for better error tracking
 * 2. Sends analytics events when flags are evaluated (only for new/changed evaluations)
 *
 * Usage:
 * ```ts
 * const tracker = FeatureFlagTracker.getInstance();
 * tracker.trackEvaluation({
 *   flagName: 'MY_FEATURE',
 *   enabled: true,
 *   userId: 'user-123',
 *   attributes: { userType: 'admin' }
 * });
 * ```
 */
export class FeatureFlagTracker {
  static #instance: FeatureFlagTracker;

  private evaluatedFlags: Map<string, TrackedFlag>;

  private constructor() {
    this.evaluatedFlags = new Map();
  }

  /**
   * Gets the singleton instance of the tracker.
   * Creates it if it doesn't exist yet.
   */
  public static get instance(): FeatureFlagTracker {
    if (!FeatureFlagTracker.#instance) {
      FeatureFlagTracker.#instance = new FeatureFlagTracker();
    }
    return FeatureFlagTracker.#instance;
  }

  /**
   * Tracks a feature flag evaluation, updating both Datadog context and analytics.
   * Only tracks if this is a new evaluation or the result has changed.
   */
  public trackEvaluation(params: {
    flagName: string;
    enabled: boolean;
    userId: string | null;
    attributes?: Record<string, unknown>;
  }): void {
    const { flagName, enabled, userId, attributes } = params;

    // Check if this is a new evaluation or different result
    const existingFlag = this.evaluatedFlags.get(flagName);
    const isNewOrChanged = !existingFlag || existingFlag.enabled !== enabled;

    if (isNewOrChanged) {
      // Update internal state
      this.evaluatedFlags.set(flagName, {
        flagName,
        enabled,
      });

      // Update Datadog RUM context
      this.updateDatadogContext();

      if (MIXPANEL_EVENTS_ENABLED) {
        // Track event in analytics
        trackEvent(
          featureFlagEvents.FEATURE_FLAG_EVALUATED({
            flagName: flagName as string,
            enabled,
            userId: userId as string,
            attributes: attributes as Record<string, unknown>,
          })
        );
      }
    }
  }

  /**
   * Updates Datadog RUM global context with all feature flags evaluated in this session.
   * This enriches error reports with feature flag state for better debugging.
   */
  private updateDatadogContext(): void {
    const flags = Array.from(this.evaluatedFlags.entries()).reduce(
      (acc, [flagName, { enabled }]) => ({
        ...acc,
        [flagName]: enabled,
      }),
      {}
    );

    datadogRum.setGlobalContextProperty('featureFlags', flags);
  }

  /**
   * Clears the current state of the tracker (to be used whenever a new user is
   * associated with the tracker)
   */
  public reset(): void {
    this.evaluatedFlags.clear();
  }
}
