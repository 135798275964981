import * as Popover from '@radix-ui/react-popover';
import { trackEvent } from '@remote-com/analytics';
import { IconV2DuotoneStars } from '@remote-com/norma/icons/IconV2DuotoneStars';
import { useRouter } from 'next/router';
import { useState } from 'react';

import type { API } from '@/src/api/config/api.types';
import { Button } from '@/src/components/Button/Button';
import type { ColumnType } from '@/src/components/Table/types';

import { CANCEL_SMART_SEARCH } from './events';
import SmartSearch from './SmartSearch';

type Filters = API.RemoteAi.GenerateSmartSearchFilter.Response['data']['filters'];

export const SmartSearchButton: React.FC<{
  apiPath: string;
  setAllFilters?: (filters: Filters) => void;
  transformFilters: (filters: Filters) => Filters;
  setGlobalFilter?: (filter: string) => void;
  columns: Array<ColumnType>;
  currentFilters: Filters;
  tableName: string;
  placeholder?: string;
  globalSearchFields?: string;
}> = ({
  setGlobalFilter,
  apiPath,
  setAllFilters,
  transformFilters,
  columns,
  currentFilters,
  tableName,
  placeholder,
  globalSearchFields,
}) => {
  const { pathname } = useRouter();
  const [open, setOpen] = useState(false);
  const handleDismiss = () => {
    trackEvent(CANCEL_SMART_SEARCH({ pathname }));
  };

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <Button
          tone="secondary"
          variant="outline"
          size="sm"
          id={`smart-search-${tableName}`}
          IconBefore={IconV2DuotoneStars}
        >
          Smart Search
        </Button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          side="bottom"
          align="start"
          onEscapeKeyDown={handleDismiss}
          onInteractOutside={handleDismiss}
        >
          <SmartSearch
            onClose={() => setOpen(false)}
            apiPath={apiPath}
            setAllFilters={setAllFilters}
            transformFilters={transformFilters}
            columns={columns}
            setGlobalFilter={setGlobalFilter}
            currentFilters={currentFilters}
            placeholder={placeholder}
            globalSearchFields={globalSearchFields}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
