import { tabbable } from 'tabbable';
import type { Instance } from 'tippy.js';

interface InstanceWithNextFocusableElement extends Instance {
  nextFocusableElement?: HTMLElement | null;
  clearEvents: CallableFunction;
  reference: HTMLElement;
  hasFocusEvents?: boolean;
}

/**
 * Returns focus to the focusable item that triggered the tooltip.
 * Either this is is the reference element or the next focusable
 * element in the DOM
 * */
function leaveTooltipContentFocus(
  instance: InstanceWithNextFocusableElement,
  returnToReference?: boolean // return focus to Tooltip reference element
) {
  if (returnToReference) {
    instance.reference.focus();
  } else {
    instance.nextFocusableElement?.focus();

    // Hide tooltip after focus leaves the reference element
    instance.hide();
  }

  instance.clearEvents();
}

/**
 * When shift tab is pressed on the first focusable item
 * inside the tooltip container, focus is returned to the tooltip
 * reference element
 */
function handleTabFirstFocusableItem(this: InstanceWithNextFocusableElement, ev: KeyboardEvent) {
  const shiftTabKey = ev.shiftKey && ev.key === 'Tab';

  if (shiftTabKey) {
    ev.preventDefault();

    leaveTooltipContentFocus(this, true);
  }
}

/**
 * When tab is pressed on the last focusable item
 * inside the tooltip container, focus is sent to the
 * next tabbable element after the tooltip reference element
 */
function handleTabLastFocusableItem(this: InstanceWithNextFocusableElement, ev: KeyboardEvent) {
  const tabKey = !ev.shiftKey && ev.key === 'Tab';

  if (tabKey) {
    ev.preventDefault();

    leaveTooltipContentFocus(this);
  }
}

/**
 * On tab press, sets event handlers for the first and last focusable items
 * inside the tooltip container.
 * Also sets focus to the tooltip popper container
 */
function handleTabKey(
  this: { instance: InstanceWithNextFocusableElement; tabbableList: HTMLElement[] },
  ev: KeyboardEvent
) {
  const { instance, tabbableList } = this;

  const tabKey = ev.key === 'Tab';
  const shiftTabKey = ev.shiftKey && ev.key === 'Tab';

  if (!tabKey) return;

  const firstTabbable = tabbableList[0];
  const lastTabbable = tabbableList[tabbableList.length - 1];

  const boundHandleTabFirstFocusableItem = handleTabFirstFocusableItem.bind(instance);
  const boundHandleTabLastFocusableItem = handleTabLastFocusableItem.bind(instance);

  if (!instance.hasFocusEvents) {
    firstTabbable.addEventListener('keydown', boundHandleTabFirstFocusableItem);
    lastTabbable.addEventListener('keydown', boundHandleTabLastFocusableItem);
    instance.hasFocusEvents = true;
  }

  instance.clearEvents = () => {
    instance.hasFocusEvents = false;
    firstTabbable.removeEventListener('keydown', boundHandleTabFirstFocusableItem);
    lastTabbable.removeEventListener('keydown', boundHandleTabLastFocusableItem);
  };

  window.requestAnimationFrame(() => {
    if (shiftTabKey) {
      // When the event happened, was the reference button the focused element?
      const wasReferenceActive = window.TippyTooltips.latestActiveElement === instance.reference;

      if (!wasReferenceActive) lastTabbable.focus();
    } else {
      // window.requestAnimationFrame allows tab focus to progress to the next element
      // so we can get a reference to it, this leverages the browser tabbing order
      instance.nextFocusableElement = document.activeElement as HTMLElement;

      // Focus back the tooltip and its first tabbable element
      instance.reference.focus();
      firstTabbable.focus();
    }
  });
}

function handleTabEvents(instance: InstanceWithNextFocusableElement, tabbableList: HTMLElement[]) {
  const boundHandleTabKey = handleTabKey.bind({ instance, tabbableList });
  document.addEventListener('keydown', boundHandleTabKey);

  instance.reference.addEventListener('blur', function removeKeyDownListener() {
    document.removeEventListener('keydown', boundHandleTabKey);
    instance.reference.removeEventListener('blur', removeKeyDownListener);
  });

  return boundHandleTabKey;
}

/**
 * If tooltip container has focusable items,
 * sets an event handler to detect tab key events
 */
function handleFirstFocus(instance: InstanceWithNextFocusableElement, tabbableList: HTMLElement[]) {
  const boundHandleTabKey = handleTabEvents(instance, tabbableList);

  const lastKeydownEvent = window.TippyTooltips.latestKeydownEvent;
  const shiftTab = lastKeydownEvent?.shiftKey;

  // If we've focused this via a shift+tab call the tab key handler right away
  // as we'll need to move focus to the last tabbable element
  if (shiftTab) {
    // The forward focusable element is the element that got us here
    instance.nextFocusableElement = window.TippyTooltips.latestActiveElement;

    boundHandleTabKey(lastKeydownEvent);
  }

  // Once focus is moved to the popper,
  // we need a way to hide the tooltip when clicking in the document
  // since the reference button will no longer trigger a blur event
  document.addEventListener('mousedown', function hideTooltip() {
    instance.hide();
    document.removeEventListener('mousedown', hideTooltip);
  });
}

function handleAccessibility(instance: InstanceWithNextFocusableElement, event: Event) {
  if (event instanceof FocusEvent) {
    // Force tooltip visibility when grabbing tabbable elements
    const currentTooltipVisibility = instance.popper.style.visibility;
    instance.popper.style.visibility = 'visible';

    const tabbableList = tabbable(instance.popper) as HTMLElement[];

    instance.popper.style.visibility = currentTooltipVisibility;

    if (tabbableList.length === 0) return;

    handleFirstFocus(instance, tabbableList);
  }
}

if (typeof window !== 'undefined') {
  window.TippyTooltips = {};

  window.addEventListener('keydown', (ev) => {
    if (ev.key === 'Tab') {
      // This is going to be used to determine the tab direction
      window.TippyTooltips.latestKeydownEvent = ev;
      window.TippyTooltips.latestActiveElement = document.activeElement as HTMLElement;
    }
  });
}

export { handleAccessibility };
