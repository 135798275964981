import type { UseMutationOptions, UseMutationResult } from 'react-query';
// eslint-disable-next-line
import { useMutation } from 'react-query';

import { useApiService } from '../ApiServiceContext';
import type { PutRequest, PutResponse, PutPath } from '../PUT.types';
import type { DefaultApiError } from '../types/utilities';

export type UsePutOptions<
  KPath extends PutPath,
  TError = DefaultApiError,
  TContext = unknown
> = Omit<UseMutationOptions<PutResponse<KPath>, TError, PutRequest<KPath>, TContext>, 'mutationFn'>;

const METHOD = 'PUT';

export function usePut<KPath extends PutPath, TError = DefaultApiError, TContext = unknown>(
  path: KPath,
  options: UsePutOptions<KPath, TError, TContext> = {}
): UseMutationResult<PutResponse<KPath>, TError, PutRequest<KPath>, unknown> {
  const { createService } = useApiService();
  const service = createService(path, METHOD);

  return useMutation((params) => service(params), options);
}
