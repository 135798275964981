import type { FocusEvent, ForwardedRef, MouseEvent, Ref } from 'react';
import { forwardRef, useRef, useState } from 'react';
import type {
  ActionMeta,
  OptionsOrGroups,
  Props as ReactSelectProps,
  FormatOptionLabelMeta,
} from 'react-select';

import { useClickOutside } from '../../hooks/useClickOutside';
import { useProvidedRefOrCreate } from '../../hooks/useProvidedRefOrCreate';
import type { $TSFixMe } from '../../types';
import type { FormGroupProps } from '../form-group';
import { FormGroup } from '../form-group';
import { isValueFilled } from '../helpers';

import { InputAndCreateElement } from './InputAndCreateElement';
import { InputElement } from './InputElement';
import { InputSelectAsideIcon } from './InputSelect.styled';

export type SelectOptionBase = {
  value: string | number;
  label: React.ReactNode;
  [x: string]: unknown;
};

export type SelectOption = {
  value: string;
  label: React.ReactNode;
  [x: string]: unknown;
};

export type SelectGroupOption = { options: SelectOption[]; label: string };

export interface GroupBase<OptionType> {
  options: OptionType[];
  label?: string;
}

type SingleChangeHandler<T> = (value: T, actionMeta: ActionMeta<T>) => void;
type MultiChangeHandler<T> = (values: T[], actionMeta: ActionMeta<T>) => void;

export interface InputSelectProps<T extends SelectOptionBase = SelectOption>
  extends Pick<ReactSelectProps<T>, 'styles' | 'value'>,
    Pick<
      FormGroupProps,
      | 'name'
      | 'id'
      | 'label'
      | 'description'
      | 'errorText'
      | 'readOnly'
      | 'placeholder'
      | 'size'
      | 'extra'
      | 'label'
      | 'hideLabel'
      | 'onBlur'
      | 'onFocus'
    > {
  /**
   * Indicates if the Input allows the creation of new options.
   */
  allowCreate?: boolean;
  /**
   *
   * Callback function that is called when the user creates a new option.
   */
  onCreateOption?: (options: T[]) => void;
  /**
   * Indicates if the selected options are clearable
   */
  isClearable?: boolean;
  /**
   * Indicates if the input select is searchable.
   */
  isSearchable?: boolean;
  /**
   * Callback function that is called when the user clears the selected options.
   */
  onClear?: () => void;
  /**
   * Callback function that is called when the current value changes.
   */
  onChange?: InputSelectProps['multiple'] extends true
    ? MultiChangeHandler<T>
    : SingleChangeHandler<T>;
  /**
   * Indicates if the Input allows multiple selections.
   */
  multiple?: boolean;
  /**
   * Default value for the input
   */
  defaultValue?: T | T[];
  /**
   * Used to render custom components (e.g. custom options, custom value container, etc.).
   */
  components?: $TSFixMe; // todo: fix
  /**
   * Indicates if the component is loading (e.g. fetching options from an API).
   */
  isLoading?: boolean;
  /**
   * Options to be displayed in the dropdown menu.
   */
  options: T[] | OptionsOrGroups<T, GroupBase<T>>;
  /**
   * Callback function that is called when the dropdown menu is opened.
   */
  onMenuOpen?: () => void;
  /**
   * Callback function that is called when the dropdown menu is closed.
   */
  onMenuClose?: () => void;
  /**
   * Indicates if the dropdown menu should be closed when an option is selected.
   */
  closeMenuOnSelect?: boolean;
  /**
   * Used to provide a unique name for the input (in cases where there's more than one InputSelect in the same form with the same name attribute).
   */
  nameUnique?: string;
  /**
   * @deprecated Use `readOnly` instead
   */
  disabled?: boolean;
  /** Indicates if the selected options should be hidden from the menu. */
  hideSelectedOptions?: boolean;
  /**
   * Boolean prop needed to apply custom styles to the InputSelect for a11y purposes.
   * @private This prop is for internal use only and should not be used by other components.
   * Gitlab comment: https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/28491#note_1989453271
   */
  $internalIsInputSelect?: boolean;
  /**
   * Optional component to render when there are no options available (and the `isLoading` prop is false).
   */
  noOptionsMessage?: ReactSelectProps<T>['noOptionsMessage'];
  /**
   * Allows user to  portal the select menu to a custom DOM node.
   */
  menuPortalTarget?: HTMLElement;
  /**
   * filterOption is a function that is called to filter the options based on the user input.
   */
  filterOption?: (option: SelectOptionBase, rawInput: string) => boolean;
  /**
   * getOptionLabel is a function that is called to get the label of the option.
   */
  getOptionLabel?: (option: SelectOptionBase) => string;
  /**
   * Formats option labels in the menu and control as React components
   */
  formatOptionLabel?: (option: T, meta: FormatOptionLabelMeta<T>) => JSX.Element;
}

function InputSelectInner<T extends SelectOptionBase = SelectOption>(
  {
    components,
    options = [],
    onFocus,
    onBlur,
    onMenuOpen,
    onMenuClose,
    // Avoiding default react-select props to use custom styles and components.
    styles = { menu: () => ({}) },
    isClearable,
    isSearchable,
    allowCreate,
    onCreateOption,
    hideSelectedOptions,
    closeMenuOnSelect,
    ...props
  }: InputSelectProps<T>,
  ref: Ref<HTMLInputElement>
) {
  const inputRef = useProvidedRefOrCreate(ref);
  const asideRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [hasUserInput, setHasUserInput] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  /**
   * preventDuplicatedEventAfterBlur is a flag that we set to true when onBlur runs.
   * When this flag is set to true, we use it to prevent triggering `setIsMenuOpen(false)` inside onMenuClose
   *
   * Without this flag, when you want to close the menu by clicking on the aside button, the interaction goes as follows:
   * 1.onBlur: setIsMenuOpen(false)
   * 2.onMenuClose: setIsMenuOpen(false)
   * 3.onClick (aside button): setIsMenuOpen(!isMenuOpen)
   * The final result of this is `true`, so we're not able to close the menu.
   */
  const preventDuplicatedEventAfterBlur = useRef(false);

  useClickOutside({
    refEl: containerRef,
    listen: isMenuOpen,
    onClick: () => isMenuOpen && setIsMenuOpen(false),
    whitelistEl: (target) => menuRef?.current?.contains(target),
  });

  const handleOnClick = (ev: MouseEvent) => {
    ev.stopPropagation();
    if (!isMenuOpen && !props?.readOnly && !props?.disabled) {
      setIsMenuOpen(true);
    }
  };

  const handleOnFocus = (e: FocusEvent) => {
    onFocus?.(e);
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    preventDuplicatedEventAfterBlur.current = true;

    if (event.relatedTarget !== asideRef.current) {
      setIsMenuOpen(false);
    }

    onBlur?.(event);
  };

  const handleOnMenuOpen = () => {
    setIsMenuOpen(true);
    onMenuOpen?.();
  };

  const handleOnMenuClose = () => {
    if (!preventDuplicatedEventAfterBlur.current) {
      setIsMenuOpen(false);
    }

    preventDuplicatedEventAfterBlur.current = false;

    onMenuClose?.();
  };

  const handleOnInputChange = (value: string) => {
    setHasUserInput(!!value.length);
  };

  const identifiers = {
    id: props.id || `${props.name}-selector`,
    inputId: `${props.nameUnique || props.name}-selector-input`,
    instanceId: `${props.nameUnique || props.name}-selector-instance`,
  };

  const hasValue = isValueFilled(props.value, props?.defaultValue, hasUserInput);

  const InputComponent = allowCreate ? InputAndCreateElement : InputElement;

  return (
    <FormGroup
      {...props}
      // @ts-expect-error
      onChange={props.onChange}
      id={identifiers.inputId}
      containerRef={containerRef}
      hasFocus={isMenuOpen}
      hasValue={hasValue}
      label={props.label}
      onClick={handleOnClick}
      aside={({ $hasFocus, $hasError, $isReadOnly }) => (
        // @ts-expect-error
        <InputSelectAsideIcon
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();

            if ($isReadOnly || props.disabled) {
              return;
            }

            setIsMenuOpen(!$hasFocus);
            // Always focus the input when the menu opens
            if (!$hasFocus) {
              // @ts-expect-error
              inputRef.current?.focus();
            }
          }}
          // This is necessary to receive relatedTarget when onBlur
          tabIndex="-1"
          $hasFocus={isMenuOpen}
          $hasError={$hasError}
          $isReadOnly={$isReadOnly}
          ref={asideRef}
          $size={props.size || 'md'}
        />
      )}
      ref={inputRef}
      placeholder={props.placeholder || ''}
      $internalIsInputSelect
    >
      <InputComponent<T>
        isClearable={isClearable}
        isSearchable={isSearchable}
        components={components}
        styles={styles}
        identifiers={identifiers}
        isMenuOpen={isMenuOpen}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onInputChange={handleOnInputChange}
        onMenuClose={handleOnMenuClose}
        onMenuOpen={handleOnMenuOpen}
        options={options}
        menuRef={menuRef}
        {...(allowCreate ? { onCreateOption } : {})}
        hideSelectedOptions={hideSelectedOptions}
        closeMenuOnSelect={closeMenuOnSelect}
      />
    </FormGroup>
  );
}

// Note: had to export InputSelect via this "as <T extends SelectOptionBase>" syntax so the forwardRef would work for generic props
export const InputSelect = forwardRef(InputSelectInner) as <T extends SelectOptionBase>(
  props: InputSelectProps<T> & { ref?: ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof InputSelectInner>;
