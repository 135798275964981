import { ADMIN_DASHBOARD_ROUTE, SIGN_IN_ROUTE } from '@/src/constants/routes';
import { BROADCAST_MESSAGE } from '@/src/domains/broadcast/constants';
/**
 * Handles BroadcastChannel messages
 * @param {string} message - message contract
 */
export function handleMessage(message) {
  const userSlug = localStorage.getItem('mimicOriginUser');
  switch (message) {
    case BROADCAST_MESSAGE.signOut:
      window.location.href = SIGN_IN_ROUTE;
      break;
    case BROADCAST_MESSAGE.signOutTimeout:
      window.location.href = `${SIGN_IN_ROUTE}?reason=timeout`;
      break;
    case BROADCAST_MESSAGE.reload:
      if (userSlug && userSlug !== '') {
        window.location.href = `${ADMIN_DASHBOARD_ROUTE}/users/${userSlug}`;
      } else {
        window.location.reload();
      }
      break;
    default:
      break;
  }
}
